import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'
import { RichInput } from 'wts'

export const ROLES = ['system', 'system2', 'user', 'assistant', 'tool'] as const
export type MessageRole = typeof ROLES[number]

export type RequestMessage = {
  role: MessageRole
  content: RichInput//改成richinput,把报错处理了
}

// 话题
export interface ChatSession {
  id: string
  topic: string
  memoryPrompt: string
  messages: ChatMessage[]
  lastUpdate: number
}

// 消息
export type ChatMessage = RequestMessage & {
  id: string
  time: number
  streaming?: boolean
  isError?: boolean
}

export default class ChatStore {
  public context: RootContext

  public assistantAvatar =
    // 'https://s3.bmp.ovh/imgs/2023/08/09/5a49f5d6eff974cc.jpg'
    'https://s3.bmp.ovh/imgs/2023/08/19/11b3b1042e7af14b.png'
  public currentSession: ChatSession = {
    id: 'testSession',
    topic: 'test',
    memoryPrompt: 'abc',
    messages: [
      {
        id: 'message_1',
        time: Math.floor(Date.now() / 1000),
        role: ROLES[3],
        content:
          [{
            type:"text",
            data:'请描述设计需求，系统将协助完成概念设计\n',
          }],
          
      },
      // {
      //   id: 'message_2',
      //   time: 1645255880,
      //   role: ROLES[1],
      //   content: '用户消息内容',
      // },
      // {
      //   id: 'message_3',
      //   time: 1645255890,
      //   role: ROLES[2],
      //   content:
      //     '```json\n' +
      //     '{\n' +
      //     '  "error": true,\n' +
      //     '  "message": "NetworkError when attempting to fetch resource."\n' +
      //     '}\n' +
      //     '```',
      //   streaming: true,
      // },
    ],
    lastUpdate: 111111,
  }
  constructor(context: RootContext) {
    this.context = context
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setMessageValue = (
    messageIndex: string,
    value: { time?: number; streaming?: boolean; content?: RichInput }
  ) => {
    for (let i = 0; i < this.currentSession.messages.length; i++) {
      if (this.currentSession.messages[i]['id'] === messageIndex) {
        this.currentSession.messages[i] = {
          ...this.currentSession.messages[i],
          ...value,
        }
        break
      }
    }
  }

  addMessage = (content: RichInput, role: MessageRole) => {
    const newMsg: ChatMessage = {
      id: `message_${this.currentSession.messages.length + 1}`,
      time: Math.floor(Date.now() / 1000), // TODO: use param
      role,
      content: content,
      streaming: true, // 临时内容
    }
    this.currentSession.messages.push(newMsg)
  }

  clearMessages = () => {
    this.currentSession.messages = this.currentSession.messages.slice(0, 1)
  }
}
