import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'
import { Cell, Graph } from '@antv/x6'

export default class GraphStore {
  public context: RootContext

  public graph: Graph
  public selectedCell: Cell[] = [] // 选中的Cell数组
  public nodesMovable = true // 节点是否可移动
  public isDragging = true // 画布拖拽状态
  public shiftPressed = false // shift是否被按下
  public graphSize = [0, 0]
  public graphScale = 1
  public knowledgeNum = 0

  constructor(context: RootContext) {
    this.context = context
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setknowledgeNum = (num: number) => {
    this.knowledgeNum = num;
  }

  setGraph = (graph: Graph) => {
    this.selectedCell = []
    this.nodesMovable = true
    this.isDragging = true
    this.shiftPressed = false
    this.graphSize = [0, 0]
    this.graphScale = 1
    this.graph = graph
  }

  // 更新框选中的元素
  selectedCellUpdate = (cells: Cell[]) => {
    if (cells.length === 0 && this.selectedCell.length === 0) {
      return
    }
    this.selectedCell = [...cells]
  }

  // 设置画布中的节点是否可移动
  setNodesMovable = (movable: boolean) => {
    this.nodesMovable = movable
  }

  // 更新画布拖拽状态
  setDragging = (dragging: boolean) => {
    this.isDragging = dragging
    if (dragging) {
      this.graph?.disableRubberband()
      this.graph?.enablePanning()
      this.graph?.unlockScroller()
    } else {
      this.graph?.lockScroller()
      this.graph?.disablePanning()
      this.graph?.enableRubberband()
    }
  }

  setShiftPressed = (shiftPressed: boolean) => {
    this.shiftPressed = shiftPressed
  }

  setGraphSize(size: [number, number]) {
    this.graphSize = size
  }

  setGraphScale(scale: number) {
    this.graphScale = scale
  }

  getNextAvailablePosition = (startPoint: [number, number]): [number, number] => {
    const xs: number[] = []
    const ys: number[] = []
    const rbs: [number, number][] = []
    const targets = []
    for (const node of this.graph!.getNodes()) {
      const pos = node.getBBox()
      if (pos.left >= startPoint[0]) {
        xs.push(pos.left)
      }
      if (pos.right >= startPoint[0]) {
        xs.push(pos.right)
      }
      if (pos.top >= startPoint[1]) {
        ys.push(pos.top)
      }
      if (pos.bottom >= startPoint[1]) {
        ys.push(pos.bottom)
      }
      if (pos.right >= startPoint[0] && pos.bottom >= startPoint[1]) {
        rbs.push([pos.right, pos.bottom])
      }
    }

    for (let i = 0; i < xs.length; i++) {
      for (let j = 0; j < ys.length; j++) {
        let has = false
        for (const rb of rbs) {
          if (rb[0] > xs[i] && rb[1] > ys[j]) {
            has = true
            break
          }
        }
        if (!has) {
          targets.push([xs[i], ys[j]])
        }
      }
    }

    const l2Distance = (a: [number, number], b: [number, number]) => {
      return Math.pow(a[0] - b[0], 2) + 2 * Math.pow(a[1] - b[1], 2)
    }

    targets.sort((l, r) => l2Distance(l, startPoint) - l2Distance(r, startPoint))
    const res: [number, number] = [startPoint[0], startPoint[1]]
    if (targets.length > 0) {
      return [targets[0][0] + 20, targets[0][1] + 20]
    }
    return [res[0], res[1]]
  }

  getCenterPosition = (): [number, number] => {
    const { left, top } = this.graph!.getScrollbarPosition()
    const { tx, ty } = this.graph!.translate()
    return [left - tx - 900, top - ty - 900]
  }
}
