import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'
import { IContinuousParam, IDiscreteParam, IInputParam } from './utils'
const testImageURL =
  'https://fengyuanchen.github.io/cropperjs/images/picture.jpg'

const modelParam: IDiscreteParam<string> = {
  id: "_model",
  type: 'select',
  title: '模型',
  default: 'majicmixRealistic_v7',
  options: ['majicmixRealistic_v7'],
}

const moduleParam: IDiscreteParam<string> = {
  id: "control_module",
  type: 'select',
  title: '控制模块',
  default: 'canny',
  options: ["canny", "depth", "shuffle"],
}

const weightParam: IContinuousParam = {
  id: 'control_weight',
  type: 'slider',
  title: '控制权重',
  default: 1,
  min: 0,
  max: 2,
  step: 0.1,
}

const seedParam: IInputParam<number> = {
  id: 'seed',
  type: 'input',
  title: '随机种子',
  default: -1,
}

const startingParam: IContinuousParam = {
  id: 'starting_control',
  type: 'slider',
  title: '控制起始点',
  default: 0,
  min: 0,
  max: 1,
  step: 0.1,
}

const endingParam: IContinuousParam = {
  id: 'ending_control',
  type: 'slider',
  title: '控制结束点',
  default: 1,
  min: 0,
  max: 1,
  step: 0.1,
}

const countParam: IContinuousParam = {
  id: 'count',
  type: 'slider',
  title: '数量',
  default: 1,
  min: 1,
  max: 4,
  step: 1,
}

const stepsParam: IContinuousParam = {
  id: 'steps',
  type: 'slider',
  title: '采样数',
  default: 30,
  min: 1,
  max: 50,
  step: 1,
}

const scaleParam: IContinuousParam = {
  id: 'cfg_scale',
  type: 'slider',
  title: '采样数',
  default: 7,
  min: 0,
  max: 10,
  step: 0.1,
}


const contorlledImgGenerationParmas = [
  modelParam,
  moduleParam,
  weightParam,
  seedParam,
  startingParam,
  endingParam,
  countParam,
  stepsParam,
  scaleParam
]

export const ControlledImageGenerationMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod( {
      cardType: 'ControlledImageGenerationMethod',
      params: contorlledImgGenerationParmas,
      needFold: true,
      inputs: [
        { title: '提示词', type: 'txt2img_prompt' },
        { title: '参考图', type: 'img' },
      ],
      outputParser: (output) => output?.gen_result?.map(({ id }) => id) ?? [],
      styles: {},
  })
