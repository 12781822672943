import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useWtsStore } from '@/hooks'
import { Asset, AssetType } from 'wts'
import { TextAsset } from '../TextAsset/TextAsset'
import { IPortInfo } from '../../Port/Port'
import { ImageAsset } from '../ImageAsset/ImageAsset'
import { PromptAsset } from '../PromptAsset/PromptAsset'
import { ModelAsset } from '../ModelAsset/ModelAsset'
import { VideoAsset } from '../VideoAsset/VideoAsset'

export interface IGeneralAssetCard {
  asset: Asset
  args?: Record<string, any>
}

const dummyPortInfo: IPortInfo = {
  cardId: '',
  portType: 'out',
  groupId: 0,
  subId: 0
}

const tempCompatibleForDesignEvaluation = (data: object | string) => {
  if(typeof data === 'string') { return data }
  else { return JSON.stringify(data) }
}

export const GeneralAssetCard: FC<IGeneralAssetCard> = (props) => {
  const wtsStore = useWtsStore()
  const [assetData, setAssetData] = useState<any | undefined>();

  const title = useMemo(() => {
    let sid = null
    if (props.args?.showController) {
      sid = wtsStore.operator?.planner.get_short_id(props.asset.id, 'asset')
      wtsStore.notifyUpdated()
    }
    return props.asset.name + (sid ? ' - ' + sid : '')
  }, [props.asset])

  useEffect(() => {
    (async () => {
      const type_list = ['context_string', 'string', 'txt2img_prompt', 'model', 'csv']
      if (type_list.includes(props.asset.type)) {
        const data = await wtsStore.app.get_asset_data(props.asset.id)
        // console.log('GeneralAssetCard', data, props.asset.id);
        setAssetData(data)
      }
    })();
  }, [props.asset, wtsStore.app])

  switch (props.asset.type as AssetType | 'csv') {
    case 'csv':
    case 'context_string':
    case 'string': {
      return <TextAsset
        portInfo={dummyPortInfo}
        text={assetData ?? ""}
        isEditable={false}
        useMarkdown={true}
        title={title}
        type={props.asset.type}
        {...props.args}
      />
    }
    case 'image': {
      return <ImageAsset
        portInfo={dummyPortInfo}
        initialImageIDs={[props.asset.id]}
        isEditable={false}
        title={title}
        {...props.args}
      />
    }
    case 'txt2img_prompt': {
      return <PromptAsset
        portInfo={dummyPortInfo}
        prompt={assetData ?? {prompt: "", negative_prompt: ""}}
        title={title}
        {...props.args}
      />
    }
    case 'model': {
      return <ModelAsset
        portInfo={dummyPortInfo}
        url={wtsStore.app.get_asset_url(props.asset.id)}
        type='obj'
        title={title}
        {...props.args}
      />
    }
    case 'video': {
      return <VideoAsset
        portInfo={dummyPortInfo}
        videoUrl={wtsStore.app.get_asset_url(props.asset.id)}
        title={title}
        {...props.args}
      />
    }
  }
  return <>Unsupported Asset Type</>
}