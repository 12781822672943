import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './DesignGoalSummarizeMethod.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { MethodCard } from '@/components/graph/methods/MethodCard/MethodCard'
import { SvgIcon } from '@/components/icons'
import { DataAnalysis } from '@/components/icons/DataAnalysis'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { ICardContext, UUID } from '@/store/metaStore'
import { useMetaStore, useWtsStore } from '@/hooks'
import { Add_707070 } from '@/components/icons/Add_707070'
import { Select, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { AssetInPort } from '@/components/graph/assets/AssetInPort/AssetInPort'
import { TextAsset } from '../../assets/TextAsset/TextAsset'
import { get_asset_data } from 'wts'
import { IPortInfo } from '../../Port/Port'
import { getPortId, parsePort } from '@/components/MainGraph/utils'
import { GeneralMethod } from '../GeneralMethod'

export const DesignGoalSummarizeMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'DesignGoalSummarizeMethod',
    params: [],
    inputs: [{ title: '设计约束', type: 'string' }],
    outputParser: (output) => [output?.text?.id],
    isGenerateButtonFrozen: (cardID, inPorts) =>
      inPorts[getPortId(cardID, 'in', 0, 0)]?.[0] ? false : true,
    styles,
  })

export interface IDesignGoalSummarizeMethodParams {
  data1?: any
  node?: Node
  graph?: Graph
  cardID?: UUID
  cardIndex?: number
  needFold?: boolean
}
export const LegacyDesignGoalSummarizeMethod: FC<IDesignGoalSummarizeMethodParams> =
  observer(({ node, graph, cardID, cardIndex, needFold }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()
    const wtsStore = useWtsStore()

    const [inPorts, setInPorts] = useState<Record<string, UUID[]>>({})
    const [assets, setAssets] = useState<ICardContext['assets']>([])
    const assetDataCache = useRef<Record<string, string>>({})

    // this works only once, while inPorts[getPortId(cardID, true, 0, 0)]?.[0] will always be sensed
    useEffect(() => {
      if (!metaStore.cardContext[cardID]) {
        return
      } // TODO
      // 这个方法有一个输入桩，每个输入桩可以连多个资产
      const inPorts = metaStore.cardContext[cardID].inPorts
      console.log('LegacyDesignGoalSummarizeMethod,inPorts update', inPorts)
      setInPorts(inPorts)
    }, [metaStore.cardContext[cardID]]) // TODO

    const isGenerateButtonFrozen = () =>
      inPorts[getPortId(cardID, 'in', 0, 0)]?.[0] ? false : true

    const generate = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      console.log('generate, designMethod', designMethod.inPorts, designMethod)
      try {
        designMethod.inPorts[0].assets = inPorts[
          getPortId(cardID, 'in', 0, 0)
        ].map((portId) => {
          const portInfo = parsePort(portId)
          return metaStore.cardContext[portInfo.cardId]?.assets[
            portInfo.groupId
          ][portInfo.subId]
        })
        console.log('generate, inports assets', designMethod.inPorts)
      } catch {
        console.error('generate, inPort not asset')
      }
      await wtsStore.operator.update_method({
        ...designMethod,
      })
      let retDesignMethod
      try {
        retDesignMethod = await wtsStore.operator.run_method(designMethod.id)
      } catch (e) {
        console.error('generate run method exception', e)
        return
      }
      console.log('generate return', retDesignMethod)
      console.log(
        'generate return',
        retDesignMethod,
        retDesignMethod.outputs[0]?.text?.id
      )
      const outputID = retDesignMethod.outputs[0]?.text?.id
      const outputContent = await get_asset_data(outputID)
      setAssets((assets) => {
        const newAssets = [...assets, [outputID]]
        metaStore.registerCardOutputAsset(
          getPortId(cardID, 'out', newAssets.length - 1, 0),
          outputID
        )
        metaStore.cardContext[cardID].assets = newAssets
        return newAssets
      })
      assetDataCache.current[outputID] = outputContent
    }

    const input = (
      <>
        <AssetInPort
          assetType={'string'}
          title={'设计约束'}
          portInfo={{ cardId: cardID, portType: 'in', groupId: 0, subId: 0 }}
        />
      </>
    )

    // TODO
    const params = (
      <div {...styleClass(['params'])}>
        <></>
      </div>
    )

    const output = (
      <>
        {assets.map((outputAssets, i) => (
          <TextAsset
            key={i}
            width={'auto'}
            text={assetDataCache.current[outputAssets[0]]}
            useMarkdown={true}
            portInfo={{
              cardId: cardID,
              portType: 'out',
              groupId: i,
              subId: 0,
            }}
            showController={true}
          />
        ))}
      </>
    )

    return (
      <MethodCard
        width={400}
        cardTitle={'设计目标'}
        cardIcon={<SvgIcon icon={DataAnalysis} />}
        hideMore={true}
        suffixExtra={
          <>
            <div {...styleClass(['header-suffix-add'])}>
              <SvgIcon icon={Add_707070} />
            </div>
          </>
        }
        node={node}
        graph={graph}
        hideInput={false}
        input={input}
        params={params}
        output={output}
        isButtonDisabled={isGenerateButtonFrozen()}
        onClick={generate}
      ></MethodCard>
    )
  })
