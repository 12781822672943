import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'

export const Image2VideoMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'Image2VideoMethod',
    params: [],
    inputs: [{ title: '动作说明', type: 'string' }, { title: '图片', type: 'img' }],
    outputParser: (output) => [output?.video?.id],
    styles: {},
  })
