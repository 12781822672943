import { ComponentType } from 'react'
import { Graph, Markup, Node } from '@antv/x6'
import { register } from '@antv/x6-react-shape'
import AppStore from '@/store/app'
import { Lang } from '@/config'
import { UUID } from '@/store/metaStore'
import { IPortInfo } from '../graph/Port/Port'

// 语言切换
export const switchLanguage = (
  appStore: AppStore,
  graph: Graph,
  newLang: 'English' | '中文'
) => {
  appStore.setLanguage(newLang === 'English' ? Lang.en : Lang.zh)
}

export const addAssetInCanvas = (inputData: {
  graph: Graph // 目标画布
  cardID: string // 资产唯一ID
  component: ComponentType<{
    node: Node
    graph: Graph
  }> // 设计资产组件
  position?: [number, number] // 添加该组件位于画布的位置
}): Node => {
  const { graph, cardID, component, position } = inputData

  register({
    shape: cardID,
    effect: ['data'],
    component: component,
  })

  const newNode = graph.addNode({
    x: position ? position[0] : 120, // 默认位置待确认
    y: position ? position[1] : 50, // 默认位置待确认
    id: cardID,
    shape: cardID,
    portMarkup: [Markup.getForeignObjectMarkup()],
    ports: {
      groups: {
        /* in: {
          attrs: {
            fo: {
              width: 10,
              height: 10,
              x: -5,
              y: 0 + 19 - 5,
              magnet: 'true',
            },
          },
          zIndex: 1,
          position: {
            name: 'left',
          },
        }, */
        out: {
          attrs: {
            fo: {
              width: 10,
              height: 10,
              x: -5,
              y: 0 + 19 - 5,
              magnet: 'true',
            },
          },
          zIndex: 1,
          position: {
            name: 'right',
          },
        },
      },
      items: [
        // { id: `${cardID}-in-port`, group: 'in' },
        { id: `${cardID}/out/0/0`, group: 'out' },
      ],
    },
  })

  return newNode
}

export const addMethodInCanvas = (inputData: {
  graph: Graph // 目标画布
  cardID: string // 资产唯一ID
  component: ComponentType<{
    node: Node
    graph: Graph
  }> // 设计资产组件
  position?: [number, number] // 添加该组件位于画布的位置
}) => {
  // console.log('addMethodInCanvas')
  const { graph, cardID, component, position } = inputData

  register({
    shape: cardID,
    effect: ['data'],
    component: component,
  })

  const node = graph.addNode({
    x: position ? position[0] : 120, // 默认位置待确认
    y: position ? position[1] : 50, // 默认位置待确认
    id: cardID,
    shape: cardID,
    portMarkup: [Markup.getForeignObjectMarkup()],
    ports: {
      groups: {
        absolute_in: {
          position: {
            name: 'absolute',
          },
          attrs: {
            fo: {
              width: 10,
              height: 10,
              magnet: 'true',
            },
          },
          zIndex: 1,
        },
        absolute_out: {
          position: {
            name: 'absolute',
          },
          attrs: {
            fo: {
              width: 10,
              height: 10,
              magnet: 'true',
              // y: -5,
            },
          },
          zIndex: 1,
        },
      },
    },
  })

  graph.centerCell(node)
  const pos = graph.getScrollbarPosition()
  graph.setScrollbarPosition(pos.left + 400, pos.top + 200)
}

// 添加边
export const addEdgeInCanvas =
  (graph: Graph) =>
  (
    fromPortId: string, toPortId: string
  ) => {
    const fromPortInfo = parsePort(fromPortId)
    const toPortInfo = parsePort(toPortId)
    graph.addEdge({
      source: {
        cell: fromPortInfo.cardId,
        port: fromPortId,
      },
      target: {
        cell: toPortInfo.cardId,
        port: toPortId,
      },
    })
  }

export const getPortId = (cardId: string, inOut: string, groupId: number, subId: number) => {
  return `${cardId}/${inOut}/${groupId}/${subId}`
}

export const getPortIdByObj = (portInfo: IPortInfo) => {
  return getPortId(portInfo.cardId, portInfo.portType, portInfo.groupId, portInfo.subId)
}

export const parsePort = (id: string): IPortInfo => {
  const res = id.split('/')
  return {
    cardId: res[0],
    portType: res[1],
    groupId: Number(res[2]),
    subId: Number(res[3]),
  }
}
