import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'
import { IContinuousParam, IDiscreteParam, IInputParam } from './utils'
import { getPortId } from '@/components/MainGraph/utils'

export const FbsRequirementMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FbsRequirementMethod',
    params: [],
    inputs: [{ title: '设计约束', type: 'string' }],
    outputParser: (output) => [(output?.contexts ?? []).map(({ id }) => id)],
    styles: {},
  })

export const FbsFunctionMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FbsFunctionMethod',
    params: [],
    inputs: [{ title: '前置分析', type: 'context_string' }],
    outputParser: (output) => (output?.contexts ?? []).map(({ id }) => id),
    styles: {},
  })

export const FbsBehaviorMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FbsBehaviorMethod',
    params: [],
    inputs: [{ title: '前置分析', type: 'context_string' }],
    outputParser: (output) => (output?.contexts ?? []).map(({ id }) => id),
    styles: {},
  })

export const FbsStructureMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FbsStructureMethod',
    params: [],
    inputs: [{ title: '前置分析', type: 'context_string' }],
    outputParser: (output) => (output?.contexts ?? []).map(({ id }) => id),
    styles: {},
  })

export const FbsEmotionMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FbsEmotionMethod',
    params: [],
    inputs: [
      { title: '前置分析', type: 'context_string' },
      { title: '情感描述', type: 'string' },
    ],
    outputParser: (output) => [output?.content?.id],
    styles: {},
  })

  export const FbsFusionMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FbsFusionMethod',
    params: [],
    inputs: [
      { title: '文本描述', type: 'context_string' },
      { title: '图像描述', type: 'img' },
    ],
    outputParser: (output) => [output?.content?.id],
    styles: {},
  })

const modelParam: IDiscreteParam<string> = {
  id: "_model",
  type: 'select',
  title: '图像生成模型',
  default: 'stablediffuusion_v1_5',
  options: ['stablediffuusion_v1_5'],
}
const generateNumParam: IContinuousParam = {
  id: 'n_iter',
  type: 'slider',
  title: '生成数量',
  default: 1,
  min: 1,
  max: 4,
  step: 1,
}
const seedParam: IInputParam<number> = {
  id: 'seed',
  type: 'input',
  title: '随机种子数值',
  default: -1,
}
const relatedScoreParam: IContinuousParam = {
  id: 'cfg_scale',
  type: 'slider',
  title: '与提示词相关程度',
  default: 2,
  min: 1,
  max: 16,
  step: 1,
}
const referenceStrengthParam: IContinuousParam = {
  id: 'denoising_strength',
  type: 'slider',
  title: '参考图强度',
  default: 0.6,
  min: 0,
  max: 1,
  step: 0.02,
}
const i2iParams = [
  // modelParam,
  generateNumParam,
  seedParam,
  relatedScoreParam,
  referenceStrengthParam,
]

export const FbsImageGenerationMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FbsImageGenerationMethod',
    params: i2iParams,
    inputs: [
      { title: '文生图描述', type: 'string' },
      { title: '参考图(选填)', type: 'img' },
      { title: '遮罩图(选填)', type: 'img' },
    ],
    outputParser: (output) => output?.images?.map(({ id }) => id) ?? [],
    isGenerateButtonFrozen: (cardID, inPorts) =>
      inPorts[getPortId(cardID, 'in', 0, 0)]?.[0] ? false : true,
    styles: {},
  })
