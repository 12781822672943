import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'
import { IContinuousParam, IImageEditParam } from './utils'
import { getPortId, parsePort } from '@/components/MainGraph/utils'
import { useWtsStore, useMetaStore } from '@/hooks'
import { get_asset_url } from 'wts'
import { isNil } from 'lodash'
const testImageURL =
  'https://fengyuanchen.github.io/cropperjs/images/picture.jpg'

export const ImageObjectEditingMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod((id) => {
    const wtsStore = useWtsStore()
    const metaStore = useMetaStore()
    const imgEditParm: IImageEditParam = {
      id: 'edit_coordinates',
      type: 'img-edit',
      default: '',
      title: '框选图像',
      valueMapper: (args, value) => {
        return value
      },
      update_wts: function (background_mask: { x: number; y: number }[]) {
        let designMethod = wtsStore.operator.get_method_data(id)
        console.log(background_mask)
        designMethod.argument.background_mask = background_mask
        const image = new Image()
        // image.src = designMethod.argument[this.id]
        image.src = get_asset_url(designMethod.inPorts?.[0]?.assets?.[0])
        image.onload = () => {
          if (
            designMethod.type === 'image_object_editing' &&
            !isNil(designMethod.argument?.background_mask)
          ) {
            const { width, height } = image
            designMethod.argument.background_mask =
              designMethod.argument.background_mask.map((each, index) => {
                return {
                  [`w${index + 1}`]: Math.round((each.x * width) / 500),
                  [`h${index + 1}`]: Math.round((each.y * height) / 500),
                }
              })
            wtsStore.operator.update_method(designMethod)
          }
        }
        if (
          designMethod.type === 'image_object_editing' &&
          !isNil(designMethod.argument?.background_mask)
        ) {
        } else {
          wtsStore.operator.update_method(designMethod)
        }
      },
      // imgUrl: testImageURL,
      imgUrl: '',
      get_image: (background_mask: { x: number; y: number }[]) => {
        const inPortId = getPortId(id, 'in', 0, 0)
        // console.log(inPortId)
        const outPort = metaStore.cardContext[id].inPorts[inPortId] ?? []
        if (outPort.length === 0) {
          imgEditParm.imgUrl = ''
          return
        }
        const portInfo = parsePort(outPort[0])

        // console.log(portInfo)
        // console.log(portInfo.cardId in metaStore.editingAssetsMap)
        imgEditParm.imgUrl = ''
        console.log('no asset')
        if (
          metaStore.cardContext[portInfo.cardId]?.assets?.[portInfo.groupId]
        ) {
          const asset =
            metaStore.cardContext[portInfo.cardId].assets[portInfo.groupId][
              portInfo.subId
            ]
          console.log(get_asset_url(asset))
          imgEditParm.imgUrl = get_asset_url(asset)
          imgEditParm.default = get_asset_url(asset)
          console.log(imgEditParm.default)
        }

        let designMethod = wtsStore.operator.get_method_data(id)
        console.log(background_mask)
        designMethod.argument.background_mask = background_mask
        wtsStore.operator.update_method(designMethod)
      },
    }
    return {
      cardType: 'ImageObjectEditingMethod',
      params: [imgEditParm],
      inputs: [
        { title: '背景图', type: 'img' },
        { title: '对象物体', type: 'img' },
      ],
      outputParser: (output) => [output?.gen_result?.id],
      styles: {},
    }
  })

const outputNumParam: IContinuousParam = {
  id: 'output_num',
  type: 'slider',
  title: '生成数量',
  default: 3,
  min: 3,
  max: 5,
  step: 1,
}
