import { RootContext } from '@/App.context'
import {
  addAssetInCanvas,
  addEdgeInCanvas,
  addMethodInCanvas,
  getPortId,
  getPortIdByObj,
  parsePort,
} from '@/components/MainGraph/utils'
import { IPortInfo } from '@/components/graph/Port/Port'
import { ImageAsset } from '@/components/graph/assets/ImageAsset/ImageAsset'
import { PromptAsset } from '@/components/graph/assets/PromptAsset/PromptAsset'
import { TextAsset } from '@/components/graph/assets/TextAsset/TextAsset'
import { DesignBackgroundDescMethod } from '@/components/graph/methods/DesignBackgroundDescMethod/DesignBackgroundDescMethod'
import { DesignEvaluationMethod } from '@/components/graph/methods/DesignEvaluationMethod/DesignEvaluationMethod'
import { LegacyDesignGoalSummarizeMethod, IDesignGoalSummarizeMethodParams, DesignGoalSummarizeMethod } from '@/components/graph/methods/DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { ImageGenerateMethod, LegacyImageGenerateMethod } from '@/components/graph/methods/Image2ImageMethod/ImageGenerateMethod'
import { ImageStyleTransferMethod, LegacyImageStyleTransferMethod } from '@/components/graph/methods/ImageStyleTransferMethod/ImageStyleTransferMethod'
import { LegacyMarketResearchMethod, MarketResearchMethod } from '@/components/graph/methods/MarketResearchMethod/MarketResearchMethod'
import { LegacyPainPointDiscoveryMethod, PainPointDiscoveryMethod } from '@/components/graph/methods/PainPointDiscoveryMethod/PainPointDiscoveryMethod'
import { LegacyPromptGenerateMethod, PromptGenerateMethod } from '@/components/graph/methods/PromptGenerateMethod/PromptGenerateMethod'
// import { LegacyRequirementsSummaryMethod, RequirementsSummaryMethod } from '@/components/graph/methods/RequirementsSummaryMethod/RequirementsSummaryMethod'
import ChatStore from '@/store/chatStore'
import WtsStore from '@/store/wtsStore'
import { Edge, Graph } from '@antv/x6'
import { makeAutoObservable } from 'mobx'
import { FC } from 'react'
import { get_asset_readable_name, DesignMethod, Asset, get_asset_url } from 'wts'
import _ from 'lodash'
import { message } from 'antd'
import { JiaotongImageGenerationMethod, JiaotongPlanePixelExtractionMethod, JiaotongThreeDComponentGenerationMethod } from '@/components/graph/methods/JiaotongMethods/JiaotongMethods'
import { GeneralAssetCard } from '@/components/graph/assets/GeneralAssetCard/GeneralAssetCard'
import { Image2VideoMethod } from '@/components/graph/methods/Image2VideoMethod'
import { Image2ModelMethod } from '@/components/graph/methods/Image2ModelMethod'
import { FbsBehaviorMethod, FbsEmotionMethod, FbsFunctionMethod, FbsFusionMethod, FbsImageGenerationMethod, FbsRequirementMethod, FbsStructureMethod } from '@/components/graph/methods/FBSMethods'
import { DesignPlanMethod, EnvironmentAnalysisMethod, FunctionRefinementMethod, ProductFunctionMethod, ProductIterationMethod, UserRequirementMethod } from '@/components/graph/methods/Case3DSeatMethods'
import { ImageEditingMethod } from '@/components/graph/methods/ImageEditingMethod'
import { SeatPressure3DMethod, SeatPressureCalcMethod, SeatPressureSimulationMethod } from '@/components/graph/methods/CaseJiaotongSeatMethods'
import { ImageObjectEditingMethod } from "@/components/graph/methods/ImageObjectEditingMethods";
import { TrizContradictionAnalysisMethod, TrizConvertParametersMethod, TrizInventionPrincipleMethod, TrizSolutionMethod, TrizSummarizeProblemMethod } from '@/components/graph/methods/TrizMethods'
import { ControlledImageGenerationMethod } from "@/components/graph/methods/ControlledImageGenerationMethod";

interface IMetaMethod {
  methodID: string
  methodName: string
  methodType: string // generate_design_goals / text_to_image...
  wtsMethod: DesignMethod
}

export type UUID = string
interface IMyCard {
  cardID: UUID
}

// TODO: add base type
type HistoryActionAddEdge = {
  action: 'addEdge'
  fromPortID: string // stringify(IPortInfo)
  toPortID: string
}
export const supportedMethodCardTypes = [
  'DesignGoalSummarizeMethod',
  'Image2ImageMethod',
  'ImageStyleTransferMethod',
  // 'RequirementsSummaryMethod',
  'MarketResearchMethod',
  // 'LegacyMarketResearchMethod',
  // 'DesignBackgroundDescMethod',
  'PainPointDiscoveryMethod',
  'PromptGenerateMethod',
  'DesignEvaluationMethod',
  'Image2ModelMethod',
  'Image2VideoMethod',
  'JiaotongPlanePixelExtractionMethod', // methods with prefix 'Jiaotong' may be deleted in future
  'JiaotongThreeDComponentGenerationMethod',
  'JiaotongImageGenerationMethod',
  'FbsRequirementMethod',
  'FbsFunctionMethod',
  'FbsBehaviorMethod',
  'FbsStructureMethod',
  'FbsEmotionMethod',
  'FbsImageGenerationMethod',
  'FbsFusionMethod',
  'EnvironmentAnalysisMethod',
  'ProductFunctionMethod',
  'UserRequirementMethod',
  'FunctionRefinementMethod',
  'DesignPlanMethod',
  'ProductIterationMethod',
  'ImageEditingMethod',
  'ImageObjectEditingMethod',
  'ControlledImageGenerationMethod',
  'SeatPressureCalcMethod',
  'SeatPressureSimulationMethod',
  'SeatPressure3DMethod',
  'TrizSummarizeProblemMethod',
  'TrizConvertParametersMethod',
  'TrizContradictionAnalysisMethod',
  'TrizInventionPrincipleMethod',
  'TrizSolutionMethod',
] as const
const supportedAssetCardTypes = [
  'TextAsset',
  'ImageAsset',
  'PromptAsset',
] as const
const supportedCardTypes = [
  ...supportedAssetCardTypes,
  ...supportedMethodCardTypes,
] as const
export type SupportMethodCardType = typeof supportedMethodCardTypes[number]
export type SupportedCardType = typeof supportedCardTypes[number]
export const methodStageTags = ['所有', '需求', '功能', '逻辑', '原型', '其他'] as const
export type MethodStageTag = typeof methodStageTags[number]

export type CardDetailedInfo = {
  title: string,
  description: string,
  component: FC<IDesignGoalSummarizeMethodParams>,
  cover: string,
  template_id: number,
  tags: MethodStageTag[]
  wtsMethod: string
}

const defaultCardDetailInfo: () => CardDetailedInfo = () => ({
  title: 'not implemented',
  description: 'not implemented',
  component: (<></> as unknown as FC<IDesignGoalSummarizeMethodParams>),
  cover: get_asset_url('7058a8e6-395c-4cf4-8e55-b66ca5bfdfa3'),
  template_id: 890,
  tags: [],
  wtsMethod: 'not implemented'
})

export const cardType2DetailedInfo = (cardType: SupportedCardType): CardDetailedInfo => {
  return {
    ...defaultCardDetailInfo(), ...new Map<SupportedCardType, Partial<CardDetailedInfo>>([
      ['DesignGoalSummarizeMethod', { title: '设计目标总结', component: DesignGoalSummarizeMethod, description: '输入需求，总结详细设计目标', tags: ['需求', '功能'], wtsMethod: 'generate_design_goals', template_id: 890, }],
      ['Image2ImageMethod', { title: '图像生成', component: ImageGenerateMethod, description: '基于提示词和参考图生成图像', cover: get_asset_url('4a25337d-21e8-482e-846f-5b6f1233fead'), tags: ['原型'], wtsMethod: 'image_generation', template_id: 891, }],
      ['ImageStyleTransferMethod', { title: '图像风格迁移', component: ImageStyleTransferMethod, description: '学习参考图像的风格生成图片', cover: get_asset_url('62201e19-3e91-4c12-8adc-90b7d0100bc7'), tags: ['原型'], wtsMethod: 'image_style_transfer', template_id: 892, }],
      // ['RequirementsSummaryMethod', { title: '需求总结', component: RequirementsSummaryMethod, description: '需求分析', tags: ['需求', '功能'], wtsMethod: 'generate_design_goals' }],
      ['MarketResearchMethod', { title: '市场调研', component: MarketResearchMethod, description: '根据产品背景描述生成市场调研表格', tags: ['需求', '功能'], wtsMethod: 'market_analysis', template_id: 893, }],
      // ['LegacyMarketResearchMethod', { title: '市场分析(legacy)', component: LegacyMarketResearchMethod, description: '需求分析', tags: ['需求', '功能'], wtsMethod: 'market_analysis' }],
      ['PainPointDiscoveryMethod', { title: '用户调研', component: PainPointDiscoveryMethod, description: '根据产品背景描述生成产品如何解决用户痛点的相关分析', tags: ['需求', '功能'], wtsMethod: 'design_opportunity_discovery', template_id: 894, }],
      ['PromptGenerateMethod', { title: '文生图提示词生成', component: PromptGenerateMethod, description: '基于设计需求生成文生图的positive+nagtive维度提示词', tags: ['原型', '功能'], wtsMethod: 'generate_text2image_prompt', template_id: 895, }],
      ['DesignEvaluationMethod', { title: '设计内容评价', component: DesignEvaluationMethod, description: '用户输入与生成内容的一致性评价+设计方案新颖/实用/可行性评估', cover: get_asset_url('3105cf5c-ebf5-47c9-a9a2-2a6c1135928a'), tags: ['逻辑', '原型', '功能'], wtsMethod: 'design_evaluation', template_id: 896, }],
      ['Image2ModelMethod', { title: '3D模型生成', component: Image2ModelMethod, description: '根据图案生成3D模型', cover: get_asset_url('9f359a0b-3887-4215-8c25-d5101440f892'), tags: ['原型', '功能'], wtsMethod: 'model_generation', template_id: 897, }],
      ['Image2VideoMethod', { title: '视频生成', component: Image2VideoMethod, description: '根据图片生成视频', cover: get_asset_url('4cfc4591-b2ac-4ce5-813a-3f50f49cd1f4'),tags: ['原型', '功能'], wtsMethod: 'video_generation', template_id: 898, }],
      ['JiaotongPlanePixelExtractionMethod', { title: '平面像素提取', component: JiaotongPlanePixelExtractionMethod, description: '根据参考图提取像素的颜色信息', cover: get_asset_url('9fc105ec-a271-4b6a-8b05-c03e735156a1'), wtsMethod: 'design_evaluation', }], // TODO: update wtsMethod
      ['JiaotongThreeDComponentGenerationMethod', { title: '三维部件重建', component: JiaotongThreeDComponentGenerationMethod, description: '基于参考图生成三维部件', cover: get_asset_url('9f359a0b-3887-4215-8c25-d5101440f892'), wtsMethod: 'design_evaluation', }],
      ['JiaotongImageGenerationMethod', { title: '多视角图片生成', component: JiaotongImageGenerationMethod, description: '基于参考图和提示词生成多视角的图片', cover: get_asset_url('9f359a0b-3887-4215-8c25-d5101440f892'), wtsMethod: 'design_evaluation', }],
      ['FbsRequirementMethod', { title: '需求分析', component: FbsRequirementMethod, description: '根据设计约束进行5W1H的需求分析', wtsMethod: 'fbs_requirement_analysis', template_id: 899, }],
      ['FbsFunctionMethod', { title: '产品功能分析', component: FbsFunctionMethod, description: '利用FBS方法，基于前置分析进行产品功能分析', wtsMethod: 'fbs_function_analysis', template_id: 900, }],
      ['FbsBehaviorMethod', { title: '产品设计行为分析', component: FbsBehaviorMethod, description: '利用FBS方法，基于前置分析进行产品应该如何设计的行为分析', wtsMethod: 'fbs_behavior_analysis', template_id: 901, }],
      ['FbsStructureMethod', { title: '产品结构分析', component: FbsStructureMethod, description: '利用FBS方法，基于前置分析进行产品结构的分析', wtsMethod: 'fbs_structure_analysis', template_id: 902, }],
      ['FbsEmotionMethod', { title: '外观特征生成', component: FbsEmotionMethod, description: '利用感性工学法，基于前置分析和情咸熏求生成外观设计特征', wtsMethod: 'fbs_emotion_analysis', template_id: 903, }],
      ['FbsImageGenerationMethod', { title: '图片生成', component: FbsImageGenerationMethod, description: '基于提示词和参考图生成图片', cover: get_asset_url('4a25337d-21e8-482e-846f-5b6f1233fead'), wtsMethod: 'fbs_image_generation', template_id: 904, }],
      ['FbsFusionMethod', { title: '设计概念导出', component: FbsFusionMethod, description: '将文本要求与图像概念融合导出设计概念', cover: get_asset_url('7ca17e2f-8b14-47d0-8511-1abfa9279559'), wtsMethod: 'fbs_fusion', template_id: 905, }],
      ['EnvironmentAnalysisMethod', { title: '市场环境分析', component: EnvironmentAnalysisMethod, description: '进行竞品分析与市场环境分析', cover: get_asset_url('7ca17e2f-8b14-47d0-8511-1abfa9279559'), wtsMethod: 'environment_analysis', template_id: 906, }],
      ['ProductFunctionMethod', { title: '设计机会点分析', component: ProductFunctionMethod, description: '分析产品功能的优化点', cover: get_asset_url('7ca17e2f-8b14-47d0-8511-1abfa9279559'), wtsMethod: 'product_function', template_id: 907, }],
      ['UserRequirementMethod', { title: '用户场景分析', component: UserRequirementMethod, description: '分析产品可能的用户场景', cover: get_asset_url('7ca17e2f-8b14-47d0-8511-1abfa9279559'), wtsMethod: 'user_requirement', template_id: 908, }],
      ['FunctionRefinementMethod', { title: '功能要点细化', component: FunctionRefinementMethod, description: '对产品的功能点进行改进', cover: get_asset_url('7ca17e2f-8b14-47d0-8511-1abfa9279559'), wtsMethod: 'function_refinement', template_id: 909, }],
      ['DesignPlanMethod', { title: '设计方案生成', component: DesignPlanMethod, description: '生成产品设计方案', cover: get_asset_url('7ca17e2f-8b14-47d0-8511-1abfa9279559'), wtsMethod: 'design_plan', template_id: 910, }],
      ['ProductIterationMethod', { title: '设计方案迭代', component: ProductIterationMethod, description: '对产品设计方案进行迭代', cover: get_asset_url('7ca17e2f-8b14-47d0-8511-1abfa9279559'), wtsMethod: 'product_iteration', template_id: 911, }],
      ['ImageEditingMethod', { title: '图像编辑', component: ImageEditingMethod, description: '对图像进行物体删除、替换，以及增加新物体', cover: get_asset_url('4a25337d-21e8-482e-846f-5b6f1233fead'), tags: ['原型'], wtsMethod: 'image_editing', template_id: 912, }],
      ['ImageObjectEditingMethod', { title: '图像对象编辑', component: ImageObjectEditingMethod, description: '将背景图中的物体替换成某对象、移动位置，向背景图中和谐地插入某物体等', cover: get_asset_url('4a25337d-21e8-482e-846f-5b6f1233fead'), tags: ['原型'], wtsMethod: 'image_object_editing', template_id: 913, }],
      ['ControlledImageGenerationMethod', { title: '受控图像生成', component: ControlledImageGenerationMethod, description: '按参考图中某种特征（例如线条边缘、深度信息等）受控地生成图像', cover: get_asset_url('4a25337d-21e8-482e-846f-5b6f1233fead'), tags: ['原型'], wtsMethod: 'controlled_image_generation', template_id: 914, }],
      ['SeatPressureCalcMethod', { title: '座椅压力计算', component: SeatPressureCalcMethod, description: '计算一个飞机座椅的压力分布', tags: ['逻辑'], wtsMethod: 'seat_pressure_calc', template_id: 915, }],
      ['SeatPressureSimulationMethod', { title: '压力分布模拟', component: SeatPressureSimulationMethod, description: '模拟特定飞机座椅的压力分布，得到分布矩阵', tags: ['逻辑'], wtsMethod: 'seat_pressure_simulation', template_id: 916, }],
      ['SeatPressure3DMethod', { title: '压力分布可视化', component: SeatPressure3DMethod, description: '根据压强仿真矩阵与座椅形状生成可视化图形', tags: ['逻辑'], wtsMethod: 'seat_pressure_3d', template_id: 917, }],
      ['TrizSummarizeProblemMethod', { title: 'TRIZ问题总结', component: TrizSummarizeProblemMethod, description: '根据用户需求总结问题参数', tags: ['逻辑'], wtsMethod: 'triz_summarize_problem', template_id: 918, }],
      ['TrizConvertParametersMethod', { title: 'TRIZ参数转化', component: TrizConvertParametersMethod, description: '将问题参数映射为TRIZ参数', tags: ['逻辑'], wtsMethod: 'triz_convert_parameters', template_id: 919, }],
      ['TrizContradictionAnalysisMethod', { title: 'TRIZ矛盾分析', component: TrizContradictionAnalysisMethod, description: '根据转化出的TRIZ参数进行矛盾分析，判断参数间是否有冲突的地方', tags: ['逻辑'], wtsMethod: 'triz_contradiction_analysis', template_id: 920, }],
      ['TrizInventionPrincipleMethod', { title: 'TRIZ原则分析', component: TrizInventionPrincipleMethod, description: '根据矛盾分析结果，推荐相应的TRIZ发明原则', tags: ['逻辑'], wtsMethod: 'triz_invention_principle', template_id: 921, }],
      ['TrizSolutionMethod', { title: 'TRIZ解决方案分析', component: TrizSolutionMethod, description: '根据推荐的发明原则，生成最终的解决方案', tags: ['逻辑'], wtsMethod: 'triz_solution', template_id: 922, }],
    ])?.get(cardType) ?? {}
  }
}

export const wtsMethodTypeToCardType = (() => {
  const list: [string, SupportedCardType][] = supportedMethodCardTypes.map(x => [cardType2DetailedInfo(x).wtsMethod, x])
  const res: Record<string, SupportedCardType> = {}
  for (const [k, v] of list) {
    if (!res[k]) {
      res[k] = v
    }
  }
  return res
})()

export const assetType2AssetType = (assetType: string) => {
  const map: Record<string, typeof supportedAssetCardTypes[number]> = {
    'string': 'TextAsset',
    'image': 'ImageAsset',
    'txt2img_prompt': 'PromptAsset',
    // 'model': 'ModelAsset',
  }
  return map[assetType]
}

export const stageTag2cardTypes: (tag: MethodStageTag) => SupportMethodCardType[] = (tag: MethodStageTag) => {
  if (tag === '所有') {
    return [...supportedMethodCardTypes]
  } else if (tag === '其他') {
    return supportedMethodCardTypes.filter(methodType => (cardType2DetailedInfo(methodType).tags ?? []).length === 0)
  } else {
    return supportedMethodCardTypes.filter(methodType => cardType2DetailedInfo(methodType).tags.indexOf(tag) !== -1)
  }
}
export const cardType2StageTags: (cardType: SupportMethodCardType) => MethodStageTag[] = (cardType: SupportMethodCardType) => {
  return cardType2DetailedInfo(cardType).tags
}
const cardTypeToComponentFunc =
  (
    cardType: SupportedCardType,
    cardID: UUID,
    cardIndex: number,
    otherParams: object
  ) =>
    ({ node, graph }) => {
      // console.log('cardTypeToComponentFunc, ', cardType);
      const Card = cardType2DetailedInfo(cardType).component
      return (
        <Card
          node={node}
          graph={graph}
          cardID={cardID}
          cardIndex={cardIndex}
          {...otherParams}
        />
      )
    }
type HistoryActionAddCard = {
  action: 'addCard'
  cardID: string
  cardType: SupportedCardType
  position: [number, number] // TODO
}
type HistoryAction = HistoryActionAddEdge | HistoryActionAddCard
type Serialized = {
  cardContext: object
  history: HistoryAction[]
  assetPortMap: Record<string, string>
}
export interface ICardContext {
  position: [number, number]
  // assets[groupId][subId]
  assets: UUID[][]
  // inPortID -> outPortId[]
  inPorts: Record<string, string[]>
  deleted?: boolean
  type: "asset" | "method"
}

// 存储 asset、method
export default class MetaStore {
  public context: RootContext

  // 记录正在编辑中的资产卡片信息
  public editingAssetsMap: Record<string, { register: () => any }> = {}
  public portsColor: Record<string, string> = {}

  public cards: IMyCard[] = []
  public methods: IMetaMethod[] = []
  // asset id -> port id
  public assetPortMap: Record<string, string> = {}
  public cardContext: Record<string, ICardContext> = {}
  private history: HistoryAction[] = []
  private loadFinished = false

  constructor(context: RootContext) {
    this.context = context
    makeAutoObservable(this, {}, { autoBind: true })
  }

  updateEditingAssetsMap(cardId: string, data: any) {
    this.editingAssetsMap[cardId] = {...data}
  }

  manuallyAddEdgeInCanvas =
    (graph: Graph) => (fromPortId: string, toPortId: string) => {
      addEdgeInCanvas(graph)(fromPortId, toPortId)
      this.onAddEdge(parsePort(fromPortId), parsePort(toPortId))
    }

  setPortColor = (portId: string, color: string) => {
    this.portsColor[portId] = color
  }

  onAddEdge = (fromPort: IPortInfo, toPort: IPortInfo) => {
    console.log('onaddedge', fromPort, toPort)
    // 只有这一个地方在写cardContext，因此这里应该初始化它的信息
    // cardContext包含一个卡片的信息，key就是它的id，value呢包含下面的内容：
    // position, 如果是asset，就包含assetID, 如果是method，会包含它输出的结果ID, deleted: 是否删除
    // 然而呢，我们新的逻辑是通过port来定位资产。因此我们需要搞一个通过portId获取的玩意
    // 通过portId得到cardId，然后拿到cardContext，再用一个通用的方法来获取assetId
    // 那么方法的输出也需要去放在这个特定的位置上，所以它的输出也得形式化
    // 不管是方法卡片还是资产卡片，都有asset的输出，那么我们这样设置：
    // assets: string[][], 代表assets[groupId][subId]
    // groupId: 表示该卡片的每个组，资产卡片只有一个组0，而方法卡片每次生成会在末尾添加一个组
    // subId：表示这个组的哪个资产。资产卡片也只有一个0，而方法卡片每个组可以有多个subId
    // 这样设计的好处是，通过portId可以唯一定位一个资产，而一个方法的一次生成也可以同时产生多个资产
    // 注意，这样每个方法的cardContext存的是它inPort的所有资产对应的卡片的portId
    // 因此，这里应该是inPorts: string[]
    // 总结：根据inPorts得到对应的卡片和输出桩，根据assets得到输出桩对应的资产

    const node: ICardContext = this.cardContext[toPort.cardId] ?? { position: [0, 0], assets: [], inPorts: {}, deleted: false, type: 'method' }
    const fromPortId = getPortIdByObj(fromPort)
    const toPortId = getPortIdByObj(toPort)

    if (!node.inPorts[toPortId]) {
      node.inPorts[toPortId] = []
    }

    node.inPorts[toPortId].push(fromPortId)

    this.cardContext[toPort.cardId] = node

    this.history.push({
      action: 'addEdge',
      fromPortID: fromPortId,
      toPortID: toPortId
    })
  }

  addCard = (cardID: UUID, type: "asset" | "method", asset_type?: string) => {
    // 如果为asset类型则加入到editingAssetsMap中
    this.cardContext[cardID] = this.cardContext[cardID] ?? { position: [0, 0], assets: [], inPorts: {}, type, deleted: false }
    this.cards.push({
      cardID,
    })
    return this.cards.length - 1
  }

  // TODO: appends to the history instead of modify it
  removeEdgeFromHistory = (fromPortID: string, toPortID: string) => {
    this.history = this.history.filter(item => {
      if (item.action !== 'addEdge') { return true }
      return !(item.fromPortID === fromPortID && item.toPortID === toPortID)
    })
  }

  removeEdge = (edge: Edge) => {
    console.log("remove")
    // TODO: make it atomic
    try {
      const [fromPortID, toPortID] = [edge.getSourcePortId(), edge.getTargetPortId()]
      const target = this.cardContext[edge.getTargetCellId()]
      target.inPorts[toPortID] = target.inPorts[toPortID].filter(port => port !== fromPortID)
      edge.remove()
      this.removeEdgeFromHistory(fromPortID, toPortID)
      this.save(this.context.store.wts)
    } catch (e) {
      message.error('删边失败') // TODO: i18n
    }
  }

  // 将方法卡片的输出注册为资产
  registerCardOutputAsset = (portId: string, assetID: UUID) => {
    this.assetPortMap[assetID] = portId
  }

  // 将用户拖出来的资产卡片注册为资产
  registerNonAssetCardAsAsset = async (
    cardID: UUID,
    assetData: unknown,
    assetType: string,
  ) => {
    const wtsStore = this.context.store.wts
    const name = await get_asset_readable_name(assetType)
    const assetID = await wtsStore.operator.add_asset(assetData, name, assetType, cardID)
    this.assetPortMap[assetID] = getPortId(cardID, 'out', 0, 0)
    this.cardContext[cardID] = { ...this.cardContext[cardID], assets: [[assetID]], inPorts: {} }
    return assetID
  }

  // 将资产库中已有的资产和卡片关联注册
  registerNonAssetCardAsAssetMock = (cardID: UUID, assetID: UUID) => {
    console.log(cardID, assetID)
    this.assetPortMap[assetID] = getPortId(cardID, 'out', 0, 0)
    this.cardContext[cardID] = { ...this.cardContext[cardID], assets: [[assetID]], inPorts: {} }
  }

  addGeneralAssetCard = (graph: Graph) =>
    (
      shouldAddToHistory: boolean,
      cardID: UUID,
      position: [number, number],
      asset: Asset,
    ) => {
      console.log(`addGeneralAssetCard, cardID: ${cardID}, position: ${position}, asset: ${JSON.stringify(asset)}`)
      this.addCard(cardID, "asset",asset.type)
      addAssetInCanvas({
        graph: graph,
        cardID: cardID,
        component: ({ node, graph }) => (
          <GeneralAssetCard
            asset={asset}
            args={{
              node,
              graph,
              cardID,
              isEditable: false,
              portInfo: {
                cardId: cardID,
                portType: 'out',
                groupId: 0,
                subId: 0,
              },
              showController: true,
            }}
          />
        ),
        position,
      })
      this.cardContext[cardID] = { ...this.cardContext[cardID], position, assets: [[asset.id]] }
      if (shouldAddToHistory) {
        this.history.push({
          action: 'addCard',
          cardType: assetType2AssetType(asset.type),
          cardID,
          position,
        })
      }
    }

  addTextAssetCard =
    (graph: Graph) =>
      (
        shouldAddToHistory: boolean,
        cardID: UUID,
        position: [number, number],
        text = '',
        isEditable = true,
        // isDraggable = true
        title = '',
      ) => {
        this.addCard(cardID, "asset","string")

        addAssetInCanvas({
          graph: graph,
          cardID: cardID,
          component: ({ node, graph }) => (
            <TextAsset
              node={node}
              graph={graph}
              text={text}
              title={title}
              type={"string"}
              cardID={cardID}
              isEditable={isEditable}
              portInfo={{
                cardId: cardID,
                portType: 'out',
                groupId: 0,
                subId: 0,
              }}
              showController={true}
              plannerAdded={true}
            />
          ),
          position,
        })
        this.cardContext[cardID] = { ...this.cardContext[cardID], position }
        if (shouldAddToHistory) {
          this.history.push({
            action: 'addCard',
            cardType: 'TextAsset',
            cardID,
            position,
          })
        }
      }

  // TODO: make it general
  addPromptAssetCard =
    (graph: Graph) =>
      (
        shouldAddToHistory: boolean,
        cardID: UUID,
        position: [number, number],
        prompt = { prompt: 'mountain', negative_prompt: 'NSFW' },
        isEditable = true
      ) => {
        this.addCard(cardID, "asset","txt2img_prompt")

        addAssetInCanvas({
          graph: graph,
          cardID: cardID,
          component: ({ node, graph }) => (
            <PromptAsset
              node={node}
              graph={graph}
              cardID={cardID}
              isEditable={isEditable}
              prompt={prompt}
              portInfo={{
                cardId: cardID,
                portType: 'out',
                groupId: 0,
                subId: 0,
              }}
              showController={true}
            />
          ),
          position,
        })
        this.cardContext[cardID] = { ...this.cardContext[cardID], position }
        console.log('cardcontext', this.cardContext)
        if (shouldAddToHistory) {
          this.history.push({
            action: 'addCard',
            cardType: 'PromptAsset',
            cardID,
            position,
          })
        }
      }

  addImageAssetCard =
    (graph: Graph) =>
      (
        shouldAddToHistory: boolean,
        cardID: UUID,
        position: [number, number],
        images: string[] = [],
        isEditable = true
      ) => {
        this.addCard(cardID, "asset","image")
          console.log(cardID)
        addAssetInCanvas({
          graph: graph,
          cardID: cardID,
          component: ({ node, graph }) => (
            <ImageAsset
              node={node}
              graph={graph}
              cardID={cardID}
              initialImageIDs={images}
              isEditable={isEditable}
              portInfo={{
                cardId: cardID,
                portType: 'out',
                groupId: 0,
                subId: 0,
              }}
              showController={true}
            />
          ),
          position,
        })
        this.cardContext[cardID] = { ...this.cardContext[cardID], position }
        console.log('cardcontext', this.cardContext)
        if (shouldAddToHistory) {
          this.history.push({
            action: 'addCard',
            cardType: 'ImageAsset',
            cardID,
            position,
          })
        }
      }

  // since added by WTS, shouldAddToHistory = true
  addGeneralMethodThroughWTS = (graph: Graph) => (
    wtsStore: WtsStore,
    position: [number, number],
    cardType: SupportMethodCardType,
    otherParams: object = {}
  ) => {
    const wtsMethod = cardType2DetailedInfo(cardType).wtsMethod
    console.log('addGeneralMethodThroughWTS', wtsMethod);
    wtsStore.operator.add_method(wtsMethod, null).then((designMethod) => {
      this.addGeneralMethod(graph)(
        true,
        designMethod.id,
        position,
        cardType
      )
    })
  }

  addGeneralMethod =
    (graph: Graph) =>
      (
        shouldAddToHistory: boolean,
        cardID: UUID,
        position: [number, number],
        cardType: SupportedCardType,
        otherParams: object = {}
      ) => {
        // TODO: wts.add_method
        const cardIndex = this.addCard(cardID, "method")
        addMethodInCanvas({
          graph,
          cardID,
          component: cardTypeToComponentFunc(
            cardType,
            cardID,
            cardIndex,
            otherParams
          ),
          position,
        })
        this.cardContext[cardID] = { ...this.cardContext[cardID], position }
        if (shouldAddToHistory) {
          this.history.push({
            action: 'addCard',
            cardType,
            cardID,
            position,
          })
        }
      }

  clear = (graph: Graph) => {
    graph.clearCells()
    this.history = []
    this.cards = []
    this.cardContext = {}
    this.assetPortMap = {}
  }

  save = async (wtsStore: WtsStore) => {
    const serialized: Serialized = {
      cardContext: this.cardContext,
      history: this.history,
      assetPortMap: this.assetPortMap,
    }
    console.log('serialized', JSON.parse(JSON.stringify(serialized)))
    await wtsStore.operator.save_canvas(serialized)
  }

  delete = (cardID: UUID, wtsStore: WtsStore) => {
    this.cardContext[cardID].deleted = true
    // this.save(wtsStore).then(() => {
    //   message.info('自动保存成功')
    // })
  }

  loadChat = async (wtsStore: WtsStore, chatStore?: ChatStore) => {
    chatStore.clearMessages()
    const chatHistory = await wtsStore.operator.list_planner_messages(null, 10000) // TODO: 自动翻页
    for (const chatEntity of chatHistory) {
      if (chatEntity.role === 'tool') {
        chatStore.addMessage(chatEntity.content, 'system2')
      } else {
        chatStore.addMessage(chatEntity.content, chatEntity.role)
      }
    }
  }

  load = async (wtsStore: WtsStore, graph: Graph, chatStore?: ChatStore) => {
    // Step 1: 下载保存的画布信息并获取design实例
    const serialized = (await wtsStore.operator.load_canvas()) as Serialized
    const design = wtsStore.operator.design

    // Step 2: 设置画布信息
    this.cards = []
    this.cardContext = serialized.cardContext as Record<string, ICardContext> ?? {}
    this.assetPortMap = serialized.assetPortMap ?? {}
    console.log('load', serialized)

    // Step 3: 加载画布元素
    // 以卡片为单位，首先加载所有卡片，然后再加载边
    const toAddEdges = []

    for (const cardId in this.cardContext) {
      const card = this.cardContext[cardId]
      if (card.deleted) continue;
      if (card.type === 'asset') {
        if (!card.assets?.[0]?.[0]) continue;
        const asset = await wtsStore.app.get_asset_meta(card.assets[0][0])
        this.addGeneralAssetCard(graph)(false, cardId, card.position, asset)
      } else {
        const method = design.methods[cardId]
        try {
          this.addGeneralMethod(graph)(false, cardId, card.position, wtsMethodTypeToCardType[method.type])

          // add edges
          for (const inPortId in card.inPorts) {
            for (const outPortId of card.inPorts[inPortId]) {
              toAddEdges.push({
                source: outPortId,
                target: inPortId,
              })
            }
          }
        } catch (e) {
          console.error(e, 'method=', method)
          message.error('画布元素加载失败')
        }
      }
    }

    for (const { source, target } of toAddEdges) {
      addEdgeInCanvas(graph)(source, target)
    }

    // Step 4: 加载聊天记录
    chatStore && (await this.loadChat(wtsStore, chatStore))

    this.loadFinished = true
  }

  loadExtraMethods = async (wtsStore: WtsStore, graph: Graph) => {
    // 首先保证load结束
    for (let i = 0; !this.loadFinished; i++) {
      await sleep(100)
      if (i === 99) {
        console.warn('等待画布加载超时')
        return
      }
    }

    const template = wtsStore.operator.design.template
    const serialized = template.canvas as Serialized;
    console.log(template)

    if (!template) {
      console.warn('没有模板，退出')
      return
    }

    for (const cardId in serialized.cardContext) {
      const card = serialized.cardContext[cardId]
      if (card.deleted) continue;
      if (card.type === 'method') {
        const method = template.methods[cardId]
        const newMethod = await wtsStore.operator.add_method(method.type, null)
        method.id = newMethod.id
        method.outputs = []
        for (const input of method.inPorts) {
          input.assets = []
        }
        await wtsStore.operator.update_method(method)
        try {
          this.addGeneralMethod(graph)(false, method.id, card.position, wtsMethodTypeToCardType[method.type])
        } catch (e) {
          console.error(e, 'method=', method)
          message.error('画布元素加载失败')
        }
      }
    }
  }
}

const sleep = async (time: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

