import styles from './AssetsDetail.module.scss'
import { useColorVar, useStyles } from "@/hooks/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useWtsStore } from "@/hooks";
import {FC, useEffect, useState} from "react";
import { SvgIcon } from "@/components/icons";
import { IconLeftArrow } from "@/components/icons/IconLeftArrow";
import Marquee from "react-fast-marquee";
import { Spin } from "antd";

interface IAssetInfo {
  Name?: string,
  Category?: string,
  Description?: string,
  Client_Manufacturer?: string,
  Design?: string,
  DateOfLaunch?: string,
  DevelopmentTime?: string,
  TargetRegions?: string,
  TargetGroups?: string,
  img?:string[]
}

const AssetsDetail = () => {

  const c = useColorVar()
  const styleClass = useStyles(styles)

  const navigation = useNavigate()
  const location = useLocation()
  const { assetID } = useParams();
  const wtsStore = useWtsStore()

  const [assetInfo, setAssetInfo] = useState<IAssetInfo>()
  const [ifLoading, setIfLoading] = useState<boolean>(true)

  const getAssetInfoByID = async (assetID: string) => {
    setIfLoading(true)

    console.log(assetID)

    const data: IAssetInfo = await wtsStore.app.get_asset_data(assetID)
    data.img = data.img?.map(id => wtsStore.app.get_asset_url(id))

    setTimeout(() => {
      setAssetInfo(data)
      setIfLoading(false)
    },500)
  }

  useEffect(() => {
    // 根据assetID去拉取数据
    getAssetInfoByID(assetID as string)
  },[])

  return (
    <Spin spinning={ifLoading}>
      <div {...styleClass(['layout'])}>
        <div {...styleClass(['header-layout'])}>
          <SvgIcon icon={IconLeftArrow} {...styleClass(['header-back'])} onClick={() => navigation(-1)} />
          <div {...styleClass(['header-meta'])}>
            <div {...styleClass(['header-meta-title'])}>{assetInfo?.Name}</div>
            <div {...styleClass(['header-meta-subtitle'])}>{assetInfo?.Category}</div>
          </div>
        </div>

        <div {...styleClass(['img-gallery'])}>
          <ImageCarousel images={assetInfo?.img} />
          <div {...styleClass(['img-gallery-mask'])}></div>
        </div>

        <div {...styleClass(['description'])}>
          {assetInfo?.Description}
        </div>

        <div {...styleClass(['info'])}>
          <div {...styleClass(['info-title'])}>{'更多信息'}</div>
          <div {...styleClass(['info-line'])}>
            <div {...styleClass(['info-block'])}>
              <div {...styleClass(['info-block-title'])}>{'设计者'}</div>
              <div {...styleClass(['info-block-text'])}>{assetInfo?.Design}</div>
            </div>
          </div>

          <div {...styleClass(['info-line'])}>
            <div {...styleClass(['info-block'])}>
              <div {...styleClass(['info-block-title'])}>{'发布日期'}</div>
              <div {...styleClass(['info-block-text'])}>{assetInfo?.DateOfLaunch}</div>
            </div>
            <div {...styleClass(['info-block'])}>
              <div {...styleClass(['info-block-title'])}>{'开发时间'}</div>
              <div {...styleClass(['info-block-text'])}>{assetInfo?.DevelopmentTime}</div>
            </div>
          </div>

          <div {...styleClass(['info-line'])}>
            <div {...styleClass(['info-block'])}>
              <div {...styleClass(['info-block-title'])}>{'目标地域'}</div>
              <div {...styleClass(['info-block-text'])}>{assetInfo?.TargetRegions}</div>
            </div>
            <div {...styleClass(['info-block'])}>
              <div {...styleClass(['info-block-title'])}>{'目标人群'}</div>
              <div {...styleClass(['info-block-text'])}>{assetInfo?.TargetGroups}</div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default AssetsDetail


interface IImageCarousel {
  images: string[]
}
const ImageCarousel: FC<IImageCarousel> = ({ images  = []}) => {
  const styleClass = useStyles(styles)


  return (
    <Marquee {...styleClass(['carousel'])} autoFill={true} speed={16} loop={0} pauseOnHover={true}>
      {images.length > 0 && images.map((item, index) => (
        <div key={`${item}-${index}`} {...styleClass(['carousel-item'])}>
          <img {...styleClass(['carousel-item-img'])} src={item} draggable={false} />
        </div>
      ))}
    </Marquee>
  )
}