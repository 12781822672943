import { CSSProperties, FC, useEffect, useState } from 'react'
import styles from './PromptAsset.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { AssetCard } from '@/components/graph/assets/AssetCard/AssetCard'
import { SvgIcon } from '@/components/icons'
import { Input } from 'antd'
import { IPortInfo, Port } from '@/components/graph/Port/Port'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { useMetaStore, useWtsStore } from '@/hooks'
import { StaticLayout } from '@/components/StaticLayout/StaticLayout'
import { IconPrompt } from '@/components/icons/IconPrompt'

interface IProps {
  cardID?: string
  width?: CSSProperties['width']
  isEditable?: boolean // TODO: infer use cardID
  node?: Node
  graph?: Graph
  prompt?: { prompt: string; negative_prompt: string }
  portInfo: IPortInfo
  showController?: boolean
  title?: string
}

export const PromptAsset: FC<IProps> = observer(
  ({
    cardID,
    width,
    isEditable: isEditableParam,
    node,
    graph,
    prompt,
    portInfo,
    showController,
    title,
  }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()

    const [isEditable, setIsEditable] = useState(isEditableParam)

    const [promptArg, setPromptArg] = useState(prompt)

    useEffect(() => {
      setPromptArg(prompt)
    }, [prompt])

    const registerAsAsset = async () => {
      await metaStore.registerNonAssetCardAsAsset(
        cardID,
        promptArg,
        'txt2img_prompt'
      )
      setIsEditable(false)
    }

    return (
      <AssetCard
        width={width}
        headerColor={c('Blue-background')}
        cardTitle={'Prompt' + (title ? " - " + title : '')}
        cardIcon={<SvgIcon icon={IconPrompt} />}
        isEditable={isEditable}
        onUpload={undefined}
        registerAsAsset={registerAsAsset}
        node={node}
        graph={graph}
        portInfo={portInfo}
        showController={showController}
        cardID={cardID}
      >
        <div {...styleClass(['layout'])}>
          <StaticLayout>
            <div {...styleClass(['content'])}>
              <div {...styleClass(['prompt'])}>
                <div {...styleClass(['prompt-title'])}>positive</div>
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  disabled={!isEditable}
                  value={promptArg.prompt}
                  onChange={(e) => setPromptArg(p => ({ ...p, prompt: e.target.value }))}
                ></Input.TextArea>
              </div>
              <div {...styleClass(['prompt'])}>
                <div {...styleClass(['prompt-title'])}>negative</div>
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  disabled={!isEditable}
                  value={promptArg.negative_prompt}
                  onChange={(e) => setPromptArg(p => ({ ...p, negative_prompt: e.target.value }))}
                ></Input.TextArea>
              </div>
            </div>
          </StaticLayout>
          {showController && <div {...styleClass(['port-wrapper'])}>
            <Port
              circleColor={c('Blue-main')}
              borderColor={c('Blue-light')}
              portInfo={portInfo}
            />
          </div>}
        </div>
      </AssetCard>
    )
  }
)
