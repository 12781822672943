import styles from './Login.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useLang } from '@/hooks/i18n'
import lang from './Login.i18n.json'
import { Button, Checkbox, Form, Input, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useUserStore, useWtsStore } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'

const Login = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const { t } = useLang(lang, 'Login')

  const navigation = useNavigate()
  const userStore = useUserStore()
  const wtsStore = useWtsStore()

  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const [ifLoading, setIfLoading] = useState<boolean>(false)

  const login = async () => {
    // console.log(form.getFieldsValue())
    setIfLoading(true)
    form
      .validateFields(['userName', 'userPassword'])
      .then(async () => {
        // 如果form自带的校验通过
        const result = await wtsStore.app.login(
          form.getFieldValue('userName'),
          form.getFieldValue('userPassword')
        )

        if (result !== null) {
          // 登录成功，此时result为token字符串

          const userMeta = await wtsStore.app.get_user_info()
          wtsStore.app.currentUser = userMeta

          userStore.setUserInfo({
            id: userMeta.id,
            nickName: userMeta.name,
            token: result,
          })
          // 保存登录状态至store和localStorage
          userStore.setUserStatus(true)

          messageApi.success({ content: `欢迎回来！"${userMeta.name}"` })

          // 登陆成功后默认跳转/projects页面
          setTimeout(() => {
            navigation('/projects')
          }, 500)
        } else {
          // 如果result为null，则用户名或密码错误
          messageApi.error({ content: '用户名或密码错误！' })
        }
        return
      })
      .catch((err) => {
        console.error(err)
        // 如果form自带的校验不通过，则用户名或密码错误
        messageApi.info({ content: '输入为空！' }) // TODO: prettify
      })

    setIfLoading(false)
  }

  const autoInput = () => {
    form.setFieldsValue({ userName: 'inlab@2023', userPassword: '123456' })
  }

  return (
    <Form form={form} initialValues={{ userName: '', userPassword: '' }}>
      {contextHolder}
      <div {...styleClass(['wrapper'])}>
        <motion.div
          {...styleClass(['card-layout'])}
          animate={{ y: -70 }}
          transition={{ ...motionEasing, duration: 0.5, delay: 0.1 }}
        >
          <div {...styleClass(['card-bar'])}></div>
          <div {...styleClass(['card-inputs'])}>
            <Form.Item
              name="userName"
              rules={[
                { required: true, message: 'Please input your Username!' },
              ]}
            >
              <Input
                {...styleClass(['card-inputs-text'])}
                size={'large'}
                prefix={
                  <UserOutlined className="site-form-item-icon" rev={null} />
                }
                placeholder="Username: inlab@2023"
                onChange={(e) => form.setFieldValue('userName', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="userPassword"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input
                {...styleClass(['card-inputs-text'])}
                size={'large'}
                prefix={
                  <LockOutlined className="site-form-item-icon" rev={null} />
                }
                type="password"
                placeholder="Password: 123456"
                onChange={(e) =>
                  form.setFieldValue('userPassword', e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div {...styleClass(['card-tools'])}>
            <Checkbox defaultChecked={true}>{t`Remember me`}</Checkbox>
            <motion.div
              {...styleClass(['card-tools-link'])}
              initial={{ color: c('primary') }}
              whileHover={{ color: 'rgba(87,13,248,0.65)' }}
              transition={{ ...motionEasing, duration: 0.25 }}
              onClick={() => autoInput()}
              id="auto-input"
            >
              {t`Automatic Input`}
            </motion.div>
          </div>
          <Form.Item noStyle>
            <Button
              {...styleClass(['card-button'])}
              type={'primary'}
              size={'large'}
              loading={ifLoading}
              onClick={() => login()}
              id="login-button"
            >
              {t`Log in`}
            </Button>
          </Form.Item>
        </motion.div>
      </div>
    </Form>
  )
}

export default Login
