import React, { ReactNode, useContext, useReducer } from 'react'
import { AppContext } from '@/App.context'
import ReactDOM from 'react-dom'

export const useRootStore = () => {
  const context = useContext(AppContext)
  return context.store
}

export const useAppContext = () => {
  return useContext(AppContext)
}

export const useAppStore = () => {
  return useRootStore().app
}

export const useExplanationViewStore = () => {
  return useRootStore().explanationView
}

export const useGraphStore = () => {
  return useRootStore().graph
}

export const useUserStore = () => {
  return useRootStore().user
}

export const useChatStore = () => {
  return useRootStore().chat
}

export const useMetaStore = () => {
  return useRootStore().meta
}

export const useWtsStore = () => {
  return useRootStore().wts
}

export const useForceUpdate = () => {
  return useReducer(() => {
    // console.log('force update')
    return {}
  }, {})
}

export const getFCSize = (component: ReactNode): [number, number] => {
  /* const container = document.createElement('div')
  container.style.visibility = 'hidden'
  container.style.position = 'absolute'
  document.body.appendChild(container)

  const validComponent = (Component) => <Component />
  // ReactDOM.createRoot(container).render(<div>{validComponent(component)}</div>)
  container.innerHTML = renderToString(<div>{validComponent(component)}</div>)
  const size = {
    width: container.offsetWidth,
    height: container.offsetHeight,
  }

  document.body.removeChild(container)

  return [size['width'], size['height']] */

  const validComponent = (Component) => <Component />
  const container = document.createElement('div')
  container.style.visibility = 'hidden'
  container.style.position = 'absolute'
  document.body.appendChild(container)
  ReactDOM.render(validComponent(component), container)
  const size = {
    width: container.offsetWidth,
    height: container.offsetHeight,
  }
  document.body.removeChild(container)
  return [size['width'], size['height']]
}
