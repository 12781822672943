import { FC } from 'react'
import styles from './RefinityVideo.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { observer } from 'mobx-react'
import { Modal } from 'antd'
import { Player } from 'video-react'
import { useState } from 'react'
import 'video-react/dist/video-react.css' // 这是video-react专用的css
interface IVideoAsset {
  videoUrl?: string
  showController?: boolean
  open?: boolean
  onClose: () => void
}

export const RefinityVideo: FC<IVideoAsset> = observer(
  ({ videoUrl, showController, open, onClose }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)

    const [posterUrl, setPosterUrl] = useState('')

    return (
        <>
            <Modal
                open={open}
                closable={true}
                onCancel={onClose}
                footer={null}
                width={1160}
                style={{ padding: '5px' }}
                {...styleClass(['modal'])}
            >   
                {
                    open && (
                        <Player
                            // playsInline
                            src={videoUrl}
                            poster={posterUrl}
                            preload={'metadata'}
                        />
                    )
                }
            </Modal>
        </>
    )
  }
)
