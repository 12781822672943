import React, { FC, useEffect, useState } from 'react'
import {
  IDiscreteParam,
  IImageClickParam,
  IDiscreteParamWithButton,
  IImageEditParam,
} from '@/components/graph/methods/utils'
import { getPortId } from '@/components/MainGraph/utils'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'

const operationModeParam: IDiscreteParamWithButton<string> = {
  id: 'operation_mode',
  type: 'select-with-button',
  title: '操作模式',
  default: '移除',
  options: ['移除', '替换', '填充'],
}

const testImageURL =
  'https://fengyuanchen.github.io/cropperjs/images/picture.jpg'

const imgClickParam: IImageClickParam = {
  id: 'click_coordinates',
  type: 'img-click',
  default: { x: 0, y: 0 },
  title: '点击修改点',
  onClick: (x, y) => {
    // console.log(`Clicked coordinates: x=${x}, y=${y}`);
  },
  imgUrl: testImageURL,
  // imgUrl: '',
  assetID: '',
}

const inpaintParams = [operationModeParam, imgClickParam]

export const ImageEditingMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod((id) => ({
    cardType: 'ImageEditingMethod',
    params: inpaintParams,
    inputs: [{ title: '处理图片', type: 'img' }],
    outputParser: (output) => output?.image?.map(({ id }) => id) ?? [],
    isGenerateButtonFrozen: (cardID, inPorts) =>
      inPorts[getPortId(cardID, 'in', 1, 0)]?.[0] ? false : true,
    styles: {},
  }))
