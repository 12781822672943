import { CSSProperties, FC, ReactNode } from 'react'
import { Graph } from '@antv/x6'
import { useStyles } from '@/hooks/styles'
import { useGraphStore } from '@/hooks'

// 在此区域内禁用画布的节点选择、节点移动、键盘事件
interface IStaticLayout {
  children?: ReactNode
  className?: string
  style?: CSSProperties
}
export const StaticLayout: FC<IStaticLayout> = ({
  children,
  className,
  style,
}) => {
  const styleClass = useStyles()
  const graphStore = useGraphStore()
  const isSpacePressed = false

  const onMouseEnter = (e) => {
    if (isSpacePressed) {
      e.preventDefault()
      graphStore.graph.disablePanning()
      graphStore.graph.disableSelection()
      graphStore.graph.disableKeyboard()
      graphStore.setNodesMovable(false)
    }
  }
  const onMouseLeave = (e) => {
    if (isSpacePressed) {
      e.preventDefault()
      graphStore.graph.enablePanning()
      graphStore.graph.enableSelection()
      graphStore.graph.enableKeyboard()
      graphStore.setNodesMovable(true)
    }
  }
  return (
    <div
      {...styleClass([], {}, { className, style })}
      onMouseEnter={(e) => graphStore.graph && onMouseEnter(e)}
      onMouseLeave={(e) => graphStore.graph && onMouseLeave(e)}
    >
      {children}
    </div>
  )
}
