import React, { FC, useEffect, useState } from 'react'
import styles from './ImageGenerateMethod.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { MethodCard } from '@/components/graph/methods/MethodCard/MethodCard'
import { SvgIcon } from '@/components/icons'
import { DataAnalysis } from '@/components/icons/DataAnalysis'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { AssetInPort } from '@/components/graph/assets/AssetInPort/AssetInPort'
import { ICardContext, UUID } from '@/store/metaStore'
import { useMetaStore, useWtsStore } from '@/hooks'
import { ImageAsset } from '@/components/graph/assets/ImageAsset/ImageAsset'
import {
  IDiscreteParam,
  IContinuousParam,
  IInputParam,
  ParamCard,
} from '@/components/graph/methods/utils'
import { getPortId, parsePort } from '@/components/MainGraph/utils'
import { ImageGenerationArguments } from 'wts'
import { IDesignGoalSummarizeMethodParams } from '../DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from '../GeneralMethod'

const modelParam: IDiscreteParam<string> = {
  id: '_model',
  type: 'select',
  title: '图像生成模型',
  default: 'stablediffuusion_v1_5',
  options: ['stablediffuusion_v1_5'],
}
const generateNumParam: IContinuousParam = {
  id: 'n_iter',
  type: 'slider',
  title: '生成数量',
  default: 1,
  min: 1,
  max: 4,
  step: 1,
}
const seedParam: IInputParam<number> = {
  id: 'seed',
  type: 'input',
  title: '随机种子数值',
  default: -1,
}
const relatedScoreParam: IContinuousParam = {
  id: 'cfg_scale',
  type: 'slider',
  title: '与提示词相关程度',
  default: 2,
  min: 1,
  max: 16,
  step: 1,
}
const referenceStrengthParam: IContinuousParam = {
  id: 'denoising_strength',
  type: 'slider',
  title: '参考图强度',
  default: 0.6,
  min: 0,
  max: 1,
  step: 0.02,
}
const i2iParams = [
  // modelParam,
  generateNumParam,
  seedParam,
  relatedScoreParam,
  referenceStrengthParam,
]

export const ImageGenerateMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'Image2ImageMethod',
    params: i2iParams,
    inputs: [
      { title: '文生图提示词', type: 'txt2img_prompt' },
      { title: '参考图(选填)', type: 'img' },
      { title: '遮罩图(选填)', type: 'img' },
    ],
    outputParser: (output) => output?.images?.map(({ id }) => id) ?? [],
    isGenerateButtonFrozen: (cardID, inPorts) =>
      inPorts[getPortId(cardID, 'in', 0, 0)]?.[0] ? false : true,
    styles,
  })

interface IImage2ImageMethodParams {
  data1?: any
  node?: Node
  graph?: Graph
  cardID?: UUID
  cardIndex?: number
  inputPromptAssetCardID?: UUID
  inputReferenceImageAssetCardID?: UUID
  inputMaskImageAssetCardID?: UUID
  outputImageIDs?: string[]
  // TODO: add params
  modelParam?: typeof modelParam.options[number]
  generateNumParam?: typeof generateNumParam.default
  seedParam?: typeof seedParam.default
  relatedScoreParam?: typeof relatedScoreParam.default
  referenceStrengthParam?: typeof referenceStrengthParam.default
}
export const LegacyImageGenerateMethod: FC<IImage2ImageMethodParams> = observer(
  ({
    node,
    graph,
    cardID,
    cardIndex,
    inputPromptAssetCardID,
    inputReferenceImageAssetCardID,
    inputMaskImageAssetCardID,
    outputImageIDs: outputImageIDsParam,
    modelParam: modelParamProps,
    generateNumParam: generateNumParamProps,
    seedParam: seedParamProps,
    relatedScoreParam: relatedScoreParamProps,
    referenceStrengthParam: referenceStrengthParamProps,
  }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()
    const wtsStore = useWtsStore()

    const [inPorts, setInPorts] = useState<ICardContext['inPorts']>({})
    const [assets, setAssets] = useState<ICardContext['assets']>([])

    useEffect(() => {
      const inPorts = metaStore.cardContext[cardID].inPorts
      setInPorts(inPorts)
    }, [metaStore.cardContext[cardID]]) // TODO

    // TODO: simplify this
    const [modelParamValue, setModelParamValue] = useState(
      modelParamProps ?? modelParam.default
    )
    const [generateNumParamValue, setGenerateNumParamValue] = useState(
      generateNumParamProps ?? generateNumParam.default
    )
    const [seedParamValue, setSeedParamValue] = useState(
      seedParamProps ?? seedParam.default
    )
    const [relatedScoreParamValue, setRelatedScoreParamValue] = useState(
      relatedScoreParamProps ?? relatedScoreParam.default
    )
    const [referenceStrengthParamValue, setReferenceStrengthParamValue] =
      useState(referenceStrengthParamProps ?? referenceStrengthParam.default)

    // useEffect(() => {
    //   console.log(
    //     'MarketResearchMethod',
    //     'outputImageIDsParam',
    //     outputImageIDsParam
    //   )
    // }, [outputImageIDsParam])

    const isGenerateButtonFrozen = () =>
      inPorts[getPortId(cardID, 'in', 0, 0)]?.[0] ? false : true

    const image2imageGenerate = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      console.log('image2imageGenerate, method', designMethod)
      console.log('image2imageGenerate, inPorts', designMethod.inPorts)
      try {
        for (let i = 0; i < 3; i++) {
          const inPortId = getPortId(cardID, 'in', i, 0)
          const groupAssets = []
          for (const outPort of inPorts[inPortId]) {
            const portInfo = parsePort(outPort)
            const asset =
              metaStore.cardContext[portInfo.cardId]?.assets[portInfo.groupId][
                portInfo.subId
              ]
            if (asset) {
              groupAssets.push(asset)
            }
          }
          designMethod.inPorts[i].assets = groupAssets
        }
        console.log('image2imageGenerate, inports assets', designMethod.inPorts)
      } catch {
        console.error('image2imageGenerate, inPort not asset')
      }
      await wtsStore.operator.update_method({
        ...designMethod,
        argument: {
          ...designMethod.argument,
          n_iter: generateNumParamValue,
          seed: seedParamValue,
          cfg_scale: relatedScoreParamValue,
          denoising_strength: referenceStrengthParamValue,
        },
      } as ImageGenerationArguments)
      console.log('image2imageGenerate before run method')
      let retDesignMethod
      try {
        retDesignMethod = await wtsStore.operator.run_method(designMethod.id)
      } catch (e) {
        console.error('image2imageGenerate run method exception', e)
        return
      }
      console.log('image2imageGenerate return', retDesignMethod)
      console.log(
        'image2imageGenerate return',
        retDesignMethod,
        retDesignMethod.outputs[0]?.images[0]?.id
      )
      setAssets((assets) => {
        const ids =
          retDesignMethod.outputs[0]?.images?.map(
            (x: { id: string }) => x.id
          ) ?? []
        const new_assets = [...assets, ids]
        for (let i = 0; i < ids.length; i++) {
          metaStore.registerCardOutputAsset(
            getPortId(cardID, 'out', new_assets.length - 1, i),
            ids[i]
          )
        }
        metaStore.cardContext[cardID].assets = new_assets
        return new_assets
      })
    }

    const input = (
      <>
        <AssetInPort
          assetType={'txt2img_prompt'}
          title={'文生图提示词'}
          portInfo={{ cardId: cardID, portType: 'in', groupId: 0, subId: 0 }}
        />
        <AssetInPort
          assetType={'img'}
          title={'参考图(选填)'}
          portInfo={{ cardId: cardID, portType: 'in', groupId: 1, subId: 0 }}
        />
        <AssetInPort
          assetType={'img'}
          title={'遮罩图(选填)'}
          portInfo={{ cardId: cardID, portType: 'in', groupId: 2, subId: 0 }}
        />
      </>
    )

    const params = (
      <>
        <ParamCard
          param={modelParam}
          value={modelParamValue}
          onChange={(v) => setModelParamValue(v)}
        />
        <ParamCard
          param={generateNumParam}
          value={generateNumParamValue}
          onChange={(v) => setGenerateNumParamValue(v)}
        />
        <ParamCard
          param={seedParam}
          value={seedParamValue}
          onChange={(v) => setSeedParamValue(v)}
        />
        <ParamCard
          param={relatedScoreParam}
          value={relatedScoreParamValue}
          onChange={(v) => setRelatedScoreParamValue(v)}
        />
        <ParamCard
          param={referenceStrengthParam}
          value={referenceStrengthParamValue}
          onChange={(v) => setReferenceStrengthParamValue(v)}
        />
      </>
    )

    const output = assets.map((outputImageIDs, i) => {
      return outputImageIDs.map((id, j) => (
        <ImageAsset
          key={i}
          width={'100%'}
          isEditable={false}
          initialImageIDs={[id]}
          portInfo={{
            cardId: cardID,
            portType: 'out',
            groupId: i,
            subId: j,
          }}
          showController={true}
        />
      ))
    })

    // TODO: replace icon
    return (
      <MethodCard
        width={384}
        cardTitle={'图片生成'}
        cardIcon={<SvgIcon icon={DataAnalysis} />}
        node={node}
        graph={graph}
        input={input}
        params={params}
        output={output}
        isButtonDisabled={isGenerateButtonFrozen()}
        onClick={image2imageGenerate}
      ></MethodCard>
    )
  }
)
