import { getPortId } from '@/components/MainGraph/utils'
import { TextAsset } from '@/components/graph/assets/TextAsset/TextAsset'
import { MethodCard } from '@/components/graph/methods/MethodCard/MethodCard'
import {
  IContinuousParam,
  IInputParam,
  IMultiRowGroupAddAbleDiscreteParam,
  ParamCard,
} from '@/components/graph/methods/utils'
import { SvgIcon } from '@/components/icons'
import { Add_707070 } from '@/components/icons/Add_707070'
import { DataAnalysis } from '@/components/icons/DataAnalysis'
import { useMetaStore, useWtsStore } from '@/hooks'
import { useColorVar, useStyles } from '@/hooks/styles'
import { ICardContext, UUID } from '@/store/metaStore'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { FC, useEffect, useRef, useState } from 'react'
import { get_asset_data, DesignMethod, MarketAnalysisArguments } from 'wts'
import { AssetInPort } from '../../assets/AssetInPort/AssetInPort'
import styles from './MarketResearchMethod.module.scss'
import { GeneralMethod, MethodDescriptor } from '../GeneralMethod'
import { IDesignGoalSummarizeMethodParams } from '../DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'

const productParam: IInputParam<string> = {
  id: 'product',
  type: 'input',
  title: '调研对象',
  default: '',
  placeholder: '输入需要调研的产品',
}

const minCountParam: IContinuousParam = {
  id: 'numberLimits',
  type: 'slider',
  title: '最小调研数量',
  min: 1,
  max: 10,
  step: 1,
  default: 3,
  valueMapper: (args, min: number) => [min, 10],
}

const columnParam: IMultiRowGroupAddAbleDiscreteParam<string> = {
  id: 'features',
  type: 'multi-row-group-addable-select',
  title: '输出字段配置',
  placeholder: '选择输出表格的字段',
  default: [],
  options: [
    {
      label: '标签1',
      options: ['产品名1', '功能1'],
    },
    {
      label: '标签2',
      options: ['产品名2', '功能2'],
    },
  ],
}

export const MarketResearchMethod: FC<IMarketResearchMethodParams> =
  GeneralMethod({
    cardType: 'MarketResearchMethod',
    params: [productParam, minCountParam, columnParam],
    inputs: [{ title: '背景描述', type: 'img' }],
    outputParser: (output) => [output?.['table']?.id],
    styles,
  })

interface IMarketResearchMethodParams {
  data1?: any
  node?: Node
  graph?: Graph
  cardID?: UUID
  cardIndex?: number
}
export const LegacyMarketResearchMethod: FC<IMarketResearchMethodParams> =
  observer(({ node, graph, cardID, cardIndex }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()
    const wtsStore = useWtsStore()

    const [productParamValue, setProductParamValue] = useState(
      productParam.default
    )
    const [minCountParamValue, setMinCountParamValue] = useState(
      minCountParam.default
    )
    const [columnsParamValue, setColumnsParamValue] = useState(
      columnParam.default
    )

    const isGenerateButtonFrozen = () => false

    const [assets, setAssets] = useState<ICardContext['assets']>([])
    const assetDataCache = useRef<Record<string, string>>({})

    useEffect(() => {
      ;(async () => {
        const designMethod = wtsStore.operator.get_method_data(
          cardID
        ) as MarketAnalysisArguments
        setProductParamValue(designMethod.argument.product)
        setColumnsParamValue(designMethod.argument.features)
        await renderOutput()
      })()
    }, [])

    const renderOutput = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      const outputTableID = designMethod.outputs[0]['table']?.id // TODO: type
      const outputTableContent = await get_asset_data(outputTableID)
      console.log('generate parse', outputTableContent)
      setAssets((assets) => {
        const newAssets = [...assets, [outputTableID]]
        metaStore.registerCardOutputAsset(
          getPortId(cardID, 'out', newAssets.length - 1, 0),
          outputTableID
        )
        metaStore.cardContext[cardID].assets = newAssets
        return newAssets
      })
      assetDataCache.current[outputTableID] = outputTableContent
    }

    const generate = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      console.log('generate, method', designMethod)
      await wtsStore.operator.update_method({
        ...designMethod,
        argument: {
          ...designMethod.argument,
          product: productParamValue,
          numberLimits: [minCountParamValue, 10],
          features: columnsParamValue,
        },
      })
      console.log('generate before run method')
      let retDesignMethod: DesignMethod
      try {
        retDesignMethod = await wtsStore.operator.run_method(designMethod.id)
      } catch (e) {
        console.error('generate run method exception', e)
      }
      console.log('generate return', retDesignMethod)
      console.log(
        'generate return',
        retDesignMethod,
        retDesignMethod.outputs[0]['table']?.id
      )
      await renderOutput()
    }

    // TODO: replace 'img' with 'background'
    const input = (
      <>
        <AssetInPort
          assetType={'img'}
          title={'背景描述'}
          portInfo={{ cardId: cardID, portType: 'in', groupId: 0, subId: 0 }}
        />
      </>
    )

    const params = (
      <div {...styleClass(['paramCard'])}>
        <ParamCard
          className={styles.textInput}
          param={productParam}
          value={productParamValue}
          onChange={(v) => setProductParamValue(v)}
        />
        <ParamCard
          className={styles.sliderInput}
          param={minCountParam}
          value={minCountParamValue}
          onChange={(v) => setMinCountParamValue(v)}
        />
        <ParamCard
          className={styles.selectInput}
          param={columnParam}
          value={columnsParamValue}
          onChange={(v) => setColumnsParamValue(v)}
        />
      </div>
    )

    const output = assets.map((outputAssets, i) => (
      // <Markdown key={i} content={outputAssetContent} theme={'light'} />
      <TextAsset
        key={i}
        width={'auto'}
        text={assetDataCache.current[outputAssets[0]]}
        useMarkdown={true}
        portInfo={{
          cardId: cardID,
          portType: 'out',
          groupId: i,
          subId: 0,
        }}
        showController={true}
      />
    ))

    // TODO: use cardDetailedInfo
    return (
      <MethodCard
        width={560}
        cardTitle={'市场调研'}
        cardIcon={<SvgIcon icon={DataAnalysis} />}
        hideMore={true}
        suffixExtra={
          <>
            <div {...styleClass(['header-suffix-add'])}>
              <SvgIcon icon={Add_707070} />
            </div>
          </>
        }
        node={node}
        graph={graph}
        hideInput={false}
        input={input}
        params={params}
        output={output}
        isButtonDisabled={isGenerateButtonFrozen()}
        onClick={generate}
      ></MethodCard>
    )
  })
