import styles from './AssetInPort.module.scss'
import { CSSProperties, FC, ReactNode } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import { motionEasing } from '@/config'
import { motion } from 'framer-motion'
import { SvgIcon } from '@/components/icons'
import { Text } from '@/components/icons/Text'
import { More } from '@/components/icons/More'
import { Video } from '@/components/icons/Video'
import { IconImage } from '@/components/icons/IconImage'
import { IconClose } from '@/components/icons/IconClose'
import { IPortInfo, Port } from '@/components/graph/Port/Port'
import { IconPrompt } from '@/components/icons/IconPrompt'

interface IAssetInPortParams {
  assetType:
    | 'string'
    | 'img'
    | 'audio'
    | 'video'
    | 'txt2img_prompt'
    | 'context_string'
    | 'csv'
  title?: string
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  portInfo: IPortInfo
}

export const AssetInPort: FC<IAssetInPortParams> = ({
  assetType = 'string',
  title,
  width,
  height,
  portInfo,
}) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  const showPrefixIcon = (type: IAssetInPortParams['assetType']) => {
    switch (type) {
      case 'csv':
      case 'context_string':
      case 'string':
        return Text
      case 'img':
        return IconImage
      case 'video':
        return Video
      case 'txt2img_prompt':
        return IconPrompt
    }
  }

  const showPrefixText = (type: IAssetInPortParams['assetType']) => {
    switch (type) {
      case 'string':
        return '文本'
      case 'img':
        return '图片'
      case 'video':
        return '视频'
      case 'txt2img_prompt':
        return '文生图提示词'
      case 'context_string':
        return '上下文'
      case 'csv':
        return '表格'
    }
  }

  const showPortColor = (type: IAssetInPortParams['assetType']) => {
    switch (type) {
      case 'csv':
      case 'context_string':
      case 'string':
        return {
          borderColor: c('DeepBlue-light'),
          circleColor: c('DeepBlue-main'),
        }
      case 'img':
        return {
          borderColor: c('Yellow-light'),
          circleColor: c('Yellow-main'),
        }
      case 'video':
        return {
          borderColor: c('Red-light'),
          circleColor: c('Red-main'),
        }
      case 'txt2img_prompt':
        return {
          borderColor: c('Blue-light'),
          circleColor: c('Blue-main'),
        }
    }
  }

  return (
    <motion.div
      {...styleClass(['layout'], { width: width, height: height })}
      style={{ background: 'white' }}
      //zhj在测试的时候发现whileHover的状态没有取消，尝试过更换浏览器和线上环境仍然如此，加入这一行保险一些
      whileHover={{ background: '#F8F8F8' }}
      transition={{ ...motionEasing }}
    >
      <div {...styleClass(['prefix-layout'])}>
        <SvgIcon icon={showPrefixIcon(assetType)} />
        <div {...styleClass(['prefix-text'])}>
          {title ? title : showPrefixText(assetType)}
        </div>
      </div>
      {/* <div {...styleClass(['suffix-layout'])}>
        <SvgIcon icon={More} />
        <SvgIcon icon={IconClose} />
      </div> */}
      <div {...styleClass(['port-wrapper'])}>
        <Port {...showPortColor(assetType)} portInfo={portInfo} />
      </div>
    </motion.div>
  )
}
