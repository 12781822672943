import { observer } from 'mobx-react'
import styles from './Reviewer.module.scss'
import { useStyles } from '@/hooks/styles'
import { useWtsStore } from '@/hooks'
import { useEffect, useRef, useState } from 'react'
import { DesignDescription } from '@/store/wtsStore'
import { Asset, DesignMethod, DesignOperator } from 'wts'
import { GeneralAssetCard } from '../graph/assets/GeneralAssetCard/GeneralAssetCard'
import { ConfigProvider, Modal, Tabs, TabsProps, Typography } from 'antd'
import { IconClose } from '../icons/IconClose'
import { AssetCard } from '../graph/assets/AssetCard/AssetCard'

enum TabUrl {
  asset = 'asset',
  method = 'method',
}

const items: TabsProps['items'] = [
  { key: 'asset', label: '资产' },
  { key: 'method', label: '方法' },
]

export const ReviewerModal = (props: {
  open: boolean
  desc_asset: string
  onClose: () => void
}) => {
  const styleClass = useStyles(styles)
  const wtsStore = useWtsStore()
  // const [tabType, setTabType] = useState<TabUrl>(TabUrl.asset)
  // const [assets, setAssets] = useState<{ asset: Asset; sid: string }[]>([])
  // const operator = useRef<DesignOperator | null>(null)
  // const design = useRef<DesignDescription | null>(null)
  // const [descMsg, setDescMsg] = useState<string[]>([])
  // const [methods, setMethods] = useState<
  //   { method: DesignMethod; sid: string }[]
  // >([])
  // useEffect(() => {
  //   ;(async () => {
  //     operator.current = await wtsStore.app.load_design_by_desc(
  //       props.desc_asset
  //     )
  //     design.current = await wtsStore.app.get_asset_data(props.desc_asset)
  //     const description =
  //       await operator.current.planner.designAwareness.generate_readable_description()
  //     setDescMsg(description)
  //     const asset_list = Object.values(design.current.assets).map((x) => ({
  //       asset: x,
  //       sid: operator.current.planner.get_short_id(x.id, 'asset'),
  //     }))
  //     const method_list = Object.values(design.current.methods).map((x) => ({
  //       method: x,
  //       sid: operator.current.planner.get_short_id(x.id, 'method'),
  //     }))
  //     setAssets(asset_list)
  //     setMethods(method_list)
  //   })()
  // }, [props.desc_asset, wtsStore.app])

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            lineHeight: 32,
          },
        },
      }}
    >
      <Modal
        width={1080}
        {...styleClass(['modal'])}
        title={<></>}
        open={props.open}
        footer={null}
        onOk={props.onClose}
        onCancel={props.onClose}
        closeIcon={<IconClose />}
      >
        <div {...styleClass(['modal-title'])}>{'设计状态回溯'}</div>
        <iframe title="reviewer" {...styleClass(['modal-iframe'])} src={`/full-graph/${wtsStore.operator.id}?type=snap&desc=${props.desc_asset}`} />
        {/* {false && (
          <div {...styleClass(['modal-total'])}>
            <div {...styleClass(['modal-left'])}>
              <div {...styleClass(['modal-leftTitle'])}>{'总体描述'}</div>
              <div {...styleClass(['modal-left-container'])}>
                {descMsg.map((x) => (
                  <div key={x} {...styleClass(['modal-leftItem'])}>
                    <div {...styleClass(['modal-leftDot'])}></div>
                    <p {...styleClass(['modal-leftDetail'])}>{x}</p>
                  </div>
                ))}
              </div>
            </div>
            <div {...styleClass(['modal-right'])}>
              <Tabs
                onChange={(v) => {
                  setTabType(v as TabUrl)
                }}
                activeKey={tabType}
                items={items}
              />
              {tabType === TabUrl.asset && (
                <div {...styleClass(['modal-right-container'])}>
                  {assets.map((x) => (
                    <div key={x.sid} {...styleClass(['modal-rightAsset'])}>
                      <div key={x.sid} {...styleClass(['modal-rightTitle'])}>
                        <Typography.Text
                          {...styleClass(['modal-rightId'])}
                        >{`id : ${x.sid}`}</Typography.Text>
                      </div>
                      <GeneralAssetCard
                        asset={x.asset}
                        args={{ width: '450px' }}
                      />
                    </div>
                  ))}
                </div>
              )}
              {tabType === TabUrl.method && (
                <div {...styleClass(['modal-right-container'])}>
                  {methods.map((x) => (
                    <div key={x.sid} {...styleClass(['modal-rightMethod'])}>
                      <AssetCard
                        headerColor={'Green-background'}
                        cardTitle={
                          <Typography.Text
                            {...styleClass(['modal-rightId'])}
                          >{`id : ${x.sid}`}</Typography.Text>
                        }
                        cardIcon={null}
                        node={null}
                        graph={null}
                        showController={false}
                        style={{
                          width: 450,
                          paddingLeft: 20,
                          paddingBottom: 20,
                          flexWrap: 'wrap',
                        }}
                      >
                        <FlexTable
                          dataSource={x.method.argument}
                          styleClass={styleClass}
                        />
                      </AssetCard>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )} */}
      </Modal>
    </ConfigProvider>
  )
}

export const FlexTable = ({ dataSource, styleClass }) => {
  return (
    <div {...styleClass(['modal-flexContainer'])} key={dataSource}>
      <div {...styleClass(['modal-flexHead'])}>
        参数名<div style={{ paddingLeft: 5 }}>参数值</div>
      </div>
      {Object.entries(dataSource).map(([key, value]) => (
        <div key={key} {...styleClass(['modal-flexContent'])}>
          {key}
          <div style={{ paddingLeft: 5 }}>
            {typeof value === 'object' ? JSON.stringify(value) : value}
          </div>
        </div>
      ))}
    </div>
  )
}
