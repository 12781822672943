import { FC } from "react";
import { IDesignGoalSummarizeMethodParams } from "./DesignGoalSummarizeMethod/DesignGoalSummarizeMethod";
import { GeneralMethod } from "./GeneralMethod";

export const Image2ModelMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'Image2ModelMethod',
    params: [],
    inputs: [{ title: '图片', type: 'img' }],
    outputParser: (output) => [output?.model?.id],
    styles: {},
  });
