import { useStyles } from "@/hooks/styles";
import { FC, useEffect, useState } from "react"
import styles from './AssetsCard.module.scss'
import { get_asset_data, get_asset_url } from "wts";
import { Col, Descriptions, Divider, Image, Modal, Row, Typography } from "antd";
import { Card } from "@/components/universal/Card/Card";
import { Markdown } from "@/components/Planner/Markdown/Markdown";
import ShowModel from "@/components/ShowModel";


interface IAssetsCard {
  id: string
  item: any
}

export const AssetsCard: FC<IAssetsCard> = ({ id, item }) => {
  const styleClass = useStyles(styles)

  const [data, setData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // 获取数据的逻辑，待定
  //   useEffect(() => {
  //     (async () => {
  //       const asset = await get_asset_data(id)
  //       setData(asset)
  //     })();
  //   }, [id])

  useEffect(() => {
    setData(item)
  }, [data])


  return (data &&
    <>
      <Card {...styleClass(['card-wrapper'])} onClick={() => setIsModalOpen(true)}>
        {findImageData(data) &&
          <>
            <div {...styleClass(['card-img-wrapper'])}>
              {findImageData(data)?.content?.[0]?.startsWith('http') ?
                (<Image width={'100%'} height={126} src={findImageData(data)?.content?.[0] ?? get_asset_url(item ?? "86f60816-07c9-4942-b2eb-8d066da6a025")} preview={false} style={{ objectFit: 'cover', objectPosition: 'center' }}></Image>) :
                (<Image width={'100%'} height={126} src={get_asset_url(findImageData(data)?.content?.[0] ?? "86f60816-07c9-4942-b2eb-8d066da6a025")} preview={false} style={{ objectFit: 'cover', objectPosition: 'center' }}></Image>)
              }
            </div>
            <div {...styleClass(['card-img-info-wrapper'])}>
              <div {...styleClass(['card-img-info-title-wrapper'])}>
                <Typography.Paragraph {...styleClass(['card-img-info-title-text'])} ellipsis={{ rows: 2 }}>
                  {data.title.content}
                </Typography.Paragraph>
              </div>
            </div>
          </>
        }
        {/* {data.img &&
          <>
            <div {...styleClass(['card-img-wrapper'])}>
              <Image width={'100%'} height={126} src={get_asset_url(data.img.content[0])} preview={false}></Image>
            </div>
            <div {...styleClass(['card-img-info-wrapper'])}>
              <div {...styleClass(['card-img-info-title-wrapper'])}>
                <Typography.Paragraph {...styleClass(['card-img-info-title-text'])} ellipsis={{ rows: 2 }}>
                  {data.title.content}
                </Typography.Paragraph>
              </div>
            </div>
          </>
        }
        {data.image &&
          <>
            <div {...styleClass(['card-img-wrapper'])}>
              <Image width={'100%'} height={126} src={get_asset_url(data.image.content[0])} preview={false}></Image>
            </div>
            <div {...styleClass(['card-img-info-wrapper'])}>
              <div {...styleClass(['card-img-info-title-wrapper'])}>
                <Typography.Paragraph {...styleClass(['card-img-info-title-text'])} ellipsis={{ rows: 2 }}>
                  {data.title.content}
                </Typography.Paragraph>
              </div>
            </div>
          </>
        } */}
        {!findImageData(data) &&
          (<div {...styleClass(['card-info-wrapper'])}>
            <div {...styleClass(['card-info-title-wrapper'])}>
              <Typography.Paragraph {...styleClass(['card-info-title-text'])} ellipsis={{ rows: 2 }}>
                {data.title.content}
              </Typography.Paragraph>
            </div>

            {data.HEX && data.RGB && data.CMYK && data.color &&
              <div {...styleClass(['card-item-wrapper'])}>
                <div {...styleClass(['card-item'])}>
                  <span {...styleClass(['card-item-text'])}>HEX: </span>
                  <span {...styleClass(['card-item-text'])}>{data.HEX.content}</span>
                </div>
                <div {...styleClass(['card-item'])}>
                  <span {...styleClass(['card-item-text'])}>RGB: </span>
                  <span {...styleClass(['card-item-text'])}>{data.RGB.content}</span>
                </div>
                <div {...styleClass(['card-item'])}>
                  <span {...styleClass(['card-item-text'])}>CMYK: </span>
                  <span {...styleClass(['card-item-text'])}>{data.CMYK.content}</span>
                </div>
                <div {...styleClass([], {
                  width: '100%',
                  height: '20px',
                  marginTop: '16px',
                  backgroundColor: data.color.content
                })} />
              </div>}

            {data.categories &&
              <ItemInfo data={data.categories.content} />
            }

            {data.tag &&
              <ItemInfo data={data.tag.content} />
            }
            {data.category &&
              <ItemInfo data={data.category.content} />
            }
            {data.publication_number &&
              <ItemInfo data={data.publication_number.content} />
            }
            {data.source &&
              <ItemInfo data={data.source.content} />
            }
          </div>
          )}
      </Card>
      <Modal
        title={data.title.content}
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        {...styleClass(['modal-wrapper'])}
        width={930}
      >
        <Divider />
        <DisplayCard data={data} />
      </Modal>
    </>
  )
}

interface Info {
  type: string;
  name: string;
  content: any;
}

interface Data {
  ID: Info;
  title: Info;
  description?: Info;
  img?: Info;
  part_details?: { name: string; content: Record<string, string> };
  [key: string]: any; // for additional information
}

const DisplayCard: React.FC<{ data: Data }> = ({ data }) => {
  const styleClass = useStyles(styles)
  const { Title } = Typography


  const { ID, title, description, img, image, part_details, basic_info, design_detail, ...moreInfo } = data

  // basic_info单独处理
  const basicInfoContent = basic_info?.content || {};
  const { img: basic_info_img, ...basic_info_filter } = basicInfoContent;

  // design_detail单独处理,筛选出“产品卖点”
  const designDetailContent = design_detail?.content || {};
  const sellingPoints = designDetailContent["产品卖点"];
  if (sellingPoints) {
    delete designDetailContent["产品卖点"];
  }


  // 根据 type 分离数据
  const textInfo = Object.entries(moreInfo).filter(([key, value]) => (value as Info).type === 'text');
  const jsonInfo = Object.entries(moreInfo).filter(([key, value]) => (value as Info).type === 'JSON');
  const markdownInfo = Object.entries(moreInfo).filter(([key, value]) => (value as Info).type === 'markdown');
  // const labelInfo = Object.entries(moreInfo).filter(([key, value]) => (value as Info).type === 'label');
  const urlInfo = Object.entries(moreInfo).filter(([key, value]) => (value as Info).type === 'url');
  const colorInfo = Object.entries(moreInfo).filter(([key, value]) => (value as Info).type === 'color');
  const modelInfo = Object.entries(moreInfo).filter(([key, value]) => (value as Info).type === 'model');

  // 其他信息，排除以上所有类型
  const otherInfo = Object.entries(moreInfo).filter(([key, value]) =>
    !['text', 'markdown', 'url', 'JSON', 'color', 'model'].includes((value as Info).type)
  );


  const renderPartDetails = (partDetails: Record<string, string>) => {

    return Object.entries(partDetails).map(([key, value]) => (
      <Col span={12} key={key}>
        <Descriptions column={2} layout="vertical" colon={false}>
          <Descriptions.Item label={key} {...styleClass(['align-justify'])}>
            {value}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    ));
  };

  return (
    <div {...styleClass(['displayCard-wrapper'])}>
      {findImageData(data) && (
        <div {...styleClass(['displayCard-img-wrapper'])}>
          {findImageData(data)?.content[0]?.startsWith('http') ? (
            findImageData(data).content.map((item, index) => (
              <Image
                src={item}
                preview={false}
                key={index}
                style={{ marginBottom: '5px', width: '100%' }}
              />
            ))
          ) : (
            findImageData(data).content.map((item, index) => (
              <Image
                src={get_asset_url(item ?? '86f60816-07c9-4942-b2eb-8d066da6a025')}
                preview={false}
                key={index}
                style={{ marginBottom: '5px', width: '100%' }}
              />
            ))
          )}
        </div>
      )}
      {/* {basic_info_img &&
        <div {...styleClass(['displayCard-img-wrapper'])}>
          {
            (basic_info_img).map((item, index) => {
              return (
                <Image src={get_asset_url(item)} preview={false} key={index} style={{ marginBottom: '5px', width: '100%' }}></Image>
              );
            })
          }
        </div>
      } */}


      <div {...styleClass(['displayCard-content'])}>
        <Descriptions layout="horizontal" column={1} colon={false} labelStyle={{ width: '150px' }}>
          <Descriptions.Item label="ID">{ID.content}</Descriptions.Item>
          <Descriptions.Item label={title.name}>{title.content}</Descriptions.Item>
          {description &&
            <Descriptions.Item label={description.name} span={3}>
              {description.content}
            </Descriptions.Item>
          }
        </Descriptions>
        {basic_info &&
          <>
            <Title level={4} style={{ marginTop: '20px' }}>{basic_info.name}</Title>
            <Row gutter={16}>
              {renderPartDetails(basic_info_filter)}
            </Row>
          </>
        }
        <Title level={4} style={{ marginTop: '20px' }}>更多信息</Title>
        <Row gutter={[16, 16]}>
          {otherInfo.map(([key, value]) => {
            const info = value as Info;
            return (
              <Col span={12} key={key}>
                <Descriptions layout="vertical" colon={false}>
                  <Descriptions.Item label={info.name} {...styleClass(['align-justify'])}>{Array.isArray(info.content) ? info.content.join(', ') : info.content}</Descriptions.Item>
                </Descriptions>
              </Col>
            );
          })}
          {textInfo.map(([key, value]) => {
            const info = value as Info;
            return (
              <Col span={24} key={key}>
                <Descriptions layout="vertical" colon={false}>
                  <Descriptions.Item label={info.name} {...styleClass(['align-justify'])}>{Array.isArray(info.content) ? info.content.join(', ') : info.content}</Descriptions.Item>
                </Descriptions>
              </Col>
            );
          })}
          {urlInfo.map(([key, value]) => {
            const info = value as Info;
            return (
              <Col span={24} key={key}>
                <Descriptions layout="vertical" colon={false} >
                  <Descriptions.Item label={info.name} {...styleClass(['align-justify'])}>{Array.isArray(info.content) ? info.content.join(', ') : info.content}</Descriptions.Item>
                </Descriptions>
              </Col>
            );
          })}
          {colorInfo.map(([key, value]) => {
            const info = value as Info;
            return (
              <Col span={24} key={key}>
                <Descriptions layout="vertical" colon={false} >
                  <Descriptions.Item label={info.name} {...styleClass(['align-justify'])}>
                    <div {...styleClass([], { backgroundColor: info.content, width: '380px', height: '20px' })}></div>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            );
          })}
          {modelInfo.map(([key, value]) => {
            const info = value as Info;
            return (
              <Col span={24} key={key}>
                <Descriptions layout="vertical" colon={false} >
                  <Descriptions.Item label={info.name} {...styleClass(['align-justify'])}>
                    <ShowModel 
                      style={{ width: "400px", height: "400px" }} 
                      url={get_asset_url(info.content)} 
                      // mtlUrl = {mtlUrl} 
                      type='obj' 
                      showMenu={true} 
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            );
          })}
          {jsonInfo.map(([key, value]) => {
            const info = value as Info;
            const jsonString = JSON.stringify(info.content, null, 2); // 将 JSON 对象转换为字符串
            return (
              <Col span={24} key={key}>
                <Descriptions layout="vertical" colon={false}>
                  <Descriptions.Item label={info.name} {...styleClass(['align-justify'])}>
                    <Markdown content={"```json\n" + jsonString + "\n```"} theme={'light'} />
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            );
          })}
          {part_details && (
            <Col span={24} key="part_details">
              <Title level={5} style={{ marginTop: '20px' }}>{part_details.name}</Title>
              <Row gutter={16}>
                {Object.entries(part_details.content).map(([key, value]) => (
                  <Col span={6} key={key}>
                    <Descriptions column={2}>
                      <Descriptions.Item label={key} {...styleClass(['align-justify'])}>
                        {value}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                ))}
              </Row>
            </Col>

          )}
          {design_detail && (
            <Col span={24} key="part_details">
              <Title level={5} style={{ marginTop: '20px' }}>{design_detail.name}</Title>
              <Row gutter={16}>
                {Object.entries(design_detail.content).map(([key, value]) => (
                  <Col span={12} key={key}>
                    <Descriptions column={2} layout="vertical" colon={false}>
                      <Descriptions.Item label={key} {...styleClass(['align-justify'])}>
                        {value}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                ))}
                <Col span={24} key={"产品卖点"}>
                  <Descriptions column={2} layout="vertical" colon={false}>
                    <Descriptions.Item label={"产品卖点"} {...styleClass(['align-justify'])}>
                      {sellingPoints}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Col>

          )}
          {markdownInfo.map(([key, value]) => {
            const info = value as Info;
            return (
              <Col span={24} key={key}>
                <Descriptions layout="vertical" colon={false}>
                  <Descriptions.Item label={info.name} {...styleClass(['align-justify'])}>
                    <Markdown content={info.content as string} theme={'light'}></Markdown>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

interface Data {
  data: string | string[];
}

const ItemInfo: React.FC<{ data: Data }> = ({ data }) => {
  const styleClass = useStyles(styles);
  const tagContent = Array.isArray(data)
    ? data.join(', ')
    : data;

  return (
    <div {...styleClass(['card-item-wrapper'])}>
      <div {...styleClass(['card-item'])}>
        <span {...styleClass(['card-item-text'])}>{tagContent}</span>
      </div>
    </div>
  );
};

// 查找image/img属性并返回图片展示组件
const findImageData = (data) => {
  if (!data) return null

  if (data.img || data.image) {
    return data.img || data.image
  }
  return null;
};