import { FC } from 'react'
import styles from './VideoAsset.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { SvgIcon } from '@/components/icons'
import { AssetCard } from '@/components/graph/assets/AssetCard/AssetCard'
import { IPortInfo, Port } from '@/components/graph/Port/Port'
import { Video } from '@/components/icons/Video'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'

import { Player } from 'video-react'
import { useState } from 'react'
import 'video-react/dist/video-react.css' // 这是video-react专用的css
interface IVideoAsset {
  videoUrl?: string
  node?: Node
  graph?: Graph
  portInfo: IPortInfo
  showController?: boolean
  title?: string
}

// 传入视频地址，获取视频第一帧作为封面

// 视频播放组建，创建时接受一个videoUrl作为视频地址
export const VideoAsset: FC<IVideoAsset> = observer(
  ({ videoUrl, node, graph, portInfo, showController, title }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)

    const [posterUrl, setPosterUrl] = useState('')

    return (
      <AssetCard
        headerColor={c('Red-background')}
        cardTitle={'视频' + (title ? " - " + title : '')}
        cardIcon={<SvgIcon icon={Video} />}
        node={node}
        graph={graph}
        showController={showController}
      >
        <div {...styleClass(['layout', 'card-content-center'])}>
          <div>
            {/* startTime属性指定视频从第几秒开始，用来解决视频一开始可能黑屏的问题 startTime=5 */}
            {/* 将preload属性设置为metadata会告诉浏览器仅预加载视频的元数据，而不会加载整个视频文件。 */}
            <Player
              // playsInline
              src={videoUrl}
              poster={posterUrl}
              preload={'metadata'}
            />
          </div>

          {showController && <div {...styleClass(['port-wrapper'])}>
            <Port circleColor={c('Red-main')} borderColor={c('Red-light')} portInfo={portInfo} />
          </div>}
        </div>
      </AssetCard>
    )
  }
)
