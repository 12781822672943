import { TextAsset } from '@/components/graph/assets/TextAsset/TextAsset'
import { MethodCard } from '@/components/graph/methods/MethodCard/MethodCard'
import {
  IParam,
  ParamCard,
  styleKeyMap,
} from '@/components/graph/methods/utils'
import { SvgIcon } from '@/components/icons'
import { Add_707070 } from '@/components/icons/Add_707070'
import { DataAnalysis } from '@/components/icons/DataAnalysis'
import { useMetaStore, useWtsStore } from '@/hooks'
import { useStyles } from '@/hooks/styles'
import {
  ICardContext,
  SupportMethodCardType,
  UUID,
  cardType2DetailedInfo,
} from '@/store/metaStore'
import { observer } from 'mobx-react'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Asset,
  DesignMethod,
  get_asset_data,
  get_asset_url,
  MarketAnalysisArguments,
  traverse_method_output,
} from 'wts'
import { AssetInPort } from '../assets/AssetInPort/AssetInPort'
import styles from './MarketResearchMethod/MarketResearchMethod.module.scss' // TODO:
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { getPortId, parsePort } from '@/components/MainGraph/utils'
import { identity } from 'lodash'
import { ImageAsset } from '../assets/ImageAsset/ImageAsset'
import { message } from 'antd'
import { GeneralAssetCard } from '../assets/GeneralAssetCard/GeneralAssetCard'
import { useChatStore } from '@/hooks'

type InputDescriptor = {
  title: string
  type:
    | 'string'
    | 'img'
    | 'audio'
    | 'video'
    | 'txt2img_prompt'
    | 'context_string'
    | 'csv' // TODO: use type
}

export type MethodDescriptor = {
  cardType: SupportMethodCardType
  params: IParam[]
  inputs: InputDescriptor[]
  outputParser: (output: any) => UUID[] // TODO: figure out return type
  isGenerateButtonFrozen?: (
    cardID: UUID,
    inPorts: Record<string, UUID[]>
  ) => boolean
  styles: any // TODO
  needFold?: boolean
}

export const GeneralMethod: (
  descriptor: MethodDescriptor | ((id: string) => MethodDescriptor)
) => FC<IDesignGoalSummarizeMethodParams> = (
  rawDescriptor: MethodDescriptor | ((id: string) => MethodDescriptor)
) =>
  observer(({ node, graph, cardID, cardIndex }) => {
    const styleClass = useStyles(styles)
    const wtsStore = useWtsStore()
    const metaStore = useMetaStore()
    const chatStore = useChatStore()

    const descriptor =
      typeof rawDescriptor === 'function'
        ? rawDescriptor(cardID)
        : rawDescriptor

    const [paramValues, setParamValues] = useState(
      descriptor.params.map((param) => param.default)
    )
    const [inPorts, setInPorts] = useState<Record<string, UUID[]>>({})
    const title = useMemo(() => {
      const methodType = cardType2DetailedInfo(descriptor.cardType).title
      const methodName = wtsStore.operator.get_method_data(cardID).name
      const methodSid = wtsStore.operator.planner.get_short_id(cardID, 'method')
      wtsStore.notifyUpdated()
      return `${methodType} - ${methodName} - ${methodSid}`
    }, [cardID])

    // useEffect(() => {}, [metaStore.cardContext])
    // runs only once
    useEffect(() => {
      const inPorts = metaStore.cardContext[cardID]?.inPorts ?? {}
      // console.log('generalmethod,useeffect', inPorts)
      setInPorts(inPorts)
    }, [metaStore.cardContext[cardID]])

    const isGenerateButtonFrozen = () =>
      (descriptor?.isGenerateButtonFrozen ?? (() => false))(cardID, inPorts)

    const [isRunning, setIsRunning] = useState(false)
    const [assets, setAssets] = useState<Asset[][]>([])

    useEffect(() => {
      ;(async () => {
        const designMethod = wtsStore.operator.get_method_data(cardID)
        setParamValues(
          descriptor.params.map((param) => {
            if (!param.id) {
              return param.default
            }
            if (typeof param.id === 'function') {
              return param.id(designMethod)
            }
            return designMethod.argument[param.id] ?? param.default
          })
        )
        await renderAllOutputs()
      })()
    }, [])

    const renderAllOutputs = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      // console.log('renderOutput', designMethod, metaStore.cardContext[cardID].assets)
      let newAssets = []
      for (const output of designMethod.outputs) {
        const outputAssets = await Promise.all(
          descriptor
            .outputParser(output)
            .map(async (id) => await wtsStore.app.get_asset_meta(id))
        )
        newAssets.unshift(outputAssets)
      }
      for (let i = 0; i < newAssets.length; i++) {
        for (let j = 0; j < newAssets[i].length; j++) {
          metaStore.registerCardOutputAsset(
            getPortId(cardID, 'out', i, j),
            newAssets[i][j].id
          )
        }
      }
      setAssets(newAssets)
    }

    const renderOutput = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      console.log('renderOutput', designMethod)
      const outputAssetIds = descriptor.outputParser(designMethod.outputs[0])
      const outputAssets = await Promise.all(
        outputAssetIds.map(async (id) => await wtsStore.app.get_asset_meta(id))
      )
      setAssets((assets) => {
        const newAssets = [...assets, outputAssets]
        for (let i = 0; i < outputAssets.length; i++) {
          metaStore.registerCardOutputAsset(
            getPortId(cardID, 'out', newAssets.length - 1, i),
            outputAssets[i].id
          )
        }
        metaStore.cardContext[cardID].assets = newAssets.map((x) =>
          x.map((asset) => asset.id)
        )
        return newAssets
      })
    }

    const generate = async () => {
      setIsRunning(true)
      try {
        const designMethod = wtsStore.operator.get_method_data(cardID)
        console.log('generate, method', designMethod)
        const shrot_id = wtsStore.operator?.planner.get_short_id(designMethod.id, 'method') ? '-' + wtsStore.operator?.planner.get_short_id(designMethod.id, 'method') : ''
        chatStore.addMessage([
          {
            "type": "run_method_user",
            "data": designMethod.name + shrot_id
          },
        ] as any, 'system2')
        const params = { ...designMethod.argument }
        descriptor.params.forEach((param, index) => {
          if (typeof param.id === 'function' || !param.id) {
            param.valueMapper?.(params, paramValues[index])
          } else if (typeof param.id === 'string') {
            if (param.valueMapper) {
              params[param.id] = param.valueMapper(params, paramValues[index])
            } else {
              params[param.id] = paramValues[index]
            }
          }
        })
        console.log('generate, params', params)
        console.log(descriptor.inputs)
        for (let i = 0; i < descriptor.inputs.length; i++) {
          const inPortId = getPortId(cardID, 'in', i, 0)
          const groupAssets = []
          for (const outPort of metaStore.cardContext[cardID].inPorts[
            inPortId
          ] ?? []) {
            const portInfo = parsePort(outPort)
            if (!(portInfo.cardId in metaStore.editingAssetsMap)) {
              if (
                metaStore.cardContext[portInfo.cardId]?.assets?.[
                  portInfo.groupId
                ]
              ) {
                const asset =
                  metaStore.cardContext[portInfo.cardId].assets[
                    portInfo.groupId
                  ][portInfo.subId]
                groupAssets.push(asset)
              }
            } else {
              // 没有资产的情况下自动注册资产
              // 1. 拿到未注册资产的信息
              const assetValue = metaStore.editingAssetsMap[portInfo.cardId]
              const assetId = await assetValue.register()
              delete metaStore.editingAssetsMap[portInfo.cardId]
              // 3. 将注册后的资产加入groupAssets
              if (assetId) {
                groupAssets.push(assetId)
              }
            }
          }
          designMethod.inPorts[i].assets = groupAssets
        }
        await wtsStore.operator.update_method({
          ...designMethod,
          argument: {
            ...designMethod.argument,
            ...params,
          },
        })
        console.log('generate before run method')
        let retDesignMethod: DesignMethod
        try {
          retDesignMethod = await wtsStore.operator.run_method(designMethod.id)
        } catch (e) {
          console.error('generate run method exception', e)
        }
        console.log('generate return', retDesignMethod)
        await renderOutput()

        // 必须在输出结束后进行保存
        // 首先保存设计，然后将资产绑定到该设计快照，用于实现资产回溯
        await metaStore.save(wtsStore)
        console.log(
          'method data',
          JSON.parse(JSON.stringify(wtsStore.operator.design))
        )
        await traverse_method_output(retDesignMethod.outputs[0], (asset) => {
          return wtsStore.operator.bind_asset_with_current_design(asset.id)
        })
      } catch (e) {
        console.error('[GeneralMethod::generate]', e)
        message.error('生成失败')
      } finally {
        setIsRunning(false)
      }
    }

    const input = (
      <>
        {descriptor.inputs.map((inputDescriptor, i) => (
          <AssetInPort
            key={inputDescriptor.title}
            assetType={inputDescriptor.type}
            title={inputDescriptor.title}
            portInfo={{ cardId: cardID, portType: 'in', groupId: i, subId: 0 }}
          />
        ))}
      </>
    )

    // TODO: make it above input
    const params = descriptor.params.length > 0 && (
      <div {...styleClass(['paramCard'])}>
        {descriptor.params.map((param, i) => (
          <ParamCard
            key={`${param.title}-${i}`}
            className={styles[styleKeyMap(param.type)]}
            param={param}
            value={paramValues[i]}
            onChange={(v) => {
              // const to = [...paramValues.slice(0, i), v, ...paramValues.slice(i + 1)]
              // console.log('generalmethod, change', to)
              setParamValues((paramValues) => [
                ...paramValues.slice(0, i),
                v,
                ...paramValues.slice(i + 1),
              ])
            }}
          />
        ))}
      </div>
    )

    // TODO: 支持复杂结构输出（比如多个图片在一个框里面）
    const output = assets.map((outputs, i) =>
      outputs.map((asset, j) => (
        <GeneralAssetCard
          key={asset.id}
          asset={asset}
          args={{
            width: '100%',
            portInfo: {
              cardId: cardID,
              portType: 'out',
              groupId: i,
              subId: j,
            },
            node: node,
            showController: true,
            isTextEditable: true,
          }}
        />
      ))
    )

    return (
      <MethodCard
        width={560}
        cardTitle={title}
        cardIcon={<SvgIcon icon={DataAnalysis} />}
        hideMore={true}
        suffixExtra={
          <>
            <div {...styleClass(['header-suffix-add'])}>
              <SvgIcon icon={Add_707070} />
            </div>
          </>
        }
        node={node}
        graph={graph}
        hideInput={false}
        input={input}
        params={params}
        output={output}
        isButtonDisabled={isGenerateButtonFrozen()}
        onClick={generate}
        needFold={descriptor?.needFold || false}
        {...styleClass(isRunning ? ['running'] : [])}
      />
    )
  })
