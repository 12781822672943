import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'
import { IContinuousParam, IDiscreteParam, IInputParam } from './utils'
import { getPortId } from '@/components/MainGraph/utils'

export const EnvironmentAnalysisMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'EnvironmentAnalysisMethod',
    params: [],
    inputs: [{ title: '产品信息', type: 'string' }],
    outputParser: (output) => [output?.competing_products?.id, output?.environment_analysis?.id],
    styles: {},
  })

const outputNumParam: IContinuousParam = {
  id: 'output_num',
  type: 'slider',
  title: '生成数量',
  default: 3,
  min: 3,
  max: 5,
  step: 1,
}

export const ProductFunctionMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'ProductFunctionMethod',
    params: [outputNumParam],
    inputs: [{ title: '竞品分析', type: 'string' }, { title: '环境分析', type: 'string' }],
    outputParser: (output) => [...(output.main_improvement ?? []).map(({ id }) => id), ...(output.secondary_improvement ?? []).map(({ id }) => id)],
    styles: {},
  })

export const UserRequirementMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'UserRequirementMethod',
    params: [outputNumParam],
    inputs: [{ title: '竞品分析', type: 'string' }, { title: '环境分析', type: 'string' }],
    outputParser: (output) => (output.user_requirement ?? []).map(({ id }) => id),
    styles: {},
  })

const outputNumParam2: IContinuousParam = {
  id: 'output_num',
  type: 'slider',
  title: '生成数量',
  default: 2,
  min: 1,
  max: 3,
  step: 1,
}

export const FunctionRefinementMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'FunctionRefinementMethod',
    params: [outputNumParam2],
    inputs: [{ title: '功能改进方向', type: 'string' }, { title: '竞品分析', type: 'string' }],
    outputParser: (output) => (output.function_refinement ?? []).map(({ id }) => id),
    styles: {},
  })

export const DesignPlanMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'DesignPlanMethod',
    params: [],
    inputs: [
      { title: '主要功能改进方向', type: 'string' },
      { title: '次要功能改进方向', type: 'string' },
      { title: '用户需求场景', type: 'string' },
    ],
    outputParser: (output) => [output.product_description.id, output.detail_description.id],
    styles: {},
  })

export const ProductIterationMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'ProductIterationMethod',
    params: [],
    inputs: [
      { title: '产品描述文本', type: 'string' },
    ],
    outputParser: (output) => [output.product_description.id, output.improvement_plan.id],
    styles: {},
  })


