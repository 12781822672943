import { CSSProperties, FC } from 'react'
import { Graph, Node } from '@antv/x6'
import styles from './ModelAsset.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { AssetCard } from '@/components/graph/assets/AssetCard/AssetCard'
import { SvgIcon } from '@/components/icons'
import { IconModel } from '@/components/icons/IconModel'
import { IPortInfo, Port } from '@/components/graph/Port/Port'
import ShowModel from '@/components/ShowModel'
import { useGraphStore } from '@/hooks'
interface IModelAsset {
  width?: CSSProperties['width']
  type: string
  url:string
  mtlUrl?:string
  turnToAsset?: () => void
  node?: Node
  graph?: Graph
  portInfo: IPortInfo
  showController?: boolean
  showMenu?: boolean
  title?: string
}

export const ModelAsset: FC<IModelAsset> = ({
  width,
  type,
  url,
  mtlUrl,
  turnToAsset,
  node,
  graph,
  portInfo,
  showController,
  showMenu,
  title,
}) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  return (
    <AssetCard
      width={width}
      headerColor={c('Blue-background')}
      cardTitle={'模型' + (title ? " - " + title : '')}
      cardIcon={<SvgIcon icon={IconModel} />}
      // turnToAsset={() => turnToAsset && turnToAsset()}
      node={node}
      graph={graph}
      portInfo={portInfo}
      showController={showController}
    >
      <div {...styleClass(['layout'])}>
        <div >

        {/* <ShowModel style={{ width: "400PX", height: "400px" }} url={modelPath} mtlurl={mtlPath} /> */}
        <ShowModel 
          style={{ width: width, height: "400px" }} 
          url={url} 
          mtlUrl = {mtlUrl} 
          type={type} 
          showMenu={showMenu} 
        />
        </div>
        {showController && <div {...styleClass(['port-wrapper'])}>
          <Port circleColor={c('Blue-main')} borderColor={c('Blue-light')} portInfo={portInfo} />
        </div>}
      </div>
    </AssetCard>
  )
}
