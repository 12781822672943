import styles from './refinityMenu.module.scss'
import { RefinityCard } from '@/components/RefinityCard/RefinityCard'

// 如果调用了静态图片，请调用：
// import examplePic from './Image/example.png'
import CreativeInspirationDrawingBoardPic from './Image/CreativeInspirationDrawingBoard.png'
import MagicCamera from './Image/MagicCamera.png'
import MagicPaint from './Image/MagicPaint.png'
import ProblemSolution from './Image/ProblemSolution.png'

// 编写前端并暴露页面接口
const RefinityMenu = () => {
  return (
    <div className={styles['page']}>
      <div className={styles['title']}>交互式创意工具</div>
      <div className={styles['subTitle']}>灵感激发</div>
      <div className={styles['cardArrayContainer']}>
        {/* <RefinityCard title='RefinityTestPage' annotation='后端接口演示' tags={["debug"]} imgPath={examplePic} href = '/refinity/TestPage'/> */}
        {/* <RefinityCard title='概念设计演化网络' annotation='在“问题-方案”演化网络中提供多模态创意刺激' tags={["键鼠"]} imgPath={examplePic} href = 'https://www.baidu.com'/> */}
        <RefinityCard
          title="实时多模态创意刺激生成工具"
          annotation="在绘画中提供多模态的创意刺激"
          tags={['手绘', '键鼠', '文本', '语音']}
          imgPath={CreativeInspirationDrawingBoardPic}
          href="CreativeInspirationDrawingBoard"
          video="https://conf.idlight.cn/videos/drawing_board.mp4"
        />
        <RefinityCard
          title="摄像头实时捕捉创意工具"
          annotation="探索实时风格化的镜头画面"
          tags={['视频', '键鼠', '文本']}
          imgPath={MagicCamera}
          href="MagicCamera"
          video="https://conf.idlight.cn/videos/magic_camera.mp4"
        />
        <RefinityCard
          title="实时草图生图像工具"
          annotation="探索实时风格化的涂鸦创作"
          tags={['手绘', '键鼠', '文本']}
          imgPath={MagicPaint}
          href="MagicPaint"
        />
        <RefinityCard
          title="问题-方案共演化辅助系统"
          annotation="基于问题-方案共演化网络的人机协同概念设计系统"
          tags={['键鼠']}
          imgPath={ProblemSolution}
          href="ProblemSolution"
          video="https://conf.idlight.cn/videos/problem_solution.mp4"
        />
      </div>

      {/* <div className={styles['subTitle']}>
                创意捕捉
            </div>
            <div className={styles['cardArrayContainer']}>
                <RefinityCard title='创意状态捕捉' annotation='捕捉和识别设计师当前的创意状态' tags={["动作", "环境"]} imgPath={examplePic} href = 'www.baidu.com'/>
            </div>

            <div className={styles['subTitle']}>
                快速原型
            </div>
            <div className={styles['cardArrayContainer']}>
                <RefinityCard title='智能CAD与仿真测试' annotation='集成零件库，与AI协作的工业设计CAD' tags={["键鼠", "3D打印"]} imgPath={examplePic} href = 'www.baidu.com'/>
                <RefinityCard title='多人MR空间概念设计' annotation='支持多人协作的MR空间建模与概念设计' tags={["空间", "手势"]} imgPath={examplePic} href = 'www.baidu.com'/>
                <RefinityCard title='模块化3D创作设计' annotation='利用3D物体创作套件进行概念设计' tags={["模块化组件", "3D打印"]} imgPath={examplePic} href = 'www.baidu.com'/>
                <RefinityCard title='ProtoDreamer' annotation='混合原型的3D概念设计' tags={["实体原型", "文本", "3D打印"]} imgPath={examplePic} href = 'www.baidu.com'/>
                <RefinityCard title='柔性套件快速建模' annotation='基于柔性实体套件的快速建模' tags={["柔性套件", "3D打印"]} imgPath={examplePic} href = 'www.baidu.com'/>
            </div>
            <div className={styles['subTitle']}>
                智能评价
            </div>
            <div className={styles['cardArrayContainer']}>
                <RefinityCard title='设计实时评价' annotation='对设计内容进行实时智能评价' tags={["文本", "图像", "模型"]} imgPath={examplePic} href = 'www.baidu.com'/>
            </div> */}
    </div>
  )
}

export default RefinityMenu
