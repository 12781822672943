import {
  IContinuousParam,
  IDiscreteParam,
  IInputParam,
  IMultiRowGroupAddAbleDiscreteParam
} from '@/components/graph/methods/utils'
import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from '../DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from '../GeneralMethod'
import styles from './JiaotongMethods.module.scss'

export const JiaotongPlanePixelExtractionMethod: FC<IDesignGoalSummarizeMethodParams> = GeneralMethod({
  cardType: 'JiaotongPlanePixelExtractionMethod',
  params: [],
  inputs: [{ title: '参考图', type: 'img' }],
  outputParser: (output) => [output?.['table']?.id],
  styles
})
export const JiaotongThreeDComponentGenerationMethod: FC<IDesignGoalSummarizeMethodParams> = GeneralMethod({
  cardType: 'JiaotongThreeDComponentGenerationMethod',
  params: [],
  inputs: [{ title: '参考图', type: 'img' }],
  outputParser: (output) => [output?.['table']?.id],
  styles
})
const modelParam: IDiscreteParam<string> = {
  id: 'model',
  type: 'select',
  title: '图像生成模型',
  default: 'realistvision',
  options: ['realistvision'],
}
const loRAParam: IDiscreteParam<string> = {
  id: 'lora',
  type: 'select',
  title: '图像生成模型',
  default: 'FeijiKeCang',
  options: ['FeijiKeCang'],
}
const samplerParam: IDiscreteParam<string> = {
  id: 'sampler',
  type: 'select',
  title: '采样器',
  default: 'DPM++ 2M Karras',
  options: ['DPM++ 2M Karras'],
}
const seedParam: IInputParam<number> = {
  id: 'seed',
  type: 'input',
  title: '随机种子数值',
  default: -1,
}
const relatedScoreParam: IContinuousParam = {
  id: 'cfg_scale',
  type: 'slider',
  title: '与提示词相关程度',
  default: 2,
  min: 1,
  max: 16,
  step: 1,
}
const referenceStrengthParam: IContinuousParam = {
  id: 'denoising_strength',
  type: 'slider',
  title: '参考图强度',
  default: 0.6,
  min: 0,
  max: 1,
  step: 0.02,
}
export const JiaotongImageGenerationMethod: FC<IDesignGoalSummarizeMethodParams> = GeneralMethod({
  cardType: 'JiaotongImageGenerationMethod',
  params: [modelParam, loRAParam, samplerParam, seedParam, relatedScoreParam, referenceStrengthParam],
  inputs: [{title: '文生图提示词', type: 'txt2img_prompt'}, { title: '参考图', type: 'img' }],
  outputParser: (output) => [output?.['table']?.id],
  styles
})
