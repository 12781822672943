import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styles from './AssetCard.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { SvgIcon } from '@/components/icons'
import { More } from '@/components/icons/More'
import { Button, Dropdown, MenuProps, message, Upload } from 'antd'
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  RedoOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { debounce } from 'lodash'
import { Graph, Node } from '@antv/x6'
import { IconTurnToAsset } from '@/components/icons/IconTurnToAsset'
import { IconUpload } from '@/components/icons/svg/Upload'
import { RcFile } from 'antd/es/upload'
import { useGraphStore, useMetaStore, useWtsStore } from '@/hooks'
import { IPortInfo } from '../../Port/Port'
import { ReviewerModal } from '@/components/Reviewer/Reviewer'
import { getPortId } from '@/components/MainGraph/utils'

export interface IAssetCard {
  cardID?: string
  width?: CSSProperties['width']
  // header部分
  headerColor?: string
  cardIcon?: ReactNode
  cardTitle?: string | ReactNode
  isEditable?: boolean
  registerAsAsset?: () => any | Promise<any>
  style?: CSSProperties
  className?: string
  // children?: ReactNode | null
  node?: Node
  graph?: Graph
  portInfo?: IPortInfo
  onResize?: (cardWidth: number, cardHeight: number) => void
  onUpload?: (file: string | RcFile | Blob) => void
  showController?: boolean
}

export const AssetCard: FC<IAssetCard> = ({
  cardID,
  width,
  headerColor,
  cardIcon,
  cardTitle,
  isEditable,
  registerAsAsset,
  onUpload,
  style,
  className,
  children,
  node,
  graph,
  portInfo,
  onResize,
  showController,
}) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const sizeRef = useRef()
  const metaStore = useMetaStore()
  const wtsStore = useWtsStore()
  const graphStore = useGraphStore()
  const [assetName, setAssetName] = useState<string | null>(cardTitle as string)
  const isIndependentCard = useMemo(() => {
    if (!node || !portInfo) return false
    if (metaStore.cardContext[portInfo.cardId]?.type !== 'asset') {
      return false
    }
    return true
  }, [metaStore.cardContext, node, portInfo])
  const [descAsset, setDescAsset] = useState<string | null>(null)
  const items: MenuProps['items'] = [
    {
      label: '保存到设计资产',
      key: 'commit',
      icon: <SaveOutlined rev={undefined} />,
      disabled: !isEditable,
    },
    {
      label: '下载',
      key: 'download',
      icon: <DownloadOutlined rev={undefined} />,
      disabled: isEditable,
    },
    {
      label: '回溯',
      key: 'history',
      icon: <RedoOutlined rev={undefined} />,
      disabled: isEditable || isIndependentCard,
    },
    {
      label: '删除',
      key: 'remove',
      icon: <DeleteOutlined rev={undefined} />,
      danger: true,
    },
  ]

  const updateAssetName = async () => {
    let index = cardTitle?.toString().indexOf('-')
    let assetName =
      index !== -1
        ? cardTitle?.toString().substring(0, index)
        : cardTitle?.toString()
    const card = metaStore.cardContext[cardID]
    if (!card?.assets?.[0]?.[0]) return
    const asset = await wtsStore.app.get_asset_meta(card.assets[0][0])
    const name = assetName + ' - ' + asset.name + ' '
    setAssetName(name)
  }
  useEffect(() => {
    // if (showController) {
    //   updateAssetName()
    // }
  }, [isEditable])
  useEffect(() => {
    console.log('setAssetName', cardTitle)
    setAssetName(cardTitle as string)
  }, [cardTitle])

  const handleCardResizeDebounce = useCallback(
    debounce(
      (width: number, height: number) => {
        // 更新节点大小
        if (isIndependentCard) {
          node.prop('size', { width: width, height: height })
        }
        onResize && onResize(width, height)
      },
      100,
      { leading: true, trailing: true, maxWait: 1000 }
    ),
    []
  )

  const handleMenuClick = async (e: { key: string }) => {
    switch (e.key) {
      case 'commit':
        if (!isIndependentCard) break
        await registerAsAsset()
        await metaStore.save(wtsStore)
        break
      case 'download': {
        const assetId: string | undefined =
          metaStore.cardContext[portInfo.cardId]?.assets?.[portInfo.groupId]?.[
            portInfo.subId
          ]
        if (assetId) {
          const asset = await wtsStore.app.get_asset_meta(assetId)
          wtsStore.downloadAsset(asset)
        }
        break
      }
      case 'history': {
        console.log(JSON.parse(JSON.stringify(metaStore.cardContext)), portInfo)
        const assetId: string | undefined =
          metaStore.cardContext[portInfo.cardId]?.assets?.[portInfo.groupId]?.[
            portInfo.subId
          ]
        console.log(assetId)
        if (assetId) {
          const asset = await wtsStore.app.get_asset_meta_ext(assetId)
          console.log(asset)
          if (asset.source_design) {
            setDescAsset(asset.source_design)
          }
        }
        break
      }
      case 'remove': {
        if (!isIndependentCard) break
        const id = node.id
        for (const edge of graphStore.graph.getOutgoingEdges(id) ?? []) {
          metaStore.removeEdge(edge)
        }
        metaStore.delete(id, wtsStore)
        node.remove()
        break
      }
    }
  }

  useEffect(() => {
    const cardDOM = sizeRef.current as HTMLElement
    const resizeObserver = new ResizeObserver((entries) => {
      handleCardResizeDebounce(
        entries[0].target.clientWidth,
        entries[0].target.clientHeight
      )
    })
    isIndependentCard && resizeObserver.observe(cardDOM)
    if (showController) {
      const element = cardDOM.querySelector('#port-circle') as HTMLElement
      const portInfo: IPortInfo = {
        cardId: element.getAttribute('data-card-id'),
        portType: element.getAttribute('data-port-type'),
        groupId: Number(element.getAttribute('data-group-id')),
        subId: Number(element.getAttribute('data-sub-id')),
      }
      const id = getPortId(
        portInfo.cardId,
        'out',
        portInfo.groupId,
        portInfo.subId
      )
      metaStore?.setPortColor(id, element.style.background)
    }
    return () => resizeObserver.unobserve(cardDOM)
  }, [])

  const handleMouseDownCapture = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <div
      ref={sizeRef}
      {...styleClass(['layout'], { width: width }, { style, className })}
    >
      <div {...styleClass(['header'], { background: headerColor })}>
        <div {...styleClass(['header-prefix'])}>
          {cardIcon}
          <div {...styleClass(['header-prefix-text'])}>{assetName}</div>
        </div>
        <div {...styleClass(['header-suffix'])}>
          {/* TODO: pass this component using param */}
          {isIndependentCard && onUpload && (
            <Button {...styleClass(['header-suffix-confirm'])} type="text">
              <Upload
                multiple={false}
                showUploadList={false}
                customRequest={(o) => {
                  onUpload(o.file)
                }}
              >
                <SvgIcon icon={IconUpload} />
              </Upload>
            </Button>
          )}
          {/* {isIndependentCard && isEditable && (
            <Button
              {...styleClass(['header-suffix-confirm'])}
              type="text"
              onClick={async () => {
                console.log('register as asset')
                await registerAsAsset()
                await metaStore.save(wtsStore)
                updateAssetName()
                message.info('自动保存成功')
              }}
            >
              <SvgIcon icon={IconTurnToAsset} />
            </Button>
          )} */}
          {showController && (
            <Dropdown
              menu={{ items, onClick: handleMenuClick }}
              arrow={true}
              destroyPopupOnHide={true}
            >
              <div {...styleClass(['header-suffix-more'])}>
                <SvgIcon icon={More} />
              </div>
            </Dropdown>
          )}
        </div>
      </div>
      <div
        {...styleClass(['content', 'card-content'])}
        onMouseDownCapture={handleMouseDownCapture}
      >
        {children}
      </div>
      {descAsset && (
        <ReviewerModal
          open={true}
          onClose={() => setDescAsset(null)}
          desc_asset={descAsset}
        />
      )}
    </div>
  )
}
