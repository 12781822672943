// 调用css
import { Button } from 'antd'
import styles from './RefinityCard.module.scss'
import { useNavigate } from 'react-router-dom'
import { RefinityVideo } from '@/components/RefinityVideo/RefinityVideo'
// 调用react的组件接口
import { FC, useState } from 'react'
import { CaretRightOutlined } from '@ant-design/icons'

// 如果涉及页面数据储存，请调用hooks中的store
// import { myStore } from '@/hooks'

// 如果使用了antd的组件，请调用antd
// import { Popover } from 'antd'

// 如果调用了静态图片，请调用：
// import myPic_png from './images/myPic.png'

// 如果组件需要处理一些数据，请声明为Interface，变量命名以“I”开头
interface IRefinityCard {
    title: string
    annotation: string
    tags: string[]
    imgPath: string
    href: string
    video?: string | undefined
}


// 编写前端并暴露组件接口，FC是FunctionComponent的缩写，通过 FC<IMyComponent>，我们告诉TypeScript，MyComponent组件接受一个类型为 IMyComponent 的 props 对象。
export const RefinityCard: FC<IRefinityCard> = ({
    title,
    annotation,
    tags,
    imgPath,
    href,
    video,
}) => {
    const [play, setPlay] = useState(false)
    const navigation = useNavigate()
    // 点击按钮时跳转到指定的 URL
    const handleButtonClick = () => {
        // 检查是否含http
        if (href && (href.startsWith('http://') || href.startsWith('https://'))) {
            window.location.href = href
        }else if(href){
            navigation(href)
        }
    }
    const close = () => {
        setPlay(false)
    }


    return (
        <div className={styles['cardContainer']}>
            <img className={styles['image']} src={imgPath}></img>
            <div className={styles['bottom']}>
                <div className={styles['infoContainer']}>
                    <div className={styles['titleContainer']}>
                        <div className={styles['title']}>{title}</div>
                        <div className={styles['tagContainer']}>
                            {tags.map((tag, index) => (
                                <div key={index} className={styles['tag']}>{tag}</div>
                            ))}
                        </div>
                    </div>
                    <div className={styles['annotation']}>{annotation}</div>
                </div>
                <div className={styles['button']}>
                    {video && <Button
                        onClick={() => {setPlay(true)}}
                        icon={<CaretRightOutlined rev={undefined} />}
                        className={styles['cardVideoIcon']}
                    />}
                    <Button onClick={handleButtonClick}>开始使用</Button>
                </div>
            </div>
            {video && <RefinityVideo videoUrl={video} open={play} onClose={close}></RefinityVideo>}
        </div >
    )
}
