import styles from './Card.module.scss'
import { CSSProperties, FC } from 'react'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { useColorVar, useStyles } from '@/hooks/styles'

interface ICard {
  style?: CSSProperties
  className?: string
  onClick?: () => void
  onMouseOver?: (e: any) => void
  onMouseOut?: (e: any) => void
}

export const Card: FC<ICard> = ({
  style,
  className,
  onClick,
  children,
  onMouseOver,
  onMouseOut,
}) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  return (
    <motion.div
      {...styleClass(['layout'], {}, { style, className })}
      initial={{ boxShadow: '0px 8px 10px 0px rgba(10, 20, 69, 0.02)' }}
      whileHover={{
        boxShadow: '0px 8px 10px 0px rgba(10, 20, 69, 0.04)',
        y: -4,
      }}
      transition={{ ...motionEasing }}
      onMouseOver={(e) => onMouseOver && onMouseOver(e)}
      onMouseOut={(e) => onMouseOut && onMouseOut(e)}
      onClick={() => onClick && onClick()}
    >
      {children}
    </motion.div>
  )
}
