import React, { FC, useEffect, useState } from 'react'
import styles from './ImageStyleTransferMethod.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { MethodCard } from '@/components/graph/methods/MethodCard/MethodCard'
import { SvgIcon } from '@/components/icons'
import { DataAnalysis } from '@/components/icons/DataAnalysis'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { AssetInPort } from '@/components/graph/assets/AssetInPort/AssetInPort'
import { ICardContext, UUID, cardType2StageTags } from '@/store/metaStore'
import { useMetaStore, useWtsStore } from '@/hooks'
import { ImageAsset } from '@/components/graph/assets/ImageAsset/ImageAsset'
import {
  IDiscreteParam,
  IContinuousParam,
  IInputParam,
  ParamCard,
} from '@/components/graph/methods/utils'
import { Tag } from 'antd'
import { getPortId, parsePort } from '@/components/MainGraph/utils'
import { ImageStyleTransferArguments } from 'wts'
import { IDesignGoalSummarizeMethodParams } from '../DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from '../GeneralMethod'

const modelParam: IDiscreteParam<string> = {
  id: '_model',
  type: 'select',
  title: '图像生成模型',
  default: 'stablediffuusion_v1_5',
  options: ['stablediffuusion_v1_5'],
}
const generateNumParam: IContinuousParam = {
  id: 'batch_size',
  type: 'slider',
  title: '生成数量',
  default: 1,
  min: 1,
  max: 4,
  step: 1,
}
const seedParam: IInputParam<number> = {
  id: 'seed',
  type: 'input',
  title: '随机种子数值',
  default: -1,
}
const relatedScoreParam: IContinuousParam = {
  id: 'cfg_scale',
  type: 'slider',
  title: '与提示词相关程度',
  default: 2,
  min: 1,
  max: 16,
  step: 1,
}
const referenceStrengthParam: IContinuousParam = {
  id: '_parg1',
  type: 'slider',
  title: '重绘幅度',
  default: 0.6,
  min: 0,
  max: 1,
  step: 0.02,
}
const stepsParam: IContinuousParam = {
  id: '_parg2',
  type: 'slider',
  title: 'steps',
  default: 20,
  min: 10,
  max: 30,
  step: 1,
}
const denoisingStrengthParam: IContinuousParam = {
  id: '_parg3',
  type: 'slider',
  title: 'denoisingStrength',
  default: 0.72,
  min: 0,
  max: 1,
  step: 0.02,
}
const resizeModeParam: IDiscreteParam<number> = {
  id: '_parg4',
  type: 'select',
  title: 'ResizeMode',
  default: 1,
  options: [1],
}
const controlModeParam: IDiscreteParam<string> = {
  id: '_parg5',
  type: 'select',
  title: 'ControlMode',
  default: 'Balanced',
  options: ['Balanced'],
}
const processorResParam: IContinuousParam = {
  id: '_parg6',
  type: 'slider',
  title: 'ProcessorRes',
  default: 512,
  min: 256,
  max: 1024,
  step: 1,
}

export const ImageStyleTransferMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'ImageStyleTransferMethod',
    params: [generateNumParam, seedParam, relatedScoreParam],
    inputs: [
      { title: '原图', type: 'img' },
      { title: '参考图', type: 'img' },
      { title: '文生图提示词(选填)', type: 'txt2img_prompt' },
    ],
    outputParser: (output) => output?.images?.map(({ id }) => id) ?? [],
    isGenerateButtonFrozen: (cardID, inPorts) =>
      !(
        Boolean(inPorts[getPortId(cardID, 'in', 0, 0)]?.[0]) &&
        Boolean(inPorts[getPortId(cardID, 'in', 1, 0)]?.[0])
      ),
    styles,
  })

interface IImageStyleTransferMethodParams {
  data1?: any
  node?: Node
  graph?: Graph
  cardID?: UUID
  cardIndex?: number
  inputPromptAssetCardID?: UUID
  inputReferenceImageAssetCardID?: UUID
  inputMaskImageAssetCardID?: UUID
  outputImageIDs?: string[]
  modelParam?: typeof modelParam.options[number]
  generateNumParam?: typeof generateNumParam.default
  seedParam?: typeof seedParam.default
  relatedScoreParam?: typeof relatedScoreParam.default
  referenceStrengthParam?: typeof referenceStrengthParam.default
  // TODO: add params
}
export const LegacyImageStyleTransferMethod: FC<IImageStyleTransferMethodParams> =
  observer(
    ({
      node,
      graph,
      cardID,
      cardIndex,
      inputPromptAssetCardID,
      inputReferenceImageAssetCardID,
      inputMaskImageAssetCardID,
      outputImageIDs: outputImageIDsParam,
      modelParam: modelParamProps,
      generateNumParam: generateNumParamProps,
      seedParam: seedParamProps,
      relatedScoreParam: relatedScoreParamProps,
      referenceStrengthParam: referenceStrengthParamProps,
      // TODO: add other params
    }) => {
      const c = useColorVar()
      const styleClass = useStyles(styles)
      const metaStore = useMetaStore()
      const wtsStore = useWtsStore()

      // TODO: move to utils
      const tryGet = (obj: object, key: string) => {
        if (!obj) return null
        return obj[key]
      }

      const [inPorts, setInPorts] = useState<ICardContext['inPorts']>({})
      const [assets, setAssets] = useState<ICardContext['assets']>([])

      useEffect(() => {
        const inPorts = metaStore.cardContext[cardID].inPorts
        setInPorts(inPorts)
      }, [metaStore.cardContext[cardID]]) // TODO

      // TODO: simplify this
      const [modelParamValue, setModelParamValue] = useState(
        modelParamProps ?? modelParam.default
      )
      const [generateNumParamValue, setGenerateNumParamValue] = useState(
        generateNumParamProps ?? generateNumParam.default
      )
      const [seedParamValue, setSeedParamValue] = useState(
        seedParamProps ?? seedParam.default
      )
      const [relatedScoreParamValue, setRelatedScoreParamValue] = useState(
        relatedScoreParamProps ?? relatedScoreParam.default
      )
      const [referenceStrengthParamValue, setReferenceStrengthParamValue] =
        useState(referenceStrengthParamProps ?? referenceStrengthParam.default)
      const [stepsParamValue, setStepsParamValue] = useState(stepsParam.default)
      const [denoisingStrengthParamValue, setDenoisingStrengthParamValue] =
        useState(denoisingStrengthParam.default)
      const [resizeModeParamValue, setResizeModeParamValue] = useState(
        resizeModeParam.default
      )
      const [controlModeParamValue, setControlModeParamValue] = useState(
        controlModeParam.default
      )
      const [processorResParamValue, setProcessorResParamValue] = useState(
        processorResParam.default
      )

      const isGenerateButtonFrozen = () =>
        !(
          Boolean(inPorts[getPortId(cardID, 'in', 0, 0)]?.[0]) &&
          Boolean(inPorts[getPortId(cardID, 'in', 1, 0)]?.[0])
        )

      const generate = async () => {
        const designMethod = wtsStore.operator.get_method_data(cardID)
        console.log('imageStyleTransfer, method', designMethod)
        console.log('imageStyleTransfer, inPorts', designMethod.inPorts)
        try {
          for (let i = 0; i < 3; i++) {
            const inPortId = getPortId(cardID, 'in', i, 0)
            const groupAssets = []
            for (const outPort of inPorts[inPortId]) {
              const portInfo = parsePort(outPort)
              const asset =
                metaStore.cardContext[portInfo.cardId]?.assets[
                  portInfo.groupId
                ][portInfo.subId]
              if (asset) {
                groupAssets.push(asset)
              }
            }
            designMethod.inPorts[i].assets = groupAssets
          }
          console.log(
            'imageStyleTransfer, inports assets',
            designMethod.inPorts
          )
        } catch {
          console.error('imageStyleTransfer, inPort not asset')
        }
        await wtsStore.operator.update_method({
          ...designMethod,
          argument: {
            ...designMethod.argument,
            batch_size: generateNumParamValue,
            seed: seedParamValue,
            cfg_scale: relatedScoreParamValue,
          },
        } as ImageStyleTransferArguments)
        console.log('imageStyleTransfer before run method')
        let retDesignMethod
        try {
          retDesignMethod = await wtsStore.operator.run_method(designMethod.id)
        } catch (e) {
          console.error('imageStyleTransfer run method exception', e)
          return
        }
        console.log('imageStyleTransfer return', retDesignMethod)
        console.log(
          'imageStyleTransfer return',
          retDesignMethod,
          retDesignMethod.outputs[0]?.images[0]?.id
        )
        setAssets((assets) => {
          const ids =
            retDesignMethod.outputs[0]?.images?.map(
              (x: { id: string }) => x.id
            ) ?? []
          const new_assets = [...assets, ids]
          for (let i = 0; i < ids.length; i++) {
            metaStore.registerCardOutputAsset(
              getPortId(cardID, 'out', new_assets.length - 1, i),
              ids[i]
            )
          }
          metaStore.cardContext[cardID].assets = new_assets
          return new_assets
        })
      }

      const input = (
        <>
          <AssetInPort
            assetType={'img'}
            title={'原图'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 0, subId: 0 }}
          />
          <AssetInPort
            assetType={'img'}
            title={'参考图'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 1, subId: 0 }}
          />
          <AssetInPort
            assetType={'txt2img_prompt'}
            title={'文生图提示词(选填)'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 2, subId: 0 }}
          />
        </>
      )

      const params = (
        <>
          <ParamCard
            param={modelParam}
            value={modelParamValue}
            onChange={(v) => setModelParamValue(v)}
          />
          <ParamCard
            param={generateNumParam}
            value={generateNumParamValue}
            onChange={(v) => setGenerateNumParamValue(v)}
          />
          <ParamCard
            param={seedParam}
            value={seedParamValue}
            onChange={(v) => setSeedParamValue(v)}
          />
          {/* <ParamCard
          param={relatedScoreParam}
          value={relatedScoreParamValue}
          onChange={(v) => setRelatedScoreParamValue(v)}
        /> */}
        </>
      )

      const output = assets.map((outputImageIDs, i) => {
        return outputImageIDs.map((id, j) => (
          <ImageAsset
            key={i}
            width={'100%'}
            isEditable={false}
            initialImageIDs={[id]}
            portInfo={{
              cardId: cardID,
              portType: 'out',
              groupId: i,
              subId: j,
            }}
            showController={true}
          />
        ))
      })

      // TODO: replace icon
      return (
        <MethodCard
          width={384}
          cardTitle={'图像风格迁移'}
          // TODO: optimize it
          tag={
            <>
              {cardType2StageTags('ImageStyleTransferMethod').map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </>
          }
          cardIcon={<SvgIcon icon={DataAnalysis} />}
          node={node}
          graph={graph}
          input={input}
          params={params}
          output={output}
          isButtonDisabled={isGenerateButtonFrozen()}
          onClick={generate}
        ></MethodCard>
      )
    }
  )
