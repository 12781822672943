import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './PainPointDiscoveryMethod.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { MethodCard } from '@/components/graph/methods/MethodCard/MethodCard'
import { SvgIcon } from '@/components/icons'
import { DataAnalysis } from '@/components/icons/DataAnalysis'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { ICardContext, UUID } from '@/store/metaStore'
import { useMetaStore, useWtsStore } from '@/hooks'
import { get_asset_data, DesignOpportunityArguments } from 'wts'
import { IInputParam, ParamCard } from '@/components/graph/methods/utils'
import { Add_707070 } from '@/components/icons/Add_707070'
import { TextAsset } from '@/components/graph/assets/TextAsset/TextAsset'
import { AssetInPort } from '../../assets/AssetInPort/AssetInPort'
import { Markdown } from '@/components/Planner/Markdown/Markdown'
import { getPortId } from '@/components/MainGraph/utils'
import { IDesignGoalSummarizeMethodParams } from '../DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from '../GeneralMethod'

const userParam: IInputParam<string> = {
  id: 'user',
  type: 'input',
  title: '目标用户',
  default: '设计师', // TODO
  placeholder: '输入需要调研的目标用户',
}

const themeParam: IInputParam<string> = {
  id: 'topic',
  type: 'input',
  title: '调研对象',
  default: '车', // TODO
  placeholder: '输入需要调研的主题',
}

export const PainPointDiscoveryMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'PainPointDiscoveryMethod',
    params: [userParam, themeParam],
    inputs: [{ title: '背景描述', type: 'img' }],
    outputParser: (output) => [output?.['requirements']?.id],
    styles,
  })
interface IPainPointDiscoveryMethodParams {
  data1?: any
  node?: Node
  graph?: Graph
  cardID?: UUID
  cardIndex?: number
}
export const LegacyPainPointDiscoveryMethod: FC<IPainPointDiscoveryMethodParams> =
  observer(({ node, graph, cardID, cardIndex }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()
    const wtsStore = useWtsStore()

    const [userParamValue, setUserParamValue] = useState(userParam.default)
    const [themeParamValue, setThemeParamValue] = useState(themeParam.default)

    const isGenerateButtonFrozen = () => false // TODO

    const [assets, setAssets] = useState<ICardContext['assets']>([])
    const assetDataCache = useRef<Record<string, string>>({})

    useEffect(() => {
      ;(async () => {
        const designMethod = (await wtsStore.operator.get_method_data(
          cardID
        )) as DesignOpportunityArguments
        setUserParamValue(designMethod.argument.user)
        setThemeParamValue(designMethod.argument.topic)
      })()
    }, [])

    const generate = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      console.log('generate, method', designMethod)
      await wtsStore.operator.update_method({
        ...designMethod,
        argument: {
          ...designMethod.argument,
          topic: themeParamValue,
          user: userParamValue,
        },
      })
      console.log('generate before run method')
      let retDesignMethod
      try {
        retDesignMethod = await wtsStore.operator.run_method(designMethod.id)
      } catch (e) {
        console.error('generate run method exception', e)
      }
      console.log('generate return', retDesignMethod)
      console.log(
        'generate return',
        retDesignMethod,
        retDesignMethod.outputs[0]?.requirements?.id
      )
      const outputRequirementsID = retDesignMethod.outputs[0]?.requirements?.id
      const outputRequirementsContent = await get_asset_data(
        outputRequirementsID
      )
      console.log('generate parse', await get_asset_data(outputRequirementsID))
      console.log('CARDDATA', metaStore.cardContext[cardID])
      setAssets((assets) => {
        const newAssets = [...assets, [outputRequirementsID]]
        metaStore.registerCardOutputAsset(
          getPortId(cardID, 'out', newAssets.length - 1, 0),
          outputRequirementsID
        )
        metaStore.cardContext[cardID].assets = newAssets
        return newAssets
      })
      assetDataCache.current[outputRequirementsID] = outputRequirementsContent
    }

    // TODO: replace 'img' with 'background'
    const input = (
      <>
        <AssetInPort
          assetType={'img'}
          title={'背景描述'}
          portInfo={{ cardId: cardID, portType: 'in', groupId: 0, subId: 0 }}
        />
      </>
    )

    const params = (
      <div {...styleClass(['paramCard'])}>
        <ParamCard
          className={styles.textInput}
          param={userParam}
          value={userParamValue}
          onChange={(v) => setUserParamValue(v)}
        />
        <ParamCard
          className={styles.textInput}
          param={themeParam}
          value={themeParamValue}
          onChange={(v) => setThemeParamValue(v)}
        />
      </div>
    )

    const output = assets.map((outputAssets, i) => (
      <TextAsset
        key={i}
        width={'auto'}
        text={assetDataCache.current[outputAssets[0]]}
        useMarkdown={true}
        portInfo={{ cardId: cardID, portType: 'out', groupId: i, subId: 0 }}
        showController={true}
      />
    ))

    return (
      <MethodCard
        width={560}
        cardTitle={'痛点发掘'}
        cardIcon={<SvgIcon icon={DataAnalysis} />}
        hideMore={true}
        suffixExtra={
          <>
            <div {...styleClass(['header-suffix-add'])}>
              <SvgIcon icon={Add_707070} />
            </div>
          </>
        }
        node={node}
        graph={graph}
        hideInput={false}
        input={input}
        params={params}
        output={output}
        isButtonDisabled={isGenerateButtonFrozen()}
        onClick={generate}
      ></MethodCard>
    )
  })
