import { CSSProperties, FC, useEffect, useState } from 'react'
import styles from './TextAsset.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { AssetCard } from '@/components/graph/assets/AssetCard/AssetCard'
import { SvgIcon } from '@/components/icons'
import { Text } from '@/components/icons/Text'
import { Input, message } from 'antd'
import { IPortInfo, Port } from '@/components/graph/Port/Port'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { useGraphStore, useMetaStore, useWtsStore } from '@/hooks'
import { StaticLayout } from '@/components/StaticLayout/StaticLayout'
import { Markdown } from '@/components/Planner/Markdown/Markdown'
import { t } from 'i18next'
import { v4 as uuidv4 } from 'uuid'

interface ITextAsset {
  cardID?: string
  width?: CSSProperties['width']
  text?: string | object
  isEditable?: boolean // TODO: infer use cardID
  useMarkdown?: boolean
  node?: Node
  type?: string
  graph?: Graph
  portInfo: IPortInfo
  showController?: boolean
  title?: string
  plannerAdded?: boolean
}

export const TextAsset: FC<ITextAsset> = observer(
  ({
    cardID,
    width,
    isEditable: isEditableParam,
    text = '',
    node,
    graph,
    type,
    useMarkdown,
    portInfo,
    showController,
    title,
    plannerAdded = false,
  }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()
    const wtsStore = useWtsStore()

    const getTitle = (title: string | null, id: string | null) => {
      let s = ''
      s += { context_string: '上下文', string: '文本', csv: '表格' }[type]
      if (!title) {
        return s
      }
      if (title && !id) {
        return s + ' - ' + title
      }
      if (title && id) {
        return s + ' - ' + title + ' - ' + id
      }
    }

    const getTextValue = (val: any) => {
      switch (type) {
        case 'string':
          return val
        case 'context_string':
          return val.text
        case 'csv':
          return csv2markdown(val)
      }
    }
    
    const [textValue, setTextValue] = useState<string>(getTextValue(text))
    const [newtitle, setNewTitle] = useState<string>(getTitle(title, null))
    const registerAsAsset = async (value?: string) => {
      const val = value ?? textValue
      if (!val || val.trim() === '') {
        message.error(t('asset.text.empty'))
        return
      }
      const id = await metaStore.registerNonAssetCardAsAsset(
        cardID,
        val,
        'string'
      )
      const asset = await wtsStore.app.get_asset_meta(id)
      setNewTitle(getTitle(asset.name, wtsStore.operator.planner.get_short_id(id, 'asset')))
      wtsStore.notifyUpdated()
      // 移除正在编辑的资产
      delete metaStore.editingAssetsMap[cardID]
      return id
    }

    const handleChange = (text: string) => {
      setTextValue(text) //想重置名字表达此时不是注册资产的意思，但是没成功

      metaStore.updateEditingAssetsMap(cardID, {
        register: () => registerAsAsset(text),
      })
    }

    useEffect(() => {
      setTextValue(getTextValue(text))
    }, [text])

    return (
      <AssetCard
        width={width}
        headerColor={c('DeepBlue-background')}
        cardTitle={newtitle}
        cardIcon={<SvgIcon icon={Text} />}
        isEditable={isEditableParam}
        registerAsAsset={registerAsAsset}
        node={node}
        graph={graph}
        showController={showController}
        portInfo={portInfo}
        cardID={cardID}
      >
        <div {...styleClass(['layout'])}>
          <StaticLayout>
            {useMarkdown ? (
              //  TODO: theme
              <Markdown content={textValue} theme={'light'} />
            ) : (
              <Input.TextArea
                {...styleClass(['textarea'])}
                autoSize={{ minRows: 1, maxRows: 5 }}
                placeholder="Please enter"
                bordered={false}
                value={textValue}
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          </StaticLayout>
          {showController && (
            <div {...styleClass(['port-wrapper'])}>
              <Port
                circleColor={c('DeepBlue-main')}
                borderColor={c('DeepBlue-light')}
                portInfo={portInfo}
              />
            </div>
          )}
        </div>
      </AssetCard>
    )
  }
)

const csv2markdown = (csvText: string) => {
    // 将CSV文本按行分割
    const rows = csvText.split('\n');
    
    // 初始化Markdown文本数组
    const markdownRows = [];

    // 遍历每一行
    rows.forEach(row => {
        // 去除行首尾空白字符
        row = row.trim();
        // 跳过空行
        if (row === '') return;
        // 将每一行按逗号分割成单元格
        const cells = row.split(',');
        // 将单元格用'|'分割并包裹在'|'中
        const markdownRow = '| ' + cells.join(' | ') + ' |';
        // 添加到Markdown文本数组中
        markdownRows.push(markdownRow);
    });

    // 如果有行数据，添加标题行分隔符
    if (markdownRows.length > 0) {
        const headerSeparator = '| ' + '--- | '.repeat(markdownRows[0].split('|').length - 2);
        markdownRows.splice(1, 0, headerSeparator);
    }

    // 将数组合并成最终的Markdown文本
    return markdownRows.join('\n');
}

