import { RouteObject, useLocation, useNavigate } from 'react-router-dom'
import { FC, Key, lazy, ReactNode, Suspense } from 'react'
import {
  BulbOutlined,
  CommentOutlined,
  DatabaseOutlined,
  FileOutlined,
  PartitionOutlined,
  RadarChartOutlined,
  SisternodeOutlined,
  TableOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Menu as AntdMenu } from 'antd'

import Home from '@/pages/home/Home'
import Login from '@/pages/login/Login'
import RefinityMenu from '@/pages/refinityMenu/refinityMenu'
import { ReadonlyGraph } from '@/components/MainGraph/ReadonlyGraph/ReadonlyGraph'
import AssetsGallery from "@/pages/assets/AssetsGallery/AssetsGallery";
import AssetsDetail from "@/pages/assets/AssetsDetail/AssetsDetail";

const Graph = lazy(() => import('@/pages/graph/Graph'))
const UITest = lazy(() => import('@/pages/uitest/UITest'))
const Projects = lazy(() => import('@/pages/projects/Projects'))
const Assets = lazy(() => import('@/pages/assets/Assets'))
const Templates = lazy(() => import('@/pages/templates/Templates'))
const Methods = lazy(() => import('@/pages/methods/Methods'))
// const RefinityTestPage = lazy(() => import('@/pages/refinityTestDemo/testPage'))
const RefinityCreativeInspirationDrawingBoard = lazy(() => import('@/pages/refinityCreativeInspirationDrawingBoard/refinityCreativeInspirationDrawingBoard'))
const RefinityMagicCamera = lazy(() => import('@/pages/refinityMagicCamera/refinityMagicCamera'))
const RefinityMagicPaint = lazy(() => import('@/pages/refinityMagicPaint/refinityMagicPaint'))
const RefinityProblemSolution = lazy(() => import('@/pages/refinityProblemSolution/refinityProblemSolution'))

const lazyLoad = (children: ReactNode): ReactNode => {
  return <Suspense fallback={<div>loading</div>}>{children}</Suspense>
}

export const router: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
    children: [
      { path: '/graph/:projectID', element: lazyLoad(<Graph />) },
      { path: '/uitest', element: lazyLoad(<UITest />) },
      { path: '/projects', element: lazyLoad(<Projects />) },
      { path: '/group', element: lazyLoad(<div />) },
      { path: '/asset/:assetID', element: lazyLoad(<AssetsDetail />)},
      { path: '/assets', element: lazyLoad(<Assets />) },
      { path: '/assets/gallery', element: lazyLoad(<AssetsGallery />)},
      { path: '/templates', element: lazyLoad(<Templates />) },
      { path: '/methods', element: lazyLoad(<Methods />) },
      { path: '/refinity', element: lazyLoad(<RefinityMenu />) },
      // { path: '/refinity/TestPage', element: lazyLoad(<RefinityTestPage />) },
      { path: '/refinity/CreativeInspirationDrawingBoard', element: lazyLoad(<RefinityCreativeInspirationDrawingBoard />) },
      { path: '/refinity/MagicCamera', element: lazyLoad(<RefinityMagicCamera />) },
      { path: '/refinity/MagicPaint', element: lazyLoad(<RefinityMagicPaint />) },
      { path: '/refinity/ProblemSolution', element: lazyLoad(<RefinityProblemSolution />) },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/full-graph/:projectID',
    element: <Graph />,
  },
]

type MenuItem = Required<MenuProps>['items'][number]
interface IMenu {
  data1?: any
}
export const Menu: FC<IMenu> = () => {
  const navigation = useNavigate()
  const location = useLocation()
  const getItem = (
    label: ReactNode,
    key: Key,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem
  }

  const items: MenuProps['items'] = [
    getItem('项目', '/projects', <FileOutlined rev={null} />),
    getItem('设计资产', '/assets', <DatabaseOutlined rev={null} />),
    getItem('设计模板', '/templates', <TableOutlined rev={null} />),
    getItem('创新方法', '/methods', <ToolOutlined rev={null} />),

    { type: 'divider' },
    getItem(
      '交互式创意工具',
      '/refinity',
      <RadarChartOutlined rev={null} />
    ),
    // getItem(
    //   '交互灵感激发',
    //   '/refinity',
    //   <BulbOutlined rev={null} />
    // ),
    // getItem(
    //   '多通道交互',
    //   '/refinity',
    //   <SisternodeOutlined rev={null} />
    // ),
    // getItem(
    //   '因果逻辑发现',
    //   '/refinity',
    //   <PartitionOutlined rev={null} />
    // ),

    // getItem('视觉优化', '/group', <PartitionOutlined rev={null} />),
    // getItem('视觉智能问答助手', '/group', <CommentOutlined rev={null} />),
    // getItem('大语言模型助手', '/group', <CommentOutlined rev={null} />),
    // getItem('三维模型生成', '/group', <PartitionOutlined rev={null} />),
    // getItem('设计方法生成', '/group', <PartitionOutlined rev={null} />),
    // getItem('关系三元组抽取', '/group', <PartitionOutlined rev={null} />),
    // getItem('因果逻辑推断', '/group', <PartitionOutlined rev={null} />),
    // getItem('汽车知识图谱', '/group', <PartitionOutlined rev={null} />),
    // getItem('跨领域迁移', '/group', <BulbOutlined rev={null} />),
  ]

  const handleItemClick = (path: string) => {
    navigation(path)
  }

  return (
    <AntdMenu
      onClick={(itemInfo) => handleItemClick(itemInfo.key)}
      style={{ border: 'none' }}
      selectedKeys={[
        location.pathname === '/' ? '/projects' : location.pathname,
      ]}
      defaultSelectedKeys={['/projects']}
      defaultOpenKeys={['/zmx']}
      mode="inline"
      inlineIndent={16}
      items={items}
    />
  )
}
