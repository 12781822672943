import {FC} from "react";

// TODO: make it the same size with IconTurnToAsset
export const IconUpload:FC = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.99992 13.6667C1.54159 13.6667 1.14922 13.5035 0.822835 13.1771C0.496446 12.8507 0.333252 12.4583 0.333252 12V10.3333C0.333252 10.0972 0.413113 9.89931 0.572835 9.73958C0.732557 9.57986 0.930474 9.5 1.16659 9.5C1.4027 9.5 1.60061 9.57986 1.76034 9.73958C1.92006 9.89931 1.99992 10.0972 1.99992 10.3333V12H11.9999V10.3333C11.9999 10.0972 12.0798 9.89931 12.2395 9.73958C12.3992 9.57986 12.5971 9.5 12.8333 9.5C13.0694 9.5 13.2673 9.57986 13.427 9.73958C13.5867 9.89931 13.6666 10.0972 13.6666 10.3333V12C13.6666 12.4583 13.5034 12.8507 13.177 13.1771C12.8506 13.5035 12.4583 13.6667 11.9999 13.6667H1.99992ZM6.16658 3.54167L4.60408 5.10417C4.43742 5.27083 4.2395 5.3507 4.01033 5.34375C3.78117 5.33681 3.58325 5.25 3.41659 5.08333C3.26381 4.91667 3.18395 4.72222 3.177 4.5C3.17006 4.27778 3.24992 4.08333 3.41659 3.91667L6.41658 0.916667C6.49992 0.833333 6.5902 0.774306 6.68742 0.739583C6.78464 0.704861 6.88881 0.6875 6.99992 0.6875C7.11103 0.6875 7.2152 0.704861 7.31242 0.739583C7.40964 0.774306 7.49992 0.833333 7.58325 0.916667L10.5833 3.91667C10.7499 4.08333 10.8298 4.27778 10.8228 4.5C10.8159 4.72222 10.736 4.91667 10.5833 5.08333C10.4166 5.25 10.2187 5.33681 9.9895 5.34375C9.76033 5.3507 9.56242 5.27083 9.39575 5.10417L7.83325 3.54167V9.5C7.83325 9.73611 7.75339 9.93403 7.59367 10.0938C7.43395 10.2535 7.23603 10.3333 6.99992 10.3333C6.76381 10.3333 6.56589 10.2535 6.40617 10.0938C6.24645 9.93403 6.16658 9.73611 6.16658 9.5V3.54167Z" fill="#707070"/>
        </svg>
    )
}
