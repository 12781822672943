import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from './MethodCard.module.scss'
import { SvgIcon } from '@/components/icons'
import { More } from '@/components/icons/More'
import { Button, Collapse } from 'antd'
import { Start } from '@/components/icons/Start'
import { Graph, Node } from '@antv/x6'
import { debounce } from 'lodash'
import { PortManager } from '@antv/x6/lib/model/port'
import PortMetadata = PortManager.PortMetadata
import { useGraphStore, useMetaStore, useWtsStore } from '@/hooks'
import { IPortInfo } from '../../Port/Port'
import { getPortId, getPortIdByObj } from '@/components/MainGraph/utils'

interface IMethodCard {
  width?: CSSProperties['width']
  // header部分
  cardIcon?: ReactNode
  cardTitle?: string
  tag?: ReactNode
  hideInput?: boolean
  suffixExtra?: ReactNode // 除去start按钮的额外标签
  hideMore?: boolean

  input?: ReactNode
  params?: ReactNode
  output?: ReactNode

  isButtonDisabled: boolean
  onClick: () => void

  style?: CSSProperties
  className?: string
  // children?: ReactNode | null
  node?: Node
  graph?: Graph
  // cardID?: UUID
  // cardIndex?: number
  // designMethod?: DesignMethod
  // 是否需要折叠参数卡片
  needFold?: boolean
}

export const MethodCard: FC<IMethodCard> = ({
  width,

  cardIcon,
  cardTitle,
  tag,
  hideInput = false,
  suffixExtra = void 0,
  hideMore = false,

  input,
  params,
  output,
  style,
  className,
  isButtonDisabled,
  onClick,
  children,
  node,
  // cardID,
  // cardIndex,
  // designMethod,
  needFold=false
}) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const sizeRef = useRef()
  const metaStore = useMetaStore()
  const wtsStore = useWtsStore()
  const graphStore = useGraphStore()

  const [portsPositionAndInfo, setPortsPositionAndInfo] = useState<Record<string, [number, number, IPortInfo]> | null>(null)

  const handleCardResizeDebounce = useCallback(
      (width: number, height: number, cardDOM: HTMLElement) => {
        // 更新节点大小
        node.prop('size', { width: width, height: height })
        const elements = cardDOM.querySelectorAll('#port-circle')

        const { left: baseLeft, top: baseTop } = cardDOM.getBoundingClientRect()
        const newPortsPositionAndInfo = {}
        const portsColor = {}
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i] as HTMLElement
          const portInfo: IPortInfo = {
            cardId: element.getAttribute('data-card-id'),
            portType: element.getAttribute('data-port-type'),
            groupId: Number(element.getAttribute('data-group-id')),
            subId: Number(element.getAttribute('data-sub-id')),
          }
          const { left, top } = elements[i].getBoundingClientRect()
          const id = getPortIdByObj(portInfo)
          newPortsPositionAndInfo[id] = [left - baseLeft, top - baseTop, portInfo]
          portsColor[id] = element.style.background
          }
        // console.log(elements, newPortsPositionAndInfo)
        setPortsPositionAndInfo(newPortsPositionAndInfo)
        console.log(newPortsPositionAndInfo)
        for (const port in portsColor) {
          metaStore.setPortColor(port, portsColor[port])
        }
      },
    //   100,
    //   { leading: true, trailing: true, maxWait: 1000 }
    // ),
    []
  )

  const calcPorts = (node: Node, portsPosition: Record<string, [number, number, IPortInfo]>) => {
    // const currentPorts = node.getPorts()
    // console.log(JSON.parse(JSON.stringify([node, portsPosition, currentPorts])))
    const graphScale = graphStore.graphScale
    const ports: PortMetadata[] = []

    for (const port of node.getPorts()) {
      if (!(port.id in portsPosition)) {
        const edges = graphStore.graph.getEdges().filter(edge => {
          return edge.getTargetPortId() === port.id || edge.getSourcePortId() === port.id
        })
        for (const edge of edges) {
          metaStore.removeEdge(edge)
        }
        node.removePort(port.id)
      }
    }

    for (const [portId, port] of Object.entries(portsPosition)) {
      const portInfo = port[2]
      if (node.hasPort(portId)) {
        node.setPortProp(portId, {
          args: {
            x: port[0] / graphScale,
            y: port[1] / graphScale,
            info: port[2] as any,
          }
        })
      } else {
        ports.push({
          id: getPortIdByObj(portInfo),
          group: portInfo.portType === 'in' ? 'absolute_in' : 'absolute_out',
          args: {
            x: port[0] / graphScale,
            y: port[1] / graphScale,
            info: portInfo as any,
          },
        })
      }
    }
    node.addPorts(ports)
    // console.log(node.getPorts(), portsPosition)
  }

  useEffect(() => {
    if (portsPositionAndInfo !== null) {
      node && calcPorts(node, portsPositionAndInfo)
    }
  }, [input, output, portsPositionAndInfo])

  useEffect(() => {
    const cardDOM = sizeRef.current as HTMLElement
    // 判断是否是小地图中的节点
    if (cardDOM.closest('.x6-widget-minimap')) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      handleCardResizeDebounce(
        entries[0].target.clientWidth,
        entries[0].target.clientHeight,
        cardDOM
      )
    })

    node && resizeObserver.observe(cardDOM)

    return () => resizeObserver.unobserve(cardDOM)
  }, [input, output])

  return (
    <div
      ref={sizeRef}
      {...styleClass(
        ['layout'],
        {
          width: width,
          boxShadow: node
            ? '0 4px 16px 0 rgba(10, 20, 69, 0.08)'
            : '0 4px 10px 0 rgba(10, 20, 69, 0.025)',
        },
        { style, className }
      )}
    >
      <div {...styleClass(['header'])}>
        <div {...styleClass(['header-prefix'])}>
          {cardIcon}
          <div {...styleClass(['header-prefix-text'])}>{cardTitle}</div>
          {tag}
        </div>

        <div {...styleClass(['header-suffix'])}>
          {suffixExtra}

          <Button
            {...styleClass(['header-suffix-button'])}
            type={'primary'}
            size={'small'}
            disabled={isButtonDisabled}
            onClick={onClick}
          >
            {/* TODO: move onClick elsewhere  */}
            <SvgIcon icon={Start} />
          </Button>
          {!hideMore && (
            <div {...styleClass(['header-suffix-more'])}>
              <SvgIcon icon={More} />
            </div>
          )}
        </div>
      </div>
      <div {...styleClass(['content'])}>
        {children}
        {!hideInput && (
          <div {...styleClass(['content-part'])}>
            <div {...styleClass(['content-part-title'])}>
              <div {...styleClass(['content-part-title-text'])}>输入</div>
            </div>
            {input}
          </div>
        )}
        {needFold ?
          <Collapse>
            <Collapse.Panel header="参数" key="1">
              {params}
            </Collapse.Panel>
          </Collapse>
          : params
        }
        {/*{params}*/}
        <div {...styleClass(['content-part'])}>
          <div {...styleClass(['content-part-title'])}>
            <div {...styleClass(['content-part-title-text'])}>输出</div>
          </div>
          {output}
        </div>
      </div>
    </div>
  )
}
