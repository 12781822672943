import styles from './ListResponsive.module.scss'
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useStyles } from '@/hooks/styles'
import debounce from 'lodash/debounce'

interface IListResponsive {
  style?: CSSProperties
  className?: string
  changeNums?: (num: number) => void
}
export const ListResponsive: FC<IListResponsive> = ({
  style,
  className,
  children,
  changeNums,
}) => {
  const styleClass = useStyles(styles)
  const listRef = useRef()
  const [firstLoad, setFirstLoad] = useState(true)
  const [columnNum, setColumnNum] = useState<number>(0)
  // 列表容器size监听
  const handleListResizeDebounce = useCallback(
    debounce(
      (width) => {
        if (width < 400) {
          setColumnNum(1)
        } else if (width < 700) {
          setColumnNum(2)
        } else if (width < 1080) {
          setColumnNum(3)
        } else if (width < 1400) {
          setColumnNum(4)
        } else if (width < 1680) {
          setColumnNum(5)
        } else if (width < 2000) {
          setColumnNum(6)
        } else {
          setColumnNum(Math.floor(width / 320))
        }
      },
      200,
      { leading: true, trailing: true, maxWait: 1000 }
    ),
    []
  )

  useEffect(() => {
    if (!firstLoad) {
      changeNums && changeNums(columnNum);
    }
  }, [columnNum])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
    const listContainer = listRef.current as HTMLElement
    const resizeObserver = new ResizeObserver((entries) => {
      // 响应式布局设置
      const currentWidth = entries[0].contentRect.width - 56 // -56是两侧间距
      handleListResizeDebounce(currentWidth)
    })
    resizeObserver.observe(listContainer)
    return () => resizeObserver.unobserve(listContainer)
  }, [])

  return (
    <div
      ref={listRef}
      {...styleClass(
        ['list-responsive-layout'],
        { gridTemplateColumns: `repeat(${columnNum}, minmax(220px, 1fr))` },
        { style, className }
      )}
    >
      {children}
    </div>
  )
}
