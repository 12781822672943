import { getPortId, parsePort } from '@/components/MainGraph/utils'
import { TextAsset } from '@/components/graph/assets/TextAsset/TextAsset'
import { MethodCard } from '@/components/graph/methods/MethodCard/MethodCard'
import {
  IDiscreteParam,
  IInputParam,
  IMultiRowGroupAddAbleDiscreteParam,
  ISingleRowGroupDiscreteParam,
  ParamCard,
} from '@/components/graph/methods/utils'
import { SvgIcon } from '@/components/icons'
import { Add_707070 } from '@/components/icons/Add_707070'
import { DataAnalysis } from '@/components/icons/DataAnalysis'
import { useMetaStore, useWtsStore } from '@/hooks'
import { useLang } from '@/hooks/i18n'
import { useStyles } from '@/hooks/styles'
import { ICardContext, UUID, cardType2DetailedInfo } from '@/store/metaStore'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  DesignEvaluationArguments,
  get_asset_data,
  DesignMethod,
  Asset,
  traverse_method_output,
} from 'wts'
import { AssetInPort } from '../../assets/AssetInPort/AssetInPort'
import lang from '../methods.i18n.json'
import styles from './DesignEvaluationMethod.module.scss'
import { message } from 'antd'
import { GeneralAssetCard } from '../../assets/GeneralAssetCard/GeneralAssetCard'

// SYNC with WTS
const evaluationModes = [
  'txt2img',
  'txt2txt',
  'img2txt',
  'img2img',
  'novelty',
  'usability',
  'feasibility',
  'aesthetics',
  'custom',
] as const
type EvaluationMode = typeof evaluationModes[number]

const evaluationModeParam: ISingleRowGroupDiscreteParam<string> = {
  id: null,
  type: 'single-row-group-select',
  title: '评估模式',
  default: 'txt2img',
  options: [
    {
      label: '一致性评价',
      options: [
        { label: '"用户文本-生成文本"一致性评估', value: 'txt2txt' },
        { label: '"用户文本-生成图像"一致性评估', value: 'txt2img' },
        { label: '"用户图像-生成文本"一致性评估', value: 'img2txt' },
        { label: '"用户图像-生成图像"一致性评估', value: 'img2img' },
      ],
    },
    { label: '设计方案新颖性评估', value: 'novelty' },
    { label: '设计方案实用性评估', value: 'usability' },
    { label: '设计方案技术可行性评估', value: 'feasibility' },
    { label: '设计方案美观性评估', value: 'aesthetics' },
    { label: '自定义指标评估', value: 'custom' },
  ],
  style: { width: '260px' },
}

const customEvaluationSpecificationParam: IInputParam<string> = {
  id: null,
  type: 'input',
  title: '自定义评价指标',
  default: '',
  placeholder: '输入需要评价的指标和说明',
}

const visible = (
  port:
    | 'user-text'
    | 'user-image'
    | 'generated-text'
    | 'generated-image'
    | 'nov-text'
    | 'nov-image',
  mode: string
) => {
  if (mode === 'custom') {
    return (
      port === 'user-text' ||
      port === 'generated-image' ||
      port === 'user-image' ||
      port === 'generated-text'
    )
  }
  if (mode === 'txt2img') {
    return port === 'user-text' || port === 'generated-image'
  }
  if (mode === 'img2img') {
    return port === 'user-image' || port === 'generated-image'
  }
  if (mode === 'img2txt') {
    return port === 'user-image' || port === 'generated-text'
  }
  if (mode === 'txt2txt') {
    return port === 'user-text' || port === 'generated-text'
  }
  if (
    mode === 'novelty' ||
    mode === 'usability' ||
    mode === 'feasibility' ||
    mode === 'aesthetics'
  ) {
    return port === 'nov-text' || port === 'nov-image'
  }
}

const descriptor = { outputParser: (output) => [output?.['result']?.id] }

interface IDesignEvaluationMethodParams {
  data1?: any
  node?: Node
  graph?: Graph
  cardID?: UUID
  cardIndex?: number
}
export const DesignEvaluationMethod: FC<IDesignEvaluationMethodParams> =
  observer(({ node, graph, cardID, cardIndex }) => {
    const { t } = useLang(lang, 'Methods')
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()
    const wtsStore = useWtsStore()

    const [evaluationModeParamValue, setEvaluationModeParamValue] = useState(
      evaluationModeParam.default
    )
    const [
      customEvaluationSpecificationParamValue,
      setCustomEvaluationSpecificationParamValue,
    ] = useState(customEvaluationSpecificationParam.default)
    // const [inPorts, setInPorts] = useState<ICardContext['inPorts']>({})
    const [assets, setAssets] = useState<Asset[][]>([])
    // const assetDataCache = useRef<Record<string, string>>({})

    const title = useMemo(() => {
      const methodType = cardType2DetailedInfo('DesignEvaluationMethod').title
      const methodName = wtsStore.operator.get_method_data(cardID).name
      const methodSid = wtsStore.operator.planner.get_short_id(cardID, 'method')
      wtsStore.notifyUpdated()
      return `${methodType} - ${methodName} - ${methodSid}`
    }, [cardID])

    // useEffect(() => {
    //   const inPorts = metaStore.cardContext[cardID].inPorts
    //   setInPorts(inPorts)
    // }, [metaStore.cardContext[cardID]]) // TODO

    const isGenerateButtonFrozen = () => false // TODO

    // FIX
    useEffect(() => {
      ;(async () => {
        const designMethod = wtsStore.operator.get_method_data(
          cardID
        ) as DesignEvaluationArguments
        setEvaluationModeParamValue(designMethod.argument.mode)
        setCustomEvaluationSpecificationParamValue(designMethod.argument.metric)
        // 应该渲染所有输出,而非调renderOutput
        // await renderOutput()
        await renderAllOutputs()
      })()
    }, [])

    // REDUDANCY
    const renderAllOutputs = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      // console.log('renderOutput', designMethod, metaStore.cardContext[cardID].assets)
      let newAssets = []
      for (const output of designMethod.outputs) {
        const outputAssets = await Promise.all(
          descriptor
            .outputParser(output)
            .map(async (id) => await wtsStore.app.get_asset_meta(id))
        )
        console.log('renderAllOutputs', outputAssets)
        newAssets.unshift(outputAssets)
      }
      for (let i = 0; i < newAssets.length; i++) {
        for (let j = 0; j < newAssets[i].length; j++) {
          metaStore.registerCardOutputAsset(
            getPortId(cardID, 'out', i, j),
            newAssets[i][j].id
          )
        }
      }
      setAssets(newAssets)
    }

    const renderOutput = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      console.log('renderOutput', designMethod)
      const outputAssetIds = descriptor.outputParser(designMethod.outputs[0])
      const outputAssets = await Promise.all(
        outputAssetIds.map(async (id) => await wtsStore.app.get_asset_meta(id))
      )
      setAssets((assets) => {
        const newAssets = [...assets, outputAssets]
        for (let i = 0; i < outputAssets.length; i++) {
          metaStore.registerCardOutputAsset(
            getPortId(cardID, 'out', newAssets.length - 1, i),
            outputAssets[i].id
          )
        }
        metaStore.cardContext[cardID].assets = newAssets.map((x) =>
          x.map((asset) => asset.id)
        )
        return newAssets
      })
    }

    const generate = async () => {
      const designMethod = wtsStore.operator.get_method_data(cardID)
      console.log('generate, method', designMethod)
      for (let i = 0; i < 6; i++) {
        const inPortId = getPortId(cardID, 'in', i, 0)
        const groupAssets = []
        for (const outPort of metaStore.cardContext[cardID].inPorts[inPortId] ??
          []) {
          const portInfo = parsePort(outPort)
          if (!(portInfo.cardId in metaStore.editingAssetsMap)) {
            if (
              metaStore.cardContext[portInfo.cardId]?.assets?.[portInfo.groupId]
            ) {
              const asset =
                metaStore.cardContext[portInfo.cardId].assets[portInfo.groupId][
                  portInfo.subId
                ]
              groupAssets.push(asset)
            }
          } else {
            // 没有资产的情况下自动注册资产
            // 1. 拿到未注册资产的信息
            const assetValue = metaStore.editingAssetsMap[portInfo?.cardId]
            const assetId = await assetValue?.register?.()
            delete metaStore.editingAssetsMap[portInfo?.cardId]
            if (assetId) {
              groupAssets.push(assetId)
            }
          }
        }
        designMethod.inPorts[i].assets = groupAssets
      }
      await wtsStore.operator.update_method({
        ...designMethod,
        argument: {
          ...designMethod.argument,
          mode: evaluationModeParamValue,
          metric: customEvaluationSpecificationParamValue,
        },
      })
      console.log('generate before run method')
      let retDesignMethod: DesignMethod
      try {
        retDesignMethod = await wtsStore.operator.run_method(designMethod.id)
      } catch (e) {
        message.error('生成失败') // TODO: i18n
        console.error('generate run method exception', e)
      }
      await renderOutput()

      // 必须在输出结束后进行保存
      // 首先保存设计，然后将资产绑定到该设计快照，用于实现资产回溯
      await metaStore.save(wtsStore)
      console.log(
        'method data',
        JSON.parse(JSON.stringify(wtsStore.operator.design))
      )
      await traverse_method_output(retDesignMethod.outputs[0], (asset) => {
        return wtsStore.operator.bind_asset_with_current_design(asset.id)
      })
    }

    const input = (
      <>
        {visible('user-text', evaluationModeParamValue) ? (
          <AssetInPort
            assetType={'string'}
            title={'用户输入'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 0, subId: 0 }}
          />
        ) : null}
        {visible('user-image', evaluationModeParamValue) ? (
          <AssetInPort
            assetType={'img'}
            title={'用户输入'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 1, subId: 0 }}
          />
        ) : null}
        {visible('generated-text', evaluationModeParamValue) ? (
          <AssetInPort
            assetType={'string'}
            title={'生成内容'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 2, subId: 0 }}
          />
        ) : null}
        {visible('generated-image', evaluationModeParamValue) ? (
          <AssetInPort
            assetType={'img'}
            title={'生成内容'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 3, subId: 0 }}
          />
        ) : null}
        {visible('nov-text', evaluationModeParamValue) ? (
          <AssetInPort
            assetType={'string'}
            title={'设计方案'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 4, subId: 0 }}
          />
        ) : null}
        {visible('nov-image', evaluationModeParamValue) ? (
          <AssetInPort
            assetType={'img'}
            title={'设计方案'}
            portInfo={{ cardId: cardID, portType: 'in', groupId: 5, subId: 0 }}
          />
        ) : null}
      </>
    )

    // TODO: make it above input
    const params = (
      <div {...styleClass(['paramCard'])}>
        <ParamCard
          className={styles.textInput}
          param={evaluationModeParam}
          value={evaluationModeParamValue}
          onChange={(v) => setEvaluationModeParamValue(v)}
          t={t}
        />
        {evaluationModeParamValue === 'custom' ? (
          <ParamCard
            className={styles.textInput}
            param={customEvaluationSpecificationParam}
            value={customEvaluationSpecificationParamValue}
            onChange={(v) => setCustomEvaluationSpecificationParamValue(v)}
          />
        ) : null}
      </div>
    )

    const output = assets.map((outputs, i) =>
      outputs.map((asset, j) => (
        <GeneralAssetCard
          key={asset.id}
          asset={asset}
          args={{
            width: '100%',
            portInfo: {
              cardId: cardID,
              portType: 'out',
              groupId: i,
              subId: j,
            },
            node: node,
            showController: true,
          }}
        />
      ))
    )

    return (
      <MethodCard
        width={560}
        cardTitle={title}
        cardIcon={<SvgIcon icon={DataAnalysis} />}
        hideMore={true}
        suffixExtra={
          <>
            <div {...styleClass(['header-suffix-add'])}>
              <SvgIcon icon={Add_707070} />
            </div>
          </>
        }
        node={node}
        graph={graph}
        hideInput={false}
        input={input}
        params={params}
        output={output}
        isButtonDisabled={isGenerateButtonFrozen()}
        onClick={generate}
      ></MethodCard>
    )
  })
