import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'
import { IContinuousParam, IDiscreteParam, IInputParam } from './utils'
import { getPortId } from '@/components/MainGraph/utils'

// 'TrizSummarizeProblemMethod',
// 'TrizConvertParametersMethod',
// 'TrizContradictionAnalysisMethod',
// 'TrizInventionPrincipleMethod',
// 'TrizSolutionMethod',

export const TrizSummarizeProblemMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'TrizSummarizeProblemMethod',
    params: [],
    inputs: [{ title: '问题参数', type: 'string' }],
    outputParser: (output) => output.output.map(({id}) => id),
    styles: {},
  })

export const TrizConvertParametersMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'TrizConvertParametersMethod',
    params: [],
    inputs: [{ title: '问题参数', type: 'string' }],
    outputParser: (output) => output.output.map(({id}) => id),
    styles: {},
  })

export const TrizContradictionAnalysisMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'TrizContradictionAnalysisMethod',
    params: [],
    inputs: [{ title: 'TRIZ参数', type: 'string' }],
    outputParser: (output) => output.output.map(({id}) => id),
    styles: {},
  })

export const TrizInventionPrincipleMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'TrizInventionPrincipleMethod',
    params: [],
    inputs: [{ title: 'TRIZ矛盾', type: 'string' }],
    outputParser: (output) => output.output.map(({id}) => id),
    styles: {},
  })

export const TrizSolutionMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'TrizSolutionMethod',
    params: [],
    inputs: [{ title: '发明原则', type: 'string' }],
    outputParser: (output) => output.output.map(({id}) => id),
    styles: {},
  })
