import { Button, Input, Select, Slider } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import styles from './utils.module.scss'
import { identity } from '@/utils/basic'
import { IconImageGray } from '@/components/icons/IconImageGray'
import { SvgIcon } from '@/components/icons'
import { DesignMethod } from 'wts'
import { useGraphStore } from '@/hooks'
type ParamType =
  | 'select'
  | 'slider'
  | 'input'
  | 'multi-select'
  | 'multi-row-group-addable-select' // 可以允许用户主动创建标签，下拉框中的标签设置分组，横向排列的
  | 'single-row-group-select' // 可以允许下拉框中的标签设置分组
  | 'img-click' // inpaint打点
  | 'img-edit'
  | 'select-with-button' // 下拉框中的标签设置按钮
export const styleKeyMap = (type: ParamType) => {
  if (type === 'input') {
    return 'textInput'
  } else if (type === 'select') {
    return 'selectInput'
  } else if (type === 'multi-row-group-addable-select') {
    return 'selectInput'
  } else if (type === 'single-row-group-select') {
    return 'selectInput'
  } else if (type === 'slider') {
    return 'sliderInput'
  } else if (type === 'multi-select') {
    return 'selectInput'
  } else if (type === 'img-click') {
    return 'imgClick'
  } else if (type === 'select-with-button') {
    return 'selectWithButton'
  } else if (type === 'img-edit') {
    return 'imgEdit'
  }
}
export interface IParam {
  [x: string]: any
  id: string | null | ((args: DesignMethod) => any)
  title: string
  default: unknown
  valueMapper?: (args: unknown, value: unknown) => unknown // TODO: type
  type: ParamType
}
export interface IContinuousParam extends IParam {
  type: 'slider'
  default: number
  min: number
  max: number
  step: number
}
export interface IDiscreteParam<T extends number | string> extends IParam {
  type: 'select'
  default: T
  options: readonly T[]
  placeholder?: string
}
export interface IMultiDiscreteParam<T extends number | string> extends IParam {
  type: 'multi-select'
  default: T[]
  options: T[]
  placeholder?: string
}

export interface IMultiRowGroupAddAbleDiscreteParam<T extends number | string>
  extends IParam {
  type: 'multi-row-group-addable-select'
  default: T[]
  options: {
    label: string
    options: T[]
  }[]
  placeholder?: string
}

export interface ISingleRowGroupDiscreteParam<T extends number | string>
  extends IParam {
  type: 'single-row-group-select'
  default: T
  options: any
  placeholder?: string
  style?: React.CSSProperties
}

export interface IDiscreteParamWithButton<T extends number | string>
  extends IParam {
  type: 'select-with-button'
  default: T
  options: readonly T[]
  placeholder?: string
}

export interface IInputParam<T extends number | string> extends IParam {
  type: 'input'
  default: T
  placeholder?: string
}

export interface IImageClickParam extends IParam {
  type: 'img-click'
  default: { x: number; y: number }
  onClick: (x: number, y: number) => void // 点击时的回调函数
  imgUrl: string
  assetID?: string
}

export interface IImageEditParam extends IParam {
  type: 'img-edit'
  default: string
  imgUrl: string
  // options: string[]
  update_wts: (background_mask: { x: number; y: number }[]) => void
  get_image: (background_mask: { x: number; y: number }[]) => void
}

export interface IParamCardParams<T> {
  value: any // TODO
  onChange: (x: T) => unknown // TODO
  param: IParam
  className?: string
  t?: (x: unknown) => unknown // TODO
}

export const ParamCard = ({
  value,
  onChange,
  param,
  className = '',
  t = identity,
  fetchImageInfo,
}: IParamCardParams<any> & { fetchImageInfo?: () => void }) => {
  // TODO
  // TODO: ban as
  const [imgUrl, setImgUrl] = useState((param as IImageEditParam).default)
  const [initialPosition, setInitialPosition] = useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 })
  const [selection, setSelection] = useState({
    x: 50,
    y: 50,
    width: 100,
    height: 100,
  })
  const [isDragging, setIsDragging] = useState(false)
  const [dragHandle, setDragHandle] = useState(null)
  const imageRef = useRef(null)
  const graphStore = useGraphStore()
  useEffect(() => {
    console.log(param)
  }, [param])

  const handleMouseDown = (e) => {
    // e.preventDefault()
    e.stopPropagation()
    const { clientX, clientY } = e
    // console.log(graphStore.graphScale)
    const rect = imageRef.current.getBoundingClientRect()
    const initialX = (clientX - rect.left) / graphStore.graphScale
    const initialY = (clientY - rect.top) / graphStore.graphScale
    setInitialPosition({ x: initialX, y: initialY })
    // 下面是另一种框选的逻辑，因改用新方法而注释
    // let handle

    // if (
    //   selection.x - 5 < initialX &&
    //   initialX < selection.x + 5 &&
    //   selection.y - 5 < initialY &&
    //   initialY < selection.y + 5
    // ) {
    //   handle = 'top-left'
    // } else if (
    //   selection.x + selection.width - 5 < initialX &&
    //   initialX < selection.x + selection.width + 5 &&
    //   selection.y - 5 < initialY &&
    //   initialY < selection.y + 5
    // ) {
    //   handle = 'top-right'
    // } else if (
    //   selection.x - 5 < initialX &&
    //   initialX < selection.x + 5 &&
    //   selection.y + selection.height - 5 < initialY &&
    //   initialY < selection.y + selection.height + 5
    // ) {
    //   handle = 'bottom-left'
    // } else if (
    //   selection.x + selection.width - 5 < initialX &&
    //   initialX < selection.x + selection.width + 5 &&
    //   selection.y + selection.height - 5 < initialY &&
    //   initialY < selection.y + selection.height + 5
    // ) {
    //   handle = 'bottom-right'
    // } else if (
    //   selection.x < initialX &&
    //   selection.x + selection.width > initialX &&
    //   selection.y < initialY &&
    //   selection.y + selection.height > initialY
    // ) {
    //   handle = 'move'
    // } else {
    //   handle = null
    // }
    // console.log(handle)
    setIsDragging(true)
    // setDragHandle(handle)
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    const minSize = 10
    const { clientX, clientY } = e
    const rect = imageRef.current.getBoundingClientRect()
    const offsetX = (clientX - rect.left) / graphStore.graphScale
    const offsetY = (clientY - rect.top) / graphStore.graphScale
    const { x: initialX, y: initialY } = initialPosition
    // console.log(selection, offsetX, offsetY, initialPosition)
    let newSelection = { ...selection }

    // switch (dragHandle) {
    //   case 'top-left':
    //     newSelection = {
    //       x: Math.min(offsetX, x + width - minSize),
    //       y: Math.min(offsetY, y + height - minSize),
    //       width: Math.max(minSize, x + width - offsetX),
    //       height: Math.max(minSize, y + height - offsetY),
    //     }
    //     break
    //   case 'move':
    //     // 移动整个矩形框
    //     newSelection = {
    //       x: x + (offsetX - initialX),
    //       y: y + (offsetY - initialY),
    //       width,
    //       height,
    //     }
    //     break
    //   case 'top-right':
    //     newSelection = {
    //       x,
    //       y: Math.min(offsetY, y + height - minSize),
    //       width: Math.max(minSize, offsetX - x),
    //       height: Math.max(minSize, y + height - offsetY),
    //     }
    //     break
    //   case 'bottom-right':
    //     newSelection = {
    //       x,
    //       y,
    //       width: Math.max(minSize, offsetX - x),
    //       height: Math.max(minSize, offsetY - y),
    //     }
    //     break
    //   case 'bottom-left':
    //     newSelection = {
    //       x: Math.min(offsetX, x + width - minSize),
    //       y,
    //       width: Math.max(minSize, x + width - offsetX),
    //       height: Math.max(minSize, offsetY - y),
    //     }
    //     break
    //   default:
    //     break
    // }

    // Ensure the selection is within the bounds of the image
    // newSelection = {
    //   ...newSelection,
    //   x: Math.min(Math.max(0, newSelection.x), rect.width - newSelection.width),
    //   y: Math.min(
    //     Math.max(0, newSelection.y),
    //     rect.height - newSelection.height
    //   ),
    //   width: Math.min(rect.width, newSelection.width),
    //   height: Math.min(rect.height, newSelection.height),
    // }
    newSelection = {
      ...newSelection,
      x: Math.min(offsetX, initialX),
      y: Math.min(offsetY, initialY),
      width: Math.min(rect.width, Math.abs(offsetX - initialX)),
      height: Math.min(rect.height, Math.abs(offsetY - initialY)),
    }

    // setInitialPosition({ x: offsetX, y: offsetY })
    setSelection(newSelection)
  }

  const handleMouseUp = (e) => {
    const { clientX, clientY } = e
    const rect = imageRef.current.getBoundingClientRect()
    const offsetX = (clientX - rect.left) / graphStore.graphScale
    const offsetY = (clientY - rect.top) / graphStore.graphScale
    const { x, y, width, height } = selection
    const { x: initialX, y: initialY } = initialPosition
    // console.log(selection, offsetX, offsetY, initialPosition)
    let newSelection = { ...selection }
    newSelection = {
      ...newSelection,
      x: Math.min(offsetX, initialX),
      y: Math.min(offsetY, initialY),
      width: Math.min(rect.width, Math.abs(offsetX - initialX)),
      height: Math.min(rect.height, Math.abs(offsetY - initialY)),
    }

    setSelection(newSelection)
    setIsDragging(false)
    const imgEdited = [
      {
        x: selection.x,
        y: selection.y,
      },
      {
        x: selection.x + selection.width,
        y: selection.y + selection.height,
      },
    ]
    param.update_wts(imgEdited)
    // setDragHandle(null)
    // console.log(dragHandle)
  }

  const slider = param.type === 'slider' && (
    <Slider
      min={(param as IContinuousParam).min}
      max={(param as IContinuousParam).max}
      step={(param as IContinuousParam).step}
      value={value as never}
      onChange={onChange}
    />
  )
  const input = param.type === 'input' && (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={(param as IInputParam<string>)?.placeholder ?? ''}
    />
  )
  const select = param.type === 'select' && (
    <Select
      value={value}
      options={(param as IDiscreteParam<string>).options.map((option) => ({
        value: option,
        label: t(option),
      }))}
      onChange={onChange}
      placeholder={(param as IDiscreteParam<string>)?.placeholder ?? ''}
    />
  )
  const multiRowGroupAddAbleSelect = param.type ===
    'multi-row-group-addable-select' && (
    <Select
      value={value}
      options={(
        param as IMultiRowGroupAddAbleDiscreteParam<string>
      ).options.map((option) => ({
        label: option.label,
        options: option.options.map((each) => {
          return {
            label: each,
            value: each,
          }
        }),
      }))}
      onChange={onChange}
      placeholder={(param as IDiscreteParam<string>)?.placeholder ?? ''}
      mode={'tags'}
    />
  )
  const singleRowGroupSelect = param.type === 'single-row-group-select' && (
    <Select
      style={(param as ISingleRowGroupDiscreteParam<string>).style}
      value={value}
      options={(param as ISingleRowGroupDiscreteParam<string>).options}
      onChange={onChange}
      placeholder={(param as IDiscreteParam<string>)?.placeholder ?? ''}
    />
  )
  const multiSelect = param.type === 'multi-select' && (
    <Select
      value={value}
      options={(param as IDiscreteParam<string>).options.map((option) => ({
        value: option,
        label: option,
      }))}
      onChange={onChange}
      placeholder={(param as IDiscreteParam<string>)?.placeholder ?? ''}
      mode={'multiple'}
    />
  )

  const [assetID, setAssetID] = useState((param as IImageClickParam).assetID)
  useEffect(() => {
    if (
      (param.type === 'img-click' || param.type === 'img-edit') &&
      (param as IImageClickParam).imgUrl !== imgUrl
    ) {
      setImgUrl((param as IImageClickParam).imgUrl)
      setAssetID((param as IImageClickParam).assetID)
    }
  }, [param, imgUrl])
  const selectWithButton =
    param.type === 'select-with-button' ? (
      <div style={{ display: 'flex', alignItems: 'center', width: '496px' }}>
        <Select
          value={value}
          options={(param as IDiscreteParam<string>).options.map((option) => ({
            value: option,
            label: t(option),
          }))}
          onChange={onChange}
          placeholder={(param as IDiscreteParam<string>)?.placeholder ?? ''}
          style={{ flexBasis: '90%' }}
        />
        <Button
          style={{ marginLeft: '5px', flexBasis: '10%' }}
          onClick={() => {
            console.log('clicked')
            if (fetchImageInfo) {
              fetchImageInfo()
            }
          }}
        >
          刷新
        </Button>
      </div>
    ) : null
  const [clickCoords, setClickCoords] = useState({ x: null, y: null })
  const imgClick =
    param.type === 'img-click' ? (
      <div style={{ position: 'relative' }}>
        {imgUrl ? (
          <>
            <img
              src={imgUrl}
              alt="Clickable"
              style={{ width: '100%', cursor: 'crosshair' }}
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect()
                const x = e.clientX - rect.left // x position within the element.
                const y = e.clientY - rect.top // y position within the element.
                const img = e.currentTarget
                const naturalWidth = img.naturalWidth // 原始宽度
                const naturalHeight = img.naturalHeight // 原始高度
                const original_x =
                  ((e.clientX - rect.left) / rect.width) * naturalWidth // 计算原始x位置
                const original_y =
                  ((e.clientY - rect.top) / rect.height) * naturalHeight // 计算原始y位置
                console.log(`Clicked coordinates: x=${x}, y=${y}`)
                console.log(
                  `Original coordinates: x=${original_x}, y=${original_y}`
                )
                ;(param as IImageClickParam).onClick(x, y)
                setClickCoords({ x, y })
                onChange({
                  ...value,
                  original_x,
                  original_y,
                  imgUrl,
                  x,
                  y,
                  assetID,
                }) // 更新 default
              }}
            />
            {clickCoords.x !== null && clickCoords.y !== null && (
              <div
                style={{
                  position: 'absolute',
                  left: `${clickCoords.x}px`,
                  top: `${clickCoords.y}px`,
                  width: '15px',
                  height: '15px',
                  backgroundColor: '#FF7777',
                  clipPath:
                    'polygon(50% 0%, 61.8% 38.2%, 100% 50%, 61.8% 61.8%, 50% 100%, 38.2% 61.8%, 0% 50%, 38.2% 38.2%)', // Star shape
                  transform: 'translate(-50%, -50%)',
                }}
              ></div>
            )}
          </>
        ) : (
          <div className={styles['empty-layout']}>
            <SvgIcon icon={IconImageGray}></SvgIcon>
            <div className={styles['empty-text']}>暂无图像</div>
          </div>
        )}
      </div>
    ) : null

  const imgEdit = param.type === 'img-edit' && (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: imgUrl ? -50 : -30,
          left: 430,
        }}
      >
        {/* <Select
              value={value}
              options={(param as IDiscreteParam<string>).options.map(
                (option) => ({
                  value: option,
                  label: t(option),
                })
              )}
              onChange={onChange}
              placeholder={(param as IDiscreteParam<string>)?.placeholder ?? ''}
              style={{ flexBasis: '90%' }}
            /> */}
        <Button
          style={{ marginLeft: '5px', flexBasis: '10%' }}
          onClickCapture={(e) => {
            e.stopPropagation()
            console.log('clicked')
            const imgEdited = [
              {
                x: selection.x,
                y: selection.y,
              },
              {
                x: selection.x + selection.width,
                y: selection.y + selection.height,
              },
            ]
            param.get_image(imgEdited)
            setImgUrl(param.imgUrl)

            if (fetchImageInfo) {
              fetchImageInfo()
            }
          }}
        >
          刷新
        </Button>
      </div>
      {imgUrl ? (
        <>
          {/* <img
            src={imgUrl}
            alt="Clickable"
            style={{ width: '100%', cursor: 'crosshair' }}
          /> */}

          <div
            style={{
              position: 'relative',
              width: 500,
              height: 'auto',
              overflow: 'hidden',
              marginTop: 20,
              cursor: 'crosshair',
            }}
            onMouseDownCapture={(e) => handleMouseDown(e)}
            onMouseMoveCapture={handleMouseMove}
            onMouseUpCapture={handleMouseUp}
          >
            <img
              ref={imageRef}
              src={imgUrl}
              alt="Crop"
              style={{ width: '100%' }}
              draggable={false}
            />
            <div
              style={{
                position: 'absolute',
                top: selection.y,
                left: selection.x,
                width: selection.width,
                height: selection.height,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'crosshair',
              }}

              // onMouseLeave={handleMouseUp}
            />
            {/* Add drag handles */}
          </div>
        </>
      ) : (
        <div className={styles['empty-layout']}>
          <SvgIcon icon={IconImageGray}></SvgIcon>
          <div className={styles['empty-text']}>暂无图像</div>
        </div>
      )}
    </div>
  )

  return (
    <div {...(className.length === 0 ? {} : { className })}>
      <div
        {...(param.type !== 'slider' ? {} : { className: styles.sliderTop })}
      >
        <span className={styles.title}>{param.title}</span>
        {param.type !== 'slider' ? (
          <></>
        ) : (
          <span className={styles.value}>{value}</span>
        )}
      </div>
      {slider}
      {input}
      {select}
      {multiSelect}
      {multiRowGroupAddAbleSelect}
      {singleRowGroupSelect}
      {imgClick}
      {selectWithButton}
      {imgEdit}
    </div>
  )
}
