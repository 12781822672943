import { FC } from 'react'
import { IDesignGoalSummarizeMethodParams } from './DesignGoalSummarizeMethod/DesignGoalSummarizeMethod'
import { GeneralMethod } from './GeneralMethod'
import { IContinuousParam, IDiscreteParam, IInputParam } from './utils'
import { getPortId } from '@/components/MainGraph/utils'

const heightParam: IContinuousParam = {
  id: 'height',
  type: 'slider',
  title: '身高',
  default: 170,
  min: 120,
  max: 200,
  step: 1,
}

const weightParam: IContinuousParam = {
  id: 'weight',
  type: 'slider',
  title: '体重',
  default: 65,
  min: 40,
  max: 100,
  step: 1,
}

const postureParam: IDiscreteParam<string> = {
  id: 'posture',
  type: 'select',
  title: '姿势',
  default: 'center',
  options: ['left', 'right', 'center'],
}

export const SeatPressureCalcMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'SeatPressureCalcMethod',
    params: [heightParam, weightParam, postureParam],
    inputs: [],
    outputParser: (output) => [output?.csv?.id],
    styles: {},
  })

const arg1Param: IContinuousParam = {
  id: (method) => method.argument.material[0],
  type: 'slider',
  title: '杨氏模量',
  default: 0.1,
  min: 0,
  max: 1,
  step: 0.01,
  valueMapper: (args: any, value) => {
    args.material[0] = value
  },
}

const arg2Param: IContinuousParam = {
  id: (method) => method.argument.material[1],
  type: 'slider',
  title: '泊松比',
  default: 0.45,
  min: 0,
  max: 1,
  step: 0.01,
  valueMapper: (args: any, value) => {
    args.material[1] = value
  },
}

export const SeatPressureSimulationMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'SeatPressureSimulationMethod',
    params: [arg1Param, arg2Param],
    inputs: [{ title: '施加压强矩阵', type: 'csv' }],
    outputParser: (output) => [output?.csv?.id, output?.image?.id],
    styles: {},
  })

export const SeatPressure3DMethod: FC<IDesignGoalSummarizeMethodParams> =
  GeneralMethod({
    cardType: 'SeatPressure3DMethod',
    params: [],
    inputs: [{ title: '仿真结果矩阵', type: 'csv' }],
    outputParser: (output) => [output?.image?.id],
    styles: {},
  })
