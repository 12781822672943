/* eslint-disable @typescript-eslint/naming-convention */
import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'
import {
  IAbility,
  AppOperator,
  Asset,
  DesignMethod,
  DesignOperator,
  ILocalStorage,
  IOwner,
  PlannerMessage,
  get_asset_data,
  Design,
} from 'wts'
import { useMetaStore } from '@/hooks'
import wtsPackageJSON from 'wts/package.json'
import { ICardContext, SupportedCardType } from '@/store/metaStore'
import { getPortId } from '@/components/MainGraph/utils'
import { title } from 'process'
// import axios from 'axios'

export interface DesignDescription extends Design {
  canvas: {
    cardContext: Record<string, ICardContext>
    history: any[]
  }
}

export default class WtsStore {
  public context: RootContext
  public app: AppOperator
  public operator: DesignOperator
  public abilityCallbacks: IAbility
  public localStorageOperator: ILocalStorage
  public owner: IOwner
  public toNotifyOnUpdated: (() => void)[] = []

  abilityCallbackTable: Record<string, any[]> = {
    on_add_asset: [
      (asset: Asset) => {
        const metaStore = useMetaStore()
        console.log('abilityCallbackTable,metaStore', metaStore)
      },
    ],
    on_add_method: [
      (method: DesignMethod) => {
        const metaStore = useMetaStore()
        console.log('abilityCallbackTable,metaStore', metaStore)
      },
    ],
  }

  constructor(context: RootContext) {
    this.context = context
    this.abilityCallbacks = {
      on_add_asset: async (asset: Asset, methodId, source) => {
        console.log('[WTS] add asset', 'source', source, 'methodId', methodId, asset)
        const metaStore = this.context.store.meta
        const graph = this.context.store.graph.graph
        this.notifyUpdated()
        if (source.source === 'user') {
          metaStore.registerNonAssetCardAsAssetMock(source.identifier, asset.id)
        } else if (source.source === 'system') {
          // do nothing
        } else {
          let name = asset.name
          if (asset.id) {
            let sid = this.operator?.planner.get_short_id(asset.id, 'asset') ? '-' + this.operator?.planner.get_short_id(asset.id, 'asset') : ''
            name = name + sid
          }
          metaStore.addTextAssetCard(graph)(
            true,
            asset.id,
            this.context.store.graph.getNextAvailablePosition(
              this.context.store.graph.getCenterPosition()
            ),
            await get_asset_data(asset.id),
            false,
            name,
          )
          // console.log('abilityCallbacks,on_add_method,brk2')
          metaStore.registerNonAssetCardAsAssetMock(asset.id, asset.id)
        }
      },
      on_add_method: async (method: DesignMethod, source) => {
        console.log('[WTS] on add method', method)
        this.notifyUpdated()
        if (source.source !== 'planner') {
          return
        }
        const metaStore = this.context.store.meta
        const graph = this.context.store.graph
        // TODO: reversed data structure is stored else where, merge them
        const wtsMethodTypeMap = this.getWtsMethodTypeMap()
        console.log('abilityCallbacks,on_add_method,method=', method)
        // const assetID = method.inPorts[0].assets[0].id
        console.log(
          'abilityCallbacks,on_add_method,brk3',
          wtsMethodTypeMap.get(method.type)
        )
        // const position: [number, number] = [500, 200]
        // const otherParams = assetID ? { inputTextAssetCardID: assetID } : {}
        metaStore.addGeneralMethod(graph.graph)(
          true,
          method.id,
          graph.getNextAvailablePosition(graph.getCenterPosition()),
          wtsMethodTypeMap.get(method.type),
          {}
        )

        for (let i = 0; i < method.inPorts.length; i++) {
          const inPort = method.inPorts[i]
          for (const asset of inPort.assets) {
            if (asset in metaStore.assetPortMap) {
              const fromPort = metaStore.assetPortMap[asset]
              const toPort = getPortId(method.id, 'in', i, 0)
              metaStore.manuallyAddEdgeInCanvas(graph.graph)(fromPort, toPort)
            }
          }
        }
        // metaStore.manuallyAddEdgeInCanvas(graph)(assetID, method.id, 0) // TODO: add exception
      },
      on_update_method: async (method: DesignMethod) => {
        const funcs = this.abilityCallbackTable['on_update_method'] ?? []
        this.notifyUpdated()
        await Promise.all(funcs.map((func) => func(method)))
        console.log('[WTS] update method')
      },
      on_remove_asset: async (asset: Asset, methodId: string | null) => {
        this.notifyUpdated()
        return
      },
      on_remove_method: async (method: DesignMethod) => {
        this.notifyUpdated()
        return
      },
      on_planner_message: async (message: PlannerMessage) => {
        // TODO: consider save this message
        console.log('on_planner_message,message', message)
        // return
        const chatStore = this.context.store.chat
        if (message.role === 'tool') {
          const content = message.content
          chatStore.addMessage(content, 'system2')
        }
        if (message.role === 'assistant') {
          // for (const contentItem of message.content) {
          //   if (contentItem.type === 'text' && contentItem.data) {
          //     chatStore.addMessage(contentItem.data, 'assistant')
          //   }
          // }
          chatStore.addMessage(message.content, 'assistant')
        }
      },
    }
    this.localStorageOperator = {
      get_local_storage: (key: string) => {
        return localStorage.getItem(key)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set_local_storage: function (key: string, value: any) {
        const prev_value = localStorage.getItem(key)
        localStorage.setItem(key, value)
        return prev_value
      },
    }
    this.app = new AppOperator(this.localStorageOperator, null, null, true)
    this.displayVersion()

    makeAutoObservable(this, {}, { autoBind: true })
  }

  getWtsMethodTypeMap = () => {
    return new Map<string, SupportedCardType>([
      ['generate_design_goals', 'DesignGoalSummarizeMethod'],
      ['market_analysis', 'MarketResearchMethod'],
      ['image_generation', 'Image2ImageMethod'],
      ['design_opportunity_discovery', 'PainPointDiscoveryMethod'],
      // ['background_description', ''],
      ['image_style_transfer', 'ImageStyleTransferMethod'],
      ['generate_text2image_prompt', 'PromptGenerateMethod'],
      ['design_evaluation', 'DesignEvaluationMethod'],
      ['model_generation', 'Image2ModelMethod'],
      ['video_generation', 'Image2VideoMethod'],
      ['fbs_requirement_analysis', 'FbsRequirementMethod'],
      ['fbs_function_analysis', 'FbsFunctionMethod'],
      ['fbs_behavior_analysis', 'FbsBehaviorMethod'],
      ['fbs_structure_analysis', 'FbsStructureMethod'],
      ['fbs_emotion_analysis', 'FbsEmotionMethod'],
      ['fbs_image_generation', 'FbsImageGenerationMethod'],
      ['fbs_fusion', 'FbsFusionMethod'],
      ['environment_analysis', 'EnvironmentAnalysisMethod'],
      ['product_function', 'ProductFunctionMethod'],
      ['user_requirement', 'UserRequirementMethod'],
      ['function_refinement', 'FunctionRefinementMethod'],
      ['design_plan', 'DesignPlanMethod'],
      ['product_iteration', 'ProductIterationMethod'],
      ['image_editing', 'ImageEditingMethod'],
      ['image_object_editing', 'ImageObjectEditingMethod'],
      ['controlled_image_generation', 'ControlledImageGenerationMethod'],
      ['seat_pressure_calc', 'SeatPressureCalcMethod'],
      ['seat_pressure_simulation', 'SeatPressureSimulationMethod'],
      ['seat_pressure_3d', 'SeatPressure3DMethod'],
      ['triz_summarize_problem', 'TrizSummarizeProblemMethod'],
      ['triz_convert_parameters', 'TrizConvertParametersMethod'],
      ['triz_contradiction_analysis', 'TrizContradictionAnalysisMethod'],
      ['triz_invention_principle', 'TrizInventionPrincipleMethod'],
      ['triz_solution', 'TrizSolutionMethod'],
    ])
  }

  notifyUpdated = () => {
    this.toNotifyOnUpdated.forEach((func) => func())
  }

  displayVersion = async () => {
    const latestVersion = await (await fetch('/wts-version')).json()
    console.log(
      `wts.current.version = ${wtsPackageJSON.version}, wts.latest.version = ${
        latestVersion?.version ?? 'unknown'
      }`
    )
  }

  createDesign = async (name: string, image: string) => {
    // TODO: 将image上传为资产，然后将资产id传到image参数(在这个函数里就可)
    const result = await this.app.create_design(
      name,
      null,
      image,
      this.abilityCallbacks
    )
    this.operator = result
  }

  initOperator = async (projectID: number, desc: string | null, readonly: boolean) => {
    if (desc) {
      this.operator = await this.app.load_design_by_desc(desc)
    } else {
      this.operator = await this.app.load_design(projectID, this.abilityCallbacks, readonly)
    }
    console.log('[initOperator] operator=', this.operator)
    return this.operator
  }

  addEventListener = (event: keyof IAbility, callback: any) => {
    if (this.abilityCallbackTable[event]) {
      this.abilityCallbackTable[event].push(callback)
    } else {
      this.abilityCallbackTable[event] = [callback]
    }
  }

  removeEventListener = (event: keyof IAbility, callback: any) => {
    this.abilityCallbackTable[event] = this.abilityCallbackTable[event].filter(
      (cb) => cb !== callback
    )
  }

  downloadAsset = async (asset: Asset) => {
    const assetExtMap = {
      'string': 'txt',
      'image': 'png',
      'video': 'mp4',
      'model': 'obj',
      'txt2img_prompt': 'json',
    }
    try {
      let data: Blob;
      if (asset.type === 'string') {
        const txt = await this.app.get_asset_data(asset.id)
        data = new Blob([txt], {type: 'text/plain'});
      } else {
        const url = this.app.get_asset_url(asset.id)
        const response = await fetch(url);
        data = await response.blob(); // 假设是blob类型的数据
      }
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', asset.name + '.' + assetExtMap[asset.type]); // 指定下载文件的名称和扩展名
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // 清理生成的url
    } catch (error) {
      console.error('Download failed:', error);
    }
  }
}
