import { FC } from 'react'
import styles from './Port.module.scss'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { useColorVar, useStyles } from '@/hooks/styles'

export interface IPortInfo {
  cardId: string
  portType: string
  groupId: number
  subId: number
}

interface IPort {
  circleColor: string
  borderColor: string
  portInfo: IPortInfo
}

export const Port: FC<IPort> = (props: IPort) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  return (
    <motion.div
      id={'port-circle'}
      {...styleClass(['port'], {
        background: props.circleColor,
        borderColor: props.borderColor,
      })}
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.1 }}
      transition={{ ...motionEasing }}
      data-card-id={props.portInfo.cardId}
      data-port-type={props.portInfo.portType}
      data-group-id={props.portInfo.groupId}
      data-sub-id={props.portInfo.subId}
    ></motion.div>
  )
}
