import { CSSProperties, FC, useEffect, useState } from 'react'
import styles from './ImageAsset.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { AssetCard } from '@/components/graph/assets/AssetCard/AssetCard'
import { SvgIcon } from '@/components/icons'
import { Image as AntdImage, message } from 'antd'
import { IPortInfo, Port } from '@/components/graph/Port/Port'
import { Graph, Node } from '@antv/x6'
import { observer } from 'mobx-react'
import { useMetaStore, useWtsStore } from '@/hooks'
import { StaticLayout } from '@/components/StaticLayout/StaticLayout'
import { get_asset_url } from 'wts'
import { RcFile } from 'antd/lib/upload'
import { IconImage } from '@/components/icons/IconImage'
import { IconImageGray } from '@/components/icons/IconImageGray'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { IconImageDelete } from '@/components/icons/IconImageDelete'
import { UUID } from '@/store/metaStore'

interface IImageAssetProps {
  cardID?: string
  width?: CSSProperties['width']
  isEditable?: boolean // TODO: infer use cardID
  node?: Node
  graph?: Graph
  initialImageIDs?: UUID[]
  portInfo: IPortInfo
  showController?: boolean
  title?: string
}

const testImageURL = 'https://s3.bmp.ovh/imgs/2023/11/08/74be99b1754c6e1a.png'

export const ImageAsset: FC<IImageAssetProps> = observer(
  ({
    cardID,
    width,
    isEditable: isEditableParam,
    node,
    graph,
    initialImageIDs,
    portInfo,
    showController,
    title,
  }) => {
    const c = useColorVar()
    const styleClass = useStyles(styles)
    const metaStore = useMetaStore()
    const wtsStore = useWtsStore()
    const [isEditable, setIsEditable] = useState(isEditableParam)
    const [newTitle, setNewTitle] = useState(
      '图像' + (title ? ' - ' + title : '')
    )
    useEffect(() => {
      setImageIDs(initialImageIDs)
    }, [initialImageIDs])

    const [imageIDs, setImageIDs] = useState(initialImageIDs ?? [])

    const registerAsAsset = async (image?: UUID[]) => {
      // TODO
      const imageIds = image ?? imageIDs
      if (imageIds.length > 0) {
        await metaStore.registerNonAssetCardAsAssetMock(cardID, imageIds[0])
      } else {
        message.error('asset.image.empty')
        return
      }
      const asset = await wtsStore.app.get_asset_meta(imageIds[0])
      setNewTitle(
        '图像 - ' +
          asset.name +
          ' - ' +
          wtsStore.operator.planner.get_short_id(asset.id, 'asset')
      )
      wtsStore.notifyUpdated()
      // 移除正在编辑的资产
      delete metaStore.editingAssetsMap[cardID]
      return imageIds[0]
    }

    // const showUploadButton = imageIDs?.length < maximumImageCount && isEditable

    const handleUpload = async (file: string | RcFile | Blob) => {
      const tmpAssetID = await wtsStore.operator.add_asset(
        file,
        await wtsStore.app.get_asset_readable_name('image'),
        'image',
        cardID
      )
      const image = [tmpAssetID]
      metaStore.updateEditingAssetsMap(cardID, {
        register: () => registerAsAsset(image),
      })
      const asset = await wtsStore.app.get_asset_meta(tmpAssetID)
      setNewTitle(
        '图像 - ' +
          asset.name +
          ' - ' +
          wtsStore.operator.planner.get_short_id(asset.id, 'asset')
      )
      setIsEditable(false)
      setImageIDs((images) => [tmpAssetID])
    }

    // TODO: type narrow
    const handleDeleteImage = (index: number) => {
      setImageIDs((images) => [
        ...images.slice(0, index),
        ...images.slice(index + 1),
      ])
    }

    return (
      <AssetCard
        cardID={cardID}
        width={width}
        headerColor={c('Yellow-background')}
        cardTitle={newTitle}
        cardIcon={<SvgIcon icon={IconImage} />}
        isEditable={isEditable}
        onUpload={handleUpload}
        registerAsAsset={registerAsAsset}
        node={node}
        graph={graph}
        portInfo={portInfo}
        showController={showController}
      >
        <div {...styleClass(['layout', 'card-content-center'])}>
          <StaticLayout>
            <div
              {...styleClass([
                `image-gallery-${imageIDs?.length <= 3 ? '3' : '4'}`,
              ])}
            >
              {imageIDs?.length === 0 && (
                <div {...styleClass(['empty-layout'])}>
                  <SvgIcon icon={IconImageGray}></SvgIcon>
                  <div {...styleClass(['empty-text'])}>暂无图像</div>
                </div>
              )}
              {imageIDs?.length > 0 &&
                imageIDs?.length <= 3 &&
                imageIDs?.map((imageID, index) => (
                  <Image
                    key={index}
                    imageCount={imageIDs?.length}
                    imageURL={get_asset_url(imageID)}
                    onDelete={() => handleDeleteImage(index)}
                  />
                ))}
              {imageIDs?.length === 4 &&
                imageIDs?.map((imageID, index) => (
                  <Image
                    key={index}
                    imageCount={imageIDs.length}
                    imageURL={get_asset_url(imageID)}
                    onDelete={() => handleDeleteImage(index)}
                  />
                ))}
            </div>
          </StaticLayout>
          {showController && (
            <div {...styleClass(['port-wrapper'])}>
              <Port
                circleColor={c('Yellow-main')}
                borderColor={c('Yellow-light')}
                portInfo={portInfo}
              />
            </div>
          )}
        </div>
      </AssetCard>
    )
  }
)

interface IImage {
  imageCount: number
  imageURL?: string
  onDelete?: () => void
}
const Image: FC<IImage> = ({ imageCount, imageURL, onDelete }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  const [ifHover, setHover] = useState<boolean>(false)

  return (
    <>
      {imageCount <= 3 && (
        <motion.div
          {...styleClass(['image', 'image-raw-wrapper'])}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <AntdImage {...styleClass(['image-raw-item'])} src={imageURL} />
        </motion.div>
      )}
      {imageCount === 4 && (
        <motion.div
          {...styleClass(['image', 'image-grid-wrapper'])}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <AntdImage {...styleClass(['image-grid-item'])} src={imageURL} />
        </motion.div>
      )}
    </>
  )
}
