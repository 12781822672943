import styles from './AssetsGallery.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWtsStore } from '@/hooks'
import { useEffect, useState } from 'react'
import {
  Button as AntdButton,
  Button,
  FloatButton,
  Input,
  Pagination,
  Spin,
  Tabs,
  TabsProps,
  Typography,
} from 'antd'
import { ListResponsive } from '@/components/universal/ListResponsive/ListResponsive'
import { CaseAssetCard } from '@/pages/assets/AssetsGallery/CaseAsset/CaseAssetCard'
import { Asset, AssetOfType } from 'wts'
import { RollbackOutlined } from '@ant-design/icons'
import { AssetsCard } from '@/pages/assets/AssetsGallery/AssetsCard/AssetsCard'

export type IType =
  | 'design_case'
  | 'design_material'
  | 'model'
  | 'patent'
  | 'product_components'
  | 'product_info'
  | 'bid'
type ITabType = 'all' | 'A.Design' | 'Redot' | 'IF' | 'DIA'

const getKnowledgeItems = (type: IType) => {
  switch (type) {
    case 'design_case':
      return [
        { key: 'design_case_adesign', label: 'A.Design' },
        { key: 'design_case_reddot', label: 'Redot' },
        { key: 'design_case_if', label: 'IF' },
        { key: 'design_case_dia_3', label: 'DIA' },
      ]
    case 'design_material':
      return [
        { key: 'design_material_logo2k', label: 'Logo2K+' },
        { key: 'design_material_texture', label: 'Texture' },
        { key: 'design_material_colors', label: 'colors' },
      ]
    case 'model':
      return [
        { key: 'model_3dobjects', label: '3D模型' },
        // { key: 'model_uisketches', label: 'UISketches' },
        // { key: 'model_sketchyscene', label: 'SketchyScene' },
        // { key: 'model_sketch1', label: 'sketch' },
      ]
    case 'patent':
      return [
        { key: 'patent_zh_v2', label: 'patent_zh' },
        { key: 'patent_en_v2', label: 'patent_en' },
      ]
    case 'product_info':
      return [
        { key: 'product_info_mechanical_v4', label: 'mechanical' },
        // { key: 'amazon_index2', label: 'Amazon' },
      ]
    default:
      return [{ key: 'all', label: '全部' }]
  }
}

// 定义各类型及其对应的文件映射
export const knowledgeToIndexMap: Record<IType, string[]> = {
  model: [
    'model_3dobjects',
    'model_uisketches',
    'model_sketchyscene',
    'model_sketch1',
  ],
  bid: ['bid'],
  product_components: ['product_components_v4'],
  product_info: ['product_info_mechanical_v4', 'amazon_index2'],
  patent: ['patent_zh_v2', 'patent_en_v2'],
  design_case: [
    'design_case_adesign',
    'design_case_if',
    'design_case_dia_3',
    'design_case_reddot',
  ],
  design_material: [
    'design_material_logo2k',
    'design_material_texture',
    'design_material_colors',
  ],
}

const AssetsGallery = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  const navigation = useNavigate()
  const location = useLocation()
  // const type = new URLSearchParams(location.search).get('type') as IType
  const [type, setType] = useState(null)
  const wtsStore = useWtsStore()

  const [tabType, setTabType] = useState<ITabType>()
  // const [assets, setAssets] = useState<Asset[]>([])
  const [assets, setAssets] = useState<any[]>([])
  const [total, setTotal] = useState(0)
  const [assetPagination, setAssetPagination] = useState({
    current: 1,
    pageSize: 20,
  })
  const [ifLoading, setIfLoading] = useState<boolean>(false)

  const showTitle = (type: IType) => {
    switch (type) {
      case 'design_case':
        return '设计案例库'
      case 'design_material':
        return '设计素材库'
      case 'model':
        return '模型知识库'
      case 'patent':
        return '专利知识库'
      case 'product_components':
        return '产品零部件库'
      case 'product_info':
        return '产品信息库'
      case 'bid':
        return '仿生知识库'
    }
  }

  async function getAssetsList(type, tabType) {
    setIfLoading(true)

    let selectedFiles = knowledgeToIndexMap[type]
    if (!selectedFiles) {
      throw new Error(`Unknown type: ${type}`)
    }

    // 如果 tabType 不是 'all'，则只选择对应的文件
    if (tabType !== 'all') {
      selectedFiles = [tabType]
    }

    const fetchPromises = selectedFiles.map(async (file) => {
      const extraData = {
        'patent_zh_v2': {
          url: `${process.env.PUBLIC_URL}/extra/patent_zh_cleaned.json`,
          size: 60,
        },
        'patent_en_v2': {
          url: `${process.env.PUBLIC_URL}/extra/patent_en_cleaned.json`,
          size: 50,
        },
        'bid': {
          url: `${process.env.PUBLIC_URL}/extra/bid_cleaned.json`,
          size: 83,
        },
        'model_3dobjects': {
          url: `${process.env.PUBLIC_URL}/extra/model_cleaned.json`,
          size: 40,
        },
      }

      let extraUrl = null;
      let extraFrom = 0, extraTo = 0, realPage = assetPagination.current, realTo = assetPagination.pageSize;
      if (file in extraData) {
        const { url, size } = extraData[file]
        extraUrl = url
        if (assetPagination.current * assetPagination.pageSize <= size) {
          extraFrom = (assetPagination.current - 1) * assetPagination.pageSize
          extraTo = assetPagination.current * assetPagination.pageSize
          realTo = 0
        } else if ((assetPagination.current - 1) * assetPagination.pageSize < size) {
          extraFrom = (assetPagination.current - 1) * assetPagination.pageSize
          extraTo = size
          realPage = 1
          realTo = assetPagination.pageSize - (extraTo - extraFrom)
        } else {
          extraFrom = size
          extraTo = size
          realPage = Math.ceil((assetPagination.current * assetPagination.pageSize - size) / assetPagination.pageSize)
          realTo = assetPagination.current * assetPagination.pageSize - size
        }
      }

      const allData = []

      if (extraTo - extraFrom > 0) {
        const response = await (await fetch(extraUrl)).json();
        allData.push(...response.slice(extraFrom, extraTo))
      }

      const response = (
        await wtsStore.app.list_knowledge(
          file,
          realPage,
          assetPagination.pageSize
        )
      )
      setTotal(response.total.value)

      if (realTo > 0) {
        allData.push(...response.hits.slice(0, realTo))
      }

      // 获取对象的前 20 个条目
      return allData

      // return Object.fromEntries(jsonData);
    })

    // 等待所有 fetch 请求完成
    const results = await Promise.all(fetchPromises)

    // 合并所有文件的内容到一个对象中
    const combinedData = results.reduce((acc, data) => {
      return [...acc, ...data]
    }, [])

    return combinedData
  }

  useEffect(() => {
    const typeFromURL = new URLSearchParams(location.search).get('type')
    setType(typeFromURL)
    setTabType(knowledgeToIndexMap[typeFromURL]?.[0])
  }, [location.search])

  useEffect(() => {
    if (type) {
      getAssetsList(type, tabType).then((assets) => {
        setAssets(assets)
        setIfLoading(false)
      })
    }
  }, [type, tabType, assetPagination.current, assetPagination.pageSize])

  useEffect(() => {
    console.log('assets ', assets)
  }, [assets])

  return (
    <div {...styleClass(['layout'])}>
      <div {...styleClass(['header-layout'])}>
        <FloatButton
          {...styleClass(['header-button'])}
          icon={<RollbackOutlined rev={undefined} />}
          onClick={() => {
            navigation('/assets')
          }}
          shape="circle"
          style={{ marginRight: '20px' }}
        />
        <Typography.Text {...styleClass(['header-title'])}>
          {showTitle(type)}
        </Typography.Text>
        <div {...styleClass(['header-space'])} />
        <div {...styleClass(['header-suffix-layout'])}>
          <AntdButton
            {...styleClass([], { color: c('primary') })}
            type={'link'}
          >
            管理
          </AntdButton>
          <AntdButton type={'default'}>导入</AntdButton>
          <AntdButton type={'primary'}>导出</AntdButton>
        </div>
      </div>

      <div {...styleClass(['filter-layout'])}>
        <Tabs
          onChange={(v) => {
            setTabType(v as ITabType)
          }}
          {...styleClass(['filter-tabs'])}
          activeKey={tabType}
          items={getKnowledgeItems(type)}
        />

        <div {...styleClass(['filter-suffix-layout'])}>
          {/* <AntdButton
            {...styleClass([], { color: c('primary') })}
            type={'link'}
          >
            高级搜索
          </AntdButton> */}
          <Input.Search
            {...styleClass([], { width: '100%' })}
            placeholder="搜索"
            /*value={searchValue.name}
            onChange={(e) =>
              setSearchValue({ ...searchValue, name: e.target.value })
            }*/
            allowClear
          />
        </div>
      </div>

      <Spin spinning={ifLoading} size={'large'}>
        <ListResponsive
          {...styleClass(['list-layout'], {
            gap: 16,
            minWidth: 400,
            filter: ifLoading ? 'blur(4px)' : 'none',
          })}
        >
          {assets.map((item) => (
            <AssetsCard
              key={item.ID.content}
              id={item.ID.content}
              item={item}
            />
          ))}
        </ListResponsive>
      </Spin>
      <div {...styleClass(['footer-layout'])}>
        <Pagination
          total={total}
          current={assetPagination.current}
          pageSize={assetPagination.pageSize}
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `共 ${total} 条`}
          onChange={(page, pageSize) => {
            setAssetPagination({
              current: page,
              pageSize: pageSize,
            })
          }}
        />
      </div>
    </div>
  )
}

export default AssetsGallery
